import React, { Component } from "react";
import { Button } from "react-materialize";
import { AuthConsumer } from "../../ContexApi/authContext";

class Login extends Component {
  state = {};
  cambiarLogin() {
    alert("en login");
  }
  render() {
    return (
      <AuthConsumer>
        {({ initiateLogin }) => (
          <li>
            <Button
              className="light-blue darken-4"
              onClick={(this.cambiarLogin.bind(this), initiateLogin)}
            >
              Iniciar Sesión
            </Button>
          </li>
        )}
      </AuthConsumer>
    );
  }
}

export default Login;
