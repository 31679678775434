import React, { Fragment } from "react";

import axios from "axios";
import { constServicios } from "../../constServicios";
import { AuthConsumer } from "../../ContexApi/authContext";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../AdminUsuarios/css/AdminUsuarios.css";
import PermisosMod from "./PermisosMod";
import Loader from "../Utils/Loader";
const MySwal = withReactContent(Swal);

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

export default class AdminRoles extends React.Component {
  state = {
    persons: [],
    modulos: [],
    rol: "",
    rolName: "",
    showModulos: false,
    showNewRole: false,
    newRol: "",
    loader: false,
    showPerm: true
  };

  async onHandleChange(event) {
    if (event.target.value === "0") return;

    this.setState({
      showModulos: false
    });

    this.setState({
      showModulos: true,
      rol: event.target.value
    });

    var arr = this.state.persons.filter(
      data => String(data.idRole) === String(event.target.value)
    );
    var nombreRol = arr[0].nombre;

    this.setState({
      rol: event.target.value,
      rolName: nombreRol
    });
  }

  onChange(e) {
    if (e.target.name === "newRol") {
      this.setState({
        [e.target.name]: e.target.value
      });
    } else {
      this.setState({
        showNewRole: true
      });
    }
  }

  crearRol = async e => {
    Swal.fire({
      title: "Ingrese el nombre del nuevo rol",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Crear",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      confirmButtonColor: '#002c55',
      preConfirm: async login => {
        var newRolId;
        var permisosIniciales = [101, 73];
        this.setState({ showPerm: false });
        await axios
          .post(
            `${constServicios.url}${constServicios.urlRol}`,
            {
              nombre: login,
              idEstado: "1" //activo
            },
            {
              headers: {
                Authorization: this.state.token
              }
            }
          )
          .then(res => {
            if (res.data) {
              if (JSON.stringify(res.data).includes("error")) {
                Toast.fire({
                  title: res.data
                });
              } else {
                newRolId = res.data.idRole;
                /* Toast.fire({
                  title: "Rol creado Exitosamente! :" + res.data.idRole
                });*/
              }
            }
          })
          .catch(err => {
            if (err.response) {
              console.log(err.response.status + " " + err.response.data);
              console.log(err.response.headers);
            } else if (err.request) {
              console.log(err.request);
            } else {
              console.log("Errorx", err.message);
            }
          });

        if (newRolId > 0) {
          permisosIniciales.map(row =>
            axios
              .post(
                `${constServicios.url}${constServicios.urlPermisosRol}`,
                [
                  {
                    idPermiso: row,
                    idRol: newRolId,
                    idEstado: 1 //activos
                  }
                ],
                {
                  headers: {
                    Authorization: this.state.token
                  }
                }
              )
              .then(res => {
                if (res.data) {
                  Toast.fire({
                    title: "Rol creado Exitosamente ! " + newRolId
                  });
                }
              })
              .catch(err => {
                if (err.response) {
                  console.log(err.response.status + " " + err.response.data);
                  console.log(err.response.headers);
                } else if (err.request) {
                  console.log(err.request);
                } else {
                  console.log("Errorx", err.message);
                }
              })
          );

          this.consultaRoles();
          this.setState({ showPerm: true });
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  };

  consultaRoles() {
    var rolesNew2;
    var rolesNew = {
      idRole: 0,
      nombre: "Seleccione Rol ..."
    };
    try {
      //e.preventDefault();
      const { accessToken } = this.context;
      this.setState({ token: accessToken });
      axios
        .get(`${constServicios.url}${constServicios.urlRol}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: accessToken
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            rolesNew2 = resp.data;
            rolesNew2.push(rolesNew);
            rolesNew2 = rolesNew2.reverse();

            this.setState({
              persons: rolesNew2,
              loader: false
            });
            //asigno el valor por defecto
          } else {
            alert(
              "Estado Inesperado recuperando roles. Favor intente nuevamente"
            );
            this.setState({
              loader: false
            });
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
        });
    } catch (e) {
      console.log("catch");
      this.setState({
        loader: false
      });
    }
  }
  componentDidMount() {
    this.setState({
      loader: true
    });

    this.consultaRoles();
  }

  render() {
    const columns = [
      { Header: "Id Rol", accessor: "idRole" },
      { Header: "Nombre", accessor: "title" }
    ];
    return (
      <Fragment>
        <div className="general_components">
          <div className="card-panel">
            {/* <h4 className="header2"></h4> */}
            {this.state.showPerm ? (
              <div className=" row">
                <div className="col m6">
                  <label>Elija un Rol:</label>
                  <select
                    id="rol"
                    name="rol"
                    l={10}
                    s={6}
                    m={8}
                    onChange={event => this.onHandleChange(event)}
                    className="browser-default"
                    value={this.state.rol}
                  >
                    {this.state.persons.map(row => (
                      <option key={row.idRole} value={row.idRole}>
                        {row.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col m6 pt-botton">
                  <button
                    className="btn btn-color-arus left"
                    type="button"
                    name="newRol"
                    onClick={this.crearRol.bind(this)}
                  >
                    Crear Rol
                  </button>
                </div>
              </div>
            ) : null}
            <div>
              <div className="row  s6 m6">
                {this.state.showModulos === true ? (
                  <PermisosMod
                    rol={this.state.rol}
                    rolname={this.state.rolName}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {this.state.loader === true ? (
            <div>
              <Loader />
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

AdminRoles.contextType = AuthConsumer;
