export const constENVIOCLIENTES = {
  mensajeInfoFisico:
    "Al seleccionar esta opción recuerde que usted debe entregar los soportes y/o facturas en las sedes que la EPS le indique de manera física",
  mensajeInfoDigital:
    "Al seleccionar esta opción recuerde que usted debe cargar los soportes y/o facturas por el portal web en el módulo carga de soportes",
  extencionesPermitidas: [".xls", ".xlsx"],
  tituloInfo: "Aviso",
  tituloError: "",
  nombreFisico: "fisico",
  nombreDigital: "digital",
  mensajeErrorExtenciones: "Tipo de archivo no permitido solo se permiten: ",
  mensajeErrorArchivo: "Debes selectionar un archivo",
  mensajeErrorTipo: "Debes selectionar un tipo",
  btnArchivoEnvioCliente: "Examinar",
  tituloTipo: "Tipo",
  labelTipoFisico: "Físico",
  labelTipoDigital: "Digital",
  btnCarga: "cargar",
  tagArchivo: "file",
  tagTipo: "data",
  rutaPlantilla: "",
  btnDecargarArchivo: "Descargar plantilla"
};
