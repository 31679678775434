import React, { Component, Fragment } from "react";

//estilos materialize
// import { Select, TextInput, Row, Col, Preloader } from "react-materialize";
import { Row, Col, Preloader, Icon } from "react-materialize";
import { constServicios } from "../../constServicios";
import { AuthConsumer } from "../../ContexApi/authContext";
import axios from "axios";
//Redux
import { connect } from "react-redux";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import FormularioUsuario from "./FormularioUsuario";
const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class EditUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUser: false,
      identificacion: "",
      token: "",
      idusuario: "",
      primerNombre: "",
      primerApellido: "",
      correo: "",
      password: "",
      numDocumento: "",
      username: "",
      persons: "",
      idTipoDocumento: "",
      idRole: "",
      idEstado: "",
      listPrestador: "",
      idCliente: "",
      idSede: "",
      idCiudad: "",
      idClienteAsignado: ""
    };
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  //Consultando Usuario
  componentDidMount() {
    const { accessToken } = this.context;
    this.setState({ token: accessToken });
  }

  preload = () => {
    if (!this.state.preLoad) return null;
    return (
      <Row>
        <Col offset="s5" s={2}>
          <Preloader size="big" />
        </Col>
      </Row>
    );
  };
  cancelCourse = async () => {
    this.setState({
      identificacion: ""
    });
    this.buscar(false);
  };

  buscar = async actualizacionActiva => {
    this.setState({
      showUser: false
    });
    var existeCMI = true;
    if (!this.state.identificacion && actualizacionActiva) {
      Toast.fire({
        type: "error",
        title: "Ingrese Usuario a Buscar"
      });
      return false;
    }

    //Verifico que si existe en WSO2
    /* var rest;
     await axios
      .get(
        `${constServicios.url}${constServicios.urlGetWSO2}${this.state.identificacion}`,
        {
          headers: {
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        if (!res.data.userName) {
          Toast.fire({
            type: "error",
            title: "No existe en Wso2, favor crear usuario."
          });

          //mensaje de no existe en wso2
          //existeCMI = false;
        } else {
          rest = res;
          /* existeCMI = true;
        this.setState({
          // idusuario: res.data.idUsuario,
          showUser: true,
          primerNombre: res.data.name.givenName,
          primerApellido: res.data.name.familyName,
          correo: res.data.emails[0].value,
          username: res.data.userName
        });
        return true;
        }
      });*/

    //Busco en CMI y descargo datos
    await axios
      .get(
        `${constServicios.url}${constServicios.urlInfoUserInd}${this.state.identificacion}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        if (!res.data.username) {
          existeCMI = false;
        }

        if (existeCMI === true) {
          //setear el estado de clientes asignados
          this.setState({
            idusuario: res.data.idUsuario,
            showUser: true,
            primerNombre: res.data.primerNombre,
            primerApellido: res.data.primerApellido,
            correo: res.data.correo,
            password: res.data.password,
            numDocumento: res.data.numeroDocumento,
            username: res.data.username,
            persons: "",
            idRole: res.data.RoleIdRole,
            idEstado: res.data.EstadoIdEstado,
            idTipoDocumento: res.data.TiposDocumentoIdTiposDocumento,
            idCliente: res.data.ClienteIdCliente,
            listPrestador: res.data.PrestadoreIdPrestador,
            idSede:
              res.data.UsuariosSedes.length > 0
                ? res.data.UsuariosSedes[0].idSede
                : null,
            idCiudad:
              res.data.UsuariosSedes.length > 0
                ? res.data.UsuariosSedes[0].idCiudad
                : null,
            idClienteAsignado: res.data.ClientesAsignados
          });
        }
      })
      .catch(err => console.log("Hubo un error en la consulta " + err));

    //this.cargarPrestadorEdit();
    if (existeCMI === false) {
      await axios
        .get(
          `${constServicios.url}${constServicios.urlGetWSO2}${this.state.identificacion}`,
          {
            headers: {
              Authorization: this.state.token
            }
          }
        )
        .then(res => {
          if (!res.data.userName) {
            existeCMI = false;
          } else {
            existeCMI = true;
            this.setState({
              // idusuario: res.data.idUsuario,
              showUser: true,
              primerNombre: res.data.name ? res.data.name.givenName : null,
              primerApellido: res.data.name.familyName
                ? res.data.name.familyName
                : null,
              correo: res.data.emails[0].value,
              username: res.data.userName
            });
            return true;
          }
        })
        .catch(err =>
          console.log("Hubo un error en la consulta de WSO2" + err)
        );
    }
    //
    if (existeCMI === false) {
      Toast.fire({
        type: "error",
        title: "Usuario no Encontrado"
      });
    }
    return false;
  };

  /* cargarPrestadorEdit = async () => {
    if (!this.state.idPrestador) {
      Toast.fire({
        type: "error",
        title: "vacio prestador"
      });
      return false;
    }
    await axios
      .get(
        `${constServicios.url}${constServicios.urlPrestador}/${this.state.idPrestador}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        if (!res.data.nombrePrestador) {
          Toast.fire({
            type: "error",
            title: "PRestador no Encontrado"
          });
          return false;
        } else {
          this.setState({
            listPrestador: res.data.nombrePrestador
          });
        }
      })
      .catch(err => console.log("Hubo un error en la consulta de Roles" + err));
  };
*/
  handleInput(event, key) {
    this.setState({
      [key]: event.target.value
    });
  }
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.buscar(true);
    }
  };
  render() {
    return (
      <Fragment>
        <div className="general_components">
          <div className="card-panel">
            {/* <h4 className="header2">Editar Usuario</h4> */}
            <div className="row">
              <div className="input-field col s6">
                <input
                  id="identificacion"
                  name="identificacion"
                  type="text"
                  className="validate"
                  data-length="30"
                  value={this.state.identificacion}
                  onChange={this.onChange.bind(this)}
                  onKeyPress={event => this.handleKeyPress(event)}
                />
                <label htmlFor="identificacion">Introduzca Usuario</label>
                </div>
                <div className="col m6 pt-botton">
                <button
                  className="btn btn-color-arus left"
                  onClick={() => this.buscar(true)}
                  name="action"
                >
                  Buscar
                </button>
                {/* <Icon right>search</Icon> */}
              </div>
              <div className="input-field col s12"></div>
            </div>
            {this.state.showUser ? (
              <FormularioUsuario
                referencia={true}
                primerNombre={this.state.primerNombre}
                primerApellido={this.state.primerApellido}
                correo={this.state.correo}
                username={this.state.username}
                password={this.state.password}
                idusuario={this.state.idusuario}
                numDocumento={this.state.numDocumento}
                idClienteEdit={this.state.idCliente}
                idRole={this.state.idRole}
                idEstado={this.state.idEstado}
                idTipoDocumento={this.state.idTipoDocumento}
                listaPrestador={this.state.listPrestador}
                idClienteAsignado={this.state.idClienteAsignado}
                idSede={this.state.idSede}
                idCiudad={this.state.idCiudad}
                onChangeUser={this.cancelCourse.bind(this)}
              />
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, { EditUsuario })(EditUsuario);

EditUsuario.contextType = AuthConsumer;
