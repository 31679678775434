import React, { Component } from "react";
import { AuthConsumer } from "../../ContexApi/authContext";
import { Carousel } from "react-materialize";
import Swal from 'sweetalert2'
import { constServicios, nitArl } from "../../constServicios";
import Axios from "axios";
import { isFunction } from "jquery";
class Inicio extends Component {
  state = {
    menus: [],
    loader: false,
    mostrarMensaje: true,
  };
  static contextType = AuthConsumer;
  validarPrestadores = async (accessToken) => {
    try {
      if (this.state.mostrarMensaje) {
        let result = await Axios({
          method: "get",
          url: `${constServicios.url}${constServicios.validarMensajeBienvenida}`,
          headers: {
            Authorization: accessToken
          }
        })
        if (result.data.status) {
          await Swal.fire({
            title: result.data.title,
            text:  result.data.message,
            type: result.data.icon,
            confirmButtonText: 'Aceptar',
          });
        }
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        type: 'error',
        title: 'Ocurrio un error, intenta de nuevo mas tarde.'
      })
    }
  }

  renderBanner = (authenticated, user, accessToken) => {

    return (
      <React.Fragment>
        <Carousel
          images={[
            "/images/banner/banner1.png",
            "/images/banner/banner2.png",
            "/images/banner/banner3.png",
          ]}
          options={{
            fullWidth: true,
            indicators: true,
            // noWrap: true
          }}
          className="bannerStyle"
        />
      </React.Fragment>
    );

  };
  render() {
    let { menus } = this.state;
    //TODO Cargar Banner de forma automatica de la carpeta banner
    if (this.context.authenticated) {
              this.validarPrestadores(this.context.accessToken);
    }
    return (
      <AuthConsumer>
        {({ authenticated, user, accessToken }) => {
          return <React.Fragment>{this.renderBanner(authenticated, user, accessToken)}</React.Fragment>
        }}
      </AuthConsumer>
    );
  }
}

Inicio.contextType = AuthConsumer;
export default Inicio;
