import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";

//constantes
import { constServicios } from "../../../constServicios";
import { constSoportes } from "../constSoportes";

//Materialize
import {
  Collapsible,
  CollapsibleItem,
  Badge,
  Row,
  Col,
  Tab,
  Tabs,
  Button,
  ProgressBar,
  Select,
} from "react-materialize";

//SweetAlert2
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//auth
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../../ContexApi/authContext";
import Can from "../../../ContexApi/Can";

import XLSX from "xlsx";
import { saveAs } from "file-saver";
import BotonAyuda from "../../herramientas/BotonAyuda";
import RadioGroup from "react-materialize/lib/RadioGroup";
import axios from "axios";

const MySwal = withReactContent(Swal);

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      mensajesFiles: {},
      pesoFiles: 0,
      countFilesResponse: 0,
      preload: false,
      errorFiles: [],
      NPBS: "",
      isNPBS: false,
      tipologia: "",
      selectTipologia: false,
      tipoTramite: "",
      tipoTramitePorTipologia: "",
      tipoTramiteElementos: [],
      tipologiasGestion: [],
      facturasEspecialesElementos: [],
      facturasEspeciales: "",
      activarFlujoTipoFactura: 0,
      loaderGeneral: true,
    };

    this.uploadFiles = this.uploadFiles.bind(this);
  }
  static contextType = AuthConsumer;

  componentDidMount() {
    this.botonNPBS();
    this.botonTipologiaMount();
    this.paramsClient();
    // if(this.context.user.lineaDeNegocio === 'LN-CUENTAS_MEDICAS_SALUD') {
    //   const e = { target: { value: 3 } };
    //   this.cargarTipoTramite(e);
    // } else if(this.context.user.lineaDeNegocio === 'LN-PREVENCION') {
    //   const e = { target: { value: 4 } };
    //   this.cargarTipoTramite(e);
    // }
  }

  botonNPBS = () => {
    for (const item of constSoportes.clientesPermitidos) {
      if (
        this.context.authenticated &&
        this.context.user.info.Cliente.idCliente === item
      ) {
        this.setState({ isNPBS: true });
        return;
      }
    }
    this.setState({ NPBS: "N" });
  };

  botonTipologia = () => {
    if (this.context.authenticated) {
      let e;
      for (const item of constSoportes.clientesPermitidosTipologias) {
        if (this.context.user.info.Cliente.idCliente === item) {
          this.setState({ selectTipologia: true });
          return;
        }
      }
      if (Number(this.context.user.info.Cliente.idCliente) === 2) {
        e = { target: { value: 2 } };
        this.cargarTipoTramite(e);
        return;
      }
      if (Number(this.context.user.info.Cliente.idCliente) === 3) {
        e = { target: { value: 1 } };
        this.cargarTipoTramite(e);
        return;
      }
      if (Number(this.context.user.info.Cliente.idCliente) === 6) {
        e = { target: { value: 6 } };
        this.setState({ tipologia: 6 });
        this.cargarTipoTramite(e);
        return;
      }
    }
  };

  botonTipologiaMount = () => {
    if (this.context.authenticated) {
      let e;
      for (const item of constSoportes.clientesPermitidosTipologias) {
        if (this.context.user.info.Cliente.idCliente === item) {
          this.setState({ selectTipologia: true });
          return;
        }
      }
      if (Number(this.context.user.info.Cliente.idCliente) === 2) {
        e = { target: { value: 2 } };
        this.setState({ tipologia: 2 });
        return;
      }
      if (Number(this.context.user.info.Cliente.idCliente) === 3) {
        e = { target: { value: 1 } };
        this.setState({ tipologia: 1 });
        return;
      }
      if (Number(this.context.user.info.Cliente.idCliente) === 6) {
        e = { target: { value: 6 } };
        this.setState({ tipologia: 6 });
        return;
      }
    }
  };

  paramsClient = async () => {
    const { accessToken, user } = this.context;
    if (this.context.authenticated) {
      try {
        const respuesta = await axios.post(
          `${constServicios.url}${constServicios.urlParamsClientKey}`,
          {
            keys: "ACTIVAR_FLUJO_TIPO_FACTURA_SOPORTE",
            cliente: user.info.Cliente.idCliente,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
        if (respuesta.status === 200) {
          if (respuesta.data[0].value == 1) {
            this.setState({
              activarFlujoTipoFactura: respuesta.data[0].value,
            });
            this.cargarTipologiasGestion();
          } else {
            this.setState({
              loaderGeneral: false,
            });
          }
        } else {
          MySwal.fire({
            title: "Error al consultar los parametros del cliente, por favor recargue la página.",
            type: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#002c55",
          });
        }
      } catch (error) {
        console.log('=======error=======> ',error)

                MySwal.fire({
          title: "Error al consultar los parametros del cliente, por favor recargue la página, si el problema persiste contacte con soporte.",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
      }
    }
  };

  clearForm = () => {
    this.setState({
      files: [],
      successfullUploaded: false,
      mensajesFiles: {},
      pesoFiles: 0,
      countFilesResponse: 0,
      errorFiles: [],
      // uploading: false,
      preload: false,
      // tipologia: "",
      tipoTramite: "",
      facturasEspeciales: "",
    });
  };

  validarZip = (files) => {
    // TODO Extenciones de comprimidos permitidos carga de soportes
    const extensiones_permitidas = constSoportes.extensiones;
    const rgAlfanumerico = /[^A-Za-z\d\s]/;
    const rgEspacios = /\s/;
    let no_permitidas = [];
    let permitidos = [];
    let files_pesomax = [];
    let files_badname = [];
    let files_existentes = [];
    let pesoTotal = this.state.pesoFiles;

    if (!files) {
      return null;
    } else {
      for (let file in files) {
        const extension = files[file].name
          .substring(files[file].name.lastIndexOf("."))
          .toLowerCase();

        let filename = files[file].name;

        let permitida = false;
        for (var i = 0; i < extensiones_permitidas.length; i++) {
          filename = filename.replace(extensiones_permitidas[i], "");
          if (extensiones_permitidas[i] === extension) {
            permitida = true;
            break;
          }
        }

        if (permitida) {
          if (
            !this.state.files.find(
              (archivo) => archivo.name === files[file].name
            ) &&
            !permitidos.find((archivo) => archivo.name === files[file].name)
          ) {
            if (!rgAlfanumerico.test(filename) && !rgEspacios.test(filename)) {
              const pesoMB = files[file].size / (1024 * 1024);
              if (pesoMB + pesoTotal < constSoportes.pesomax) {
                pesoTotal += pesoMB;
                permitidos.push(files[file]);
              } else {
                files_pesomax.push(files[file]);
              }
            } else {
              files_badname.push(files[file]);
            }
          } else {
            files_existentes.push(files[file]);
          }
        } else {
          no_permitidas.push(files[file]);
        }
      }

      this.setState({
        // pesoFiles: pesoTotal.toFixed(2)
        pesoFiles: pesoTotal,
      });

      // mensaje archivo no permitido y nombre no permitido
      if (no_permitidas.length > 0 && files_badname.length > 0) {
        let archNoPermi = "";
        no_permitidas.forEach((archivo) => {
          archNoPermi += `<p>${archivo.name}</p>`;
        });

        MySwal.fire({
          title:
            "El Nombre y la Extensión del Archivo no son válidos”. Validar Nombre y la Extensión del archivo",
          type: "error",
          html: archNoPermi,
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
      }

      //mensaje archivos no permitidos
      else if (no_permitidas.length > 0) {
        let archNoPermi = "";
        no_permitidas.forEach((archivo) => {
          archNoPermi += `<p>${archivo.name}</p>`;
        });

        MySwal.fire({
          title:
            "Extensión del Archivo no válida. Verifique que la extensión del archivo sea .ZIP",
          type: "error",
          html: archNoPermi,
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
      }

      //mensaje archivos con nombres no permitidos
      else if (files_badname.length > 0) {
        let archNoPermi = "";
        files_badname.forEach((archivo) => {
          archNoPermi += `${archivo.name} <br>`;
        });

        MySwal.fire({
          type: "error",
          html:
            "<h6><b>Archivo no válido. Validar que el nombre del archivo no tenga caracteres especiales ni espacios</b></h6>" +
            `<p style="text-align: left"> ${archNoPermi} <p>`,
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
      }

      //mensaje archivos con peso maximo no permitidos
      if (files_pesomax.length > 0) {
        let archNoPermi = "";
        files_pesomax.forEach((archivo) => {
          archNoPermi += `<p>${archivo.name}</p>`;
        });

        MySwal.fire({
          type: "error",
          html: "Se ha superado el tamaño permitido para la carga",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
      }

      //mensaje archivos con existentes
      if (files_existentes.length > 0) {
        let archNoPermi = "";
        files_existentes.forEach((archivo) => {
          archNoPermi += `<p>${archivo.name}</p>`;
        });

        MySwal.fire({
          type: "error",
          html: "Está intentando cargar un archivo que ya existe",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
      }

      return permitidos.length > 0 ? permitidos : null;
    }
  };

  onFilesAdded = (files) => {
    files = this.validarZip(files);
    if (files != null) {
      this.setState((prevState) => ({
        files: prevState.files.concat(files),
      }));
    }
  };

  async uploadFiles() {
    if (this.state.NPBS === "" || !this.state.NPBS) {
      MySwal.fire({
        type: "error",
        html: "El campo NPBS es obligatorio",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55",
      });
      return false;
    }
    if (this.state.tipologia === "" || !this.state.tipologia) {
      MySwal.fire({
        type: "error",
        html: "El campo linea de negocio es obligatorio",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55",
      });
      return false;
    }
    this.setState({ uploadProgress: {}, uploading: true, preload: true });
    const promises = [];
    this.state.files.forEach((file) => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
      if (Number(this.context.user.info.Cliente.idCliente) === 6) {
        this.setState({
          successfullUploaded: false,
          preload: false,
        });
      }

    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest = (file) => {
    if (Number(this.context.user.info.Cliente.idCliente) === 6) {
      if (this.state.tipoTramite === "" || !this.state.tipoTramite) {
        MySwal.fire({
          type: "error",
          html: "El campo tipo de tramite es obligatorio",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });       
        return false;
      }      
    }
    
    const { accessToken, user } = this.context;

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);
      //mensajes de respuesta
      req.onreadystatechange = () => {
        if (req.readyState === 4 && req.response !== "") {
          //valida si llega con errores
          const obj = JSON.parse(req.response);
          if (obj.error !== "NOT AUTORIZED") {
            const copy = { ...this.state.mensajesFiles };
            copy[file.name] = { error: req.response };
            const copyErrores = [...this.state.errorFiles];
            if (req.response) {
              if (obj.test)
                if (obj.test.length > 0) copyErrores.push(file.name);
            }

            if (this.state.files.length === this.state.countFilesResponse + 1) {
              this.setState({
                mensajesFiles: copy,
                preload: false,
                errorFiles: copyErrores,
              });
              if (copyErrores.length > 0) {
                MySwal.fire({
                  type: "error",
                  html:
                    "Se encontraron archivos con errores haz clic sobre ellos para ver los detalles.",
                  confirmButtonText: "Cerrar",
                  confirmButtonColor: "#002c55",
                });
              } else {
                if (this.state.activarFlujoTipoFactura == 1 &&
                  !user.info.Prestadore.electronico) {
                  MySwal.fire({
                    title: "Factura y Soportes Recibidos",
                    type: "success",
                    html: "Factura y soportes recibidos recibidos para verificación La presentación de los soportes por este medio no conlleva la aprobación y pago de la factura ni la modificación en acuerdos de tiempo y formas de pago ya pactados",
                    confirmButtonText: "Cerrar",
                    confirmButtonColor: "#002c55",
                  });
                } else {
                  MySwal.fire({
                    title: "Factura y Soportes Recibidos",
                    type: "success", 
                    html: "Factura y soportes recibidos para verificación La presentación de los soportes por este medio no conlleva la aprobación y pago de la factura ni la modificación en acuerdos de tiempo y formas de pago ya pactados",
                    confirmButtonText: "Cerrar",
                    confirmButtonColor: "#002c55",
                  });
                }
              }
            } else {
              this.setState({
                mensajesFiles: copy,
                errorFiles: copyErrores,
                countFilesResponse: this.state.countFilesResponse + 1,
              });
            }
          } else {
            MySwal.fire({
              title: "Su sesión ha finalizado",
              type: "error",
              confirmButtonText: "Cerrar",
              confirmButtonColor: "#002c55",
            });
            this.props.history.push("/logout");
          }
        }
      };

      req.open("POST", `${constServicios.url}${constServicios.urlSoportes}`);
      req.setRequestHeader("Authorization", accessToken);
      req.setRequestHeader("Cliente", user.info.Cliente.idCliente);
      req.setRequestHeader("Prestador", user.info.Prestadore.idPrestador);
      req.setRequestHeader("NPBS", this.state.NPBS);
      req.setRequestHeader("tipologia", this.state.tipologia);
      req.setRequestHeader("tipotramite", this.state.tipoTramite);
      req.setRequestHeader("tipoFactura", 0);
      req.setRequestHeader(
        "tipofacturaespecial",
        this.state.facturasEspeciales
      );

      req.send(formData);
    });
  };

  renderBadge = (file) => {
    const uploadProgress = this.state.uploadProgress[file];
    if (this.state.uploading || this.state.successfullUploaded) {
      if (uploadProgress) {
        if (uploadProgress.state === "done") {
          return (
            <Badge className="green" newIcon caption="%">
              {uploadProgress.percentage}
            </Badge>
          );
        } else {
          return (
            <Badge className="blue" newIcon caption="%">
              {parseInt(uploadProgress.percentage)}
            </Badge>
          );
        }
      } else {
        return (
          <Badge className="grey lighten-1" newIcon caption="%">
            0
          </Badge>
        );
      }
    }
  };
  pintarSelectTipologia = () => {
    console.log('=======this.props=======> ',this.props)
    
    if (this.context.user.lineaDeNegocio === null) {
      return <Select
        label="Linea de negocio"
        className="col m4"
        value={this.state.tipologia}
        onChange={this.cargarTipoTramite}
      >
        <option value="">Seleccionar</option>
        <option value="3">Servicios Asistenciales</option>
        <option value="4">Prevención De Riesgos</option>
        {/* <option value="5">Recobros De Incapacidades</option> */}
      </Select>
    } else if (this.context.user.lineaDeNegocio === 'LN-CUENTAS_MEDICAS_SALUD') {
      return <Select
        label="Linea de negocio"
        className="col m4"
        value={this.state.tipologia}
        onChange={this.cargarTipoTramite}
      >
        <option value="">Seleccionar</option>
        <option value="3">Servicios Asistenciales</option>
      </Select>
    } else if (this.context.user.lineaDeNegocio === 'LN-PREVENCION') {
      return <Select
        label="Linea de negocio"
        className="col m4"
        value={this.state.tipologia}
        onChange={this.cargarTipoTramite}
      >
        <option value="">Seleccionar</option>
        <option value="4">Prevención De Riesgos</option>
      </Select>
    }
  }
  renderActions = () => {
    if (this.state.successfullUploaded) {
      return (
        <React.Fragment>
          <Row>
            <Col xl={12} l={12} m={12} s={12}>
              {this.state.errorFiles.length > 0 ? (
                <Button
                  style={{
                    marginRight: "5px",
                  }}
                  onClick={this.exportarExcel}
                >
                  Descargar Errores
                </Button>
              ) : null}

              {!this.state.preload ? (
                <Button
                  className="red accent-2"
                  disabled={
                    this.state.files.length <= 0 || this.state.uploading
                  }
                  onClick={() => this.clearForm()}
                >
                  Finalizar
                </Button>
              ) : null}
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {

      return (
        <React.Fragment>
          {this.state.isNPBS ? (
            <div style={{ marginLeft: "0", textAlign: "center" }} m="12">
              <Select label='NPBS' value={this.state.NPBS} onChange={(e)=> this.setState({NPBS:e.target.value})}>
                  <option value='' >Seleccione una opción</option>
                  <option value='1' >Presupuesto máximo</option>
                  <option value='2' >COVID</option>
                  <option value='3' >PBS</option>
              </Select>
            </div>
          ) : null}

          <Row>
            {this.state.selectTipologia ? (
              <>
                <Col
                  className=""
                  s={
                    this.state.facturasEspecialesElementos.length == 0
                      ? this.state.tipoTramiteElementos.length > 0
                        ? 6
                        : 12
                      : 4
                  }
                  style={{ marginLeft: "0", textAlign: "center" }}
                >
                  {this.pintarSelectTipologia()}
                </Col>
              </>
            ) : null}
            {this.state.tipoTramiteElementos.length > 0 ? (
              <Col
                className=""
                s={
                  this.state.facturasEspecialesElementos.length > 0
                    ? this.state.selectTipologia
                      ? 4
                      : 6
                    : 6
                }
              >
                <Select
                  label="Tipo de Tramite"
                  className="col m4"
                  value={this.state.tipoTramite}
                  onChange={(e) =>
                    this.setState({ tipoTramite: e.target.value })
                  }
                >
                  <option value="">Seleccionar</option>
                  {this.state.tipoTramiteElementos.map((tipo) => {
                    return (
                      <option
                        key={tipo.tipoTramiteId}
                        value={`${tipo.tipoTramiteId}`}
                      >
                        {tipo.tipoTramite}
                      </option>
                    );
                  })}
                </Select>
              </Col>
            ) : Number(this.context.user.info.Cliente.idCliente) == 6 ? (
            <Select
              label="Tipo de Tramite"
              className="col m4"
              value={this.state.tipoTramite}
              onChange={(e) =>
                this.setState({ tipoTramite: e.target.value })
              }
            >
              <option value="">Seleccionar</option>
              {this.state.tipoTramitePorTipologia[0].tipoTramite.map((tipo) => {
                return (
                  <option
                    key={tipo.tipoTramiteId}
                    value={`${tipo.tipoTramiteId}`}
                  >
                    {tipo.tipoTramite}
                  </option>
                );
              })}
            </Select>): null}
            {this.state.facturasEspecialesElementos.length > 0 ? (
              <Col className="" s={this.state.selectTipologia ? 4 : 6}>
                <Select
                  label="Facturas Especiales"
                  className="col m4"
                  value={this.state.facturasEspeciales}
                  onChange={(e) =>
                    this.setState({ facturasEspeciales: e.target.value })
                  }
                >
                  {/* <option value="">Seleccionar</option> */}
                  {this.state.facturasEspecialesElementos.map((tipo) => {
                    return (
                      <option
                        key={tipo.documentoSinSoporteId}
                        value={`${tipo.documentoSinSoporteId}`}
                      >
                        {tipo.documentoSinSoporteNombre}
                      </option>
                    );
                  })}
                </Select>
              </Col>
            ) : null}
          </Row>

          <div>
            <Button
              style={{
                marginRight: "5px",
              }}
              disabled={this.state.files.length <= 0 || this.state.uploading}
              onClick={this.uploadFiles}
            >
              Cargar
            </Button>
            <Button
              className="red accent-2"
              disabled={this.state.files.length <= 0 || this.state.uploading}
              onClick={() => this.clearForm()}
            >
              Cancelar
            </Button>
          </div>
        </React.Fragment>
      );
    }
  };

  cargarTipologiasGestion = async () => {
    const { accessToken } = this.context;
    try {
      const respuesta = await axios.get(
        `${constServicios.url}${constServicios.urlTipologiasGestion}`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );

      if (respuesta.status === 200) {
        this.setState({
          tipologiasGestion: respuesta.data.data,
          loaderGeneral: false,
          tipoTramitePorTipologia:respuesta.data.data.filter(
            data => data.tipologiaId==  6)
          
        });
        this.botonTipologia();
      } else {
        MySwal.fire({
          title: "Por favor recargue la página, si el problema persiste contacte con soporte.",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
      }
    } catch (error) {
      MySwal.fire({
        title: "Por favor recargue la página, si el problema persiste contacte con soporte.",
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55",
      });
    }
  };

  cargarTipoTramite = (e) => {
    if (e.target.value != "") {
      const { user } = this.context;
      if (
        this.state.activarFlujoTipoFactura == 1 &&
        !user.info.Prestadore.electronico
      ) {
        try {
          console.log('=======this.state.tipologiasGestion=======> ',this.state.tipologiasGestion)
          
          let tipoTramite = "";
          let facturasEspeciales = "";
          let tipoTramiteElementos = [];
          let facturasEspecialesElementos = [];
          let tramiteDocumento = this.state.tipologiasGestion.find(
            (item) => item.tipologiaId == e.target.value
          );          
          if (tramiteDocumento.tipoTramite.length > 1) {
            tipoTramiteElementos = tramiteDocumento.tipoTramite;
          } else if (tramiteDocumento.tipoTramite.length == 1) {
            tipoTramite = tramiteDocumento.tipoTramite[0].tipoTramiteId;
          }
          if (tramiteDocumento.documentoSinSoporte.length > 1) {
            facturasEspecialesElementos = tramiteDocumento.documentoSinSoporte;
          } else if (tramiteDocumento.documentoSinSoporte.length == 1) {
            facturasEspeciales =
              tramiteDocumento.documentoSinSoporte[0].documentoSinSoporteId;
          }
          this.setState({
            tipologia: e.target.value,
            tipoTramite,
            facturasEspeciales,
            tipoTramiteElementos,
            facturasEspecialesElementos,
          });
        } catch (error) {
          MySwal.fire({
            title: "Error al consultar tipo tramite y factura especial, por favor recargue la página, si el problema persiste contacte con soporte.",
            type: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#002c55",
          });
        }
      } else {
        this.setState({
          tipologia: e.target.value,
        });
      }
    } else {
      this.setState({
        tipologia: "",
        tipoTramiteElementos: [],
        facturasEspecialesElementos: [],
      });
    }
  };

  preload = () => {
    if (!this.state.preload) return null;
    return (
      <Row>
        <Col m={6} offset="m3">
          <ProgressBar className="" color="blue" active={this.state.cargando} />
        </Col>
      </Row>
    );
  };

  renderIcon = (file) => {
    const mensajes = this.state.mensajesFiles[file];
    if (this.state.uploading || this.state.successfullUploaded) {
      if (mensajes) {
        if (mensajes.error !== "") {
          const mjs = JSON.parse(mensajes.error);
          if (mjs.test && mjs.test.length > 0) {
            const dato = {
              icon: "error",
              iconClassName: "red-text accent-3",
            };
            return dato;
          } else if (mjs.alerta && mjs.alerta.length > 0) {
            const dato = {
              icon: "warning",
              iconClassName: "orange-text accent-3",
            };
            return dato;
          } else {
            const dato = {
              icon: "check_circle",
              iconClassName: "green-text accent-3",
            };
            return dato;
          }
        } else {
          const dato = {
            icon: "error",
            iconClassName: "red-text accent-3",
          };
          return dato;
        }
      } else {
        const dato = {
          icon: "attachment",
          iconClassName: "grey-text",
        };
        return dato;
      }
    }
  };

  renderMensajes = (file) => {
    const mensajes = this.state.mensajesFiles[file];

    if (!mensajes && mensajes == undefined) return null;

    if (mensajes.error && mensajes.error.length > 0) {
      const mjs = JSON.parse(mensajes.error);

      if (mjs && (mjs.test || mjs.alerta)) {
        if (mjs.test.length > 0) {
          return (
            <React.Fragment>
              {mjs.test.map((mensaje, index) => {
                return (
                  <li className="collection-item" key={index}>
                    <div>
                      <i class="red-text accent-3 secondary-content material-icons">
                        error
                      </i>
                      {mensaje}
                    </div>
                  </li>
                );
              })}
            </React.Fragment>
          );
        } else if (mjs.alerta.length > 0) {
          return (
            <React.Fragment>
              {mjs.alerta.map((mensaje, index) => {
                return (
                  <li className="collection-item" key={index}>
                    <div>
                      {mensaje}
                      <i class="red-text accent-3 secondary-content material-icons">
                        error
                      </i>
                    </div>
                  </li>
                );
              })}
            </React.Fragment>
          );
        }
      }
    } else {
      return null;
    }
  };

  tipoFactura = (file) => {
    const mensajes = this.state.mensajesFiles[file];

    if (!mensajes && mensajes == undefined) return null;

    if (mensajes.error && mensajes.error.length > 0) {
      const mjs = JSON.parse(mensajes.error);

      if (mjs.tipoFactura == 1) {
        return (
          <li className="collection-item" key="d">
            <div>
              Medio de carga: Electrónica
              <i class="red-text accent-3 secondary-content material-icons">
                success
              </i>
            </div>
          </li>
        );
      } else if (mjs.tipoFactura == 0) {
        return (
          <li className="collection-item" key="d">
            <div>
              Medio de carga: Física
              <i class="red-text accent-3 secondary-content material-icons">
                success
              </i>
            </div>
          </li>
        );
      }else {
        return (
          <li className="collection-item" key="d">
            <div>
              Se tuvo un error al tratar de cargas el soporte, intente nuevamente mas tarde
              <i class="red-text accent-3 secondary-content material-icons">
                success
              </i>
            </div>
          </li>
        );
      }
    }
  };

  renderArchivosError = () => {
    if (this.state.errorFiles.length > 0) {
      return (
        <Col xl={6} l={6} m={6} s={12}>
          <h4>Errores</h4>
          <Collapsible>
            {this.state.errorFiles.map((file) => {
              const iconoFile = this.renderIcon(file);
              return (
                <CollapsibleItem
                  key={file}
                  header={
                    <React.Fragment>
                      {`${file} - Ver Más`}
                      {/* {this.renderBadge(file)} */}
                    </React.Fragment>
                  }
                  icon={`${iconoFile ? iconoFile.icon : "attachment"}`}
                  iconClassName={`${
                    iconoFile ? iconoFile.iconClassName : "grey-text"
                  }`}
                >
                  <ul className="collection with-header">
                    {this.renderMensajes(file)}
                  </ul>
                </CollapsibleItem>
              );
            })}
          </Collapsible>
        </Col>
      );
    }
  };

  exportarExcel = () => {
    let data = [];

    const mensajes = this.state.mensajesFiles;

    for (const prop in mensajes) {
      let mns = JSON.parse(mensajes[prop].error);

      for (let index = 0; index < mns.test.length; index++) {
        data.push({
          archivo: prop,
          mensaje: `error: ${mns.test[index]}`,
        });
      }

      for (let index = 0; index < mns.alerta.length; index++) {
        data.push({
          archivo: prop,
          mensaje: `alerta: ${mns.alerta[index]}`,
        });
      }
    }

    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(data);
    var wscols = [{ wch: 30 }, { wch: 200 }];
    ws["!cols"] = wscols;

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Soportes");

    /* write workbook (use type 'binary') */
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "Soportes.xlsx"
    );
  };

  renderDropzone = () => {
    if (!this.state.successfullUploaded) {
      return (
        <Dropzone
          onFilesAdded={this.onFilesAdded}
          disabled={this.state.uploading || this.state.successfullUploaded}
        />
      );
    }
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) => {
          if (authenticated && user.info.Prestadore) {
            return (
              <Can
                rules={user.permisos}
                perform="soportes:ver"
                yes={() =>
                  !this.state.loaderGeneral ? (
                    <React.Fragment>
                      <Tabs className="tab-demo z-depth-1">
                        <Tab
                          options={{
                            responsiveThreshold: Infinity,
                            swipeable: false,
                          }}
                          title="Carga de factura y soportes - Fact. NO electrónica"
                        >
                          <div className="card-panel">
                            <div className="general_components Upload">
                              <Row>
                                <Col
                                  m={12}
                                  s={12}
                                  style={{ textAlign: "right" }}
                                >
                                   <BotonAyuda
                                  info={{
                                    texto: `
                                  <div style="text-align: justify !important">
                                  <p><b>&#10004; </b>  En la carpeta .ZIP solo deberán estar adjuntos las facturas y los soportes que aún conservan la numeración consecutiva de la facturación física.</p>
                                  <p><b>&#10004; </b> Crear la carpeta en formato .ZIP que contenga la factura (PDF o representación gráfica en caso de ser facturador electrónico y los respectivos soportes en caso de que aplique). No debe contener subcarpetas.</p>
                                  <p><b>&#10004;</b> Nombrar la carpeta .ZIP solo con el prefijo (si aplica) y el número de la factura sin caracteres especiales ni espacios. Si no maneja prefijo solo con el número de factura. No se debe nombrar dicha carpeta con textos como "FACTURA", "SOPORTE", "ANEXOS", "ARL", etc.</p>
                                  <p><b>&#10004;</b> Asegurar que las imágenes contenidas dentro del .ZIP deben estar en alguna de las siguientes extensiones: PDF, JPG, JPEG TIFF, TIF, PNG, XLS, XLSX.</p>
                                  <p><b>&#10004;</b> Configurar el escáner donde realizará la digitalización de los documentos para que la calidad de la imagen sea de 300DPI que es la resolución minima para que la imagen no quede ilegible (consulte con su soporte técnico). </p>
                                  <p><b>&#10004;</b> Asegurar que el peso total de los archivos contenidos en el .ZIP no supere los 100MB que es el peso máximo permitido para la carga de documentos a través del portal. Si sus soportes superan este peso deberá presentarlos de forma fisica en las instalaciones habituales designadas para la radicación de documentos a la ARL SURA.</p>
                                  </div>
                                  `,
                                    titulo: "Tips para la carga exitosa de documentos soporte",
                                    ancho: 700,
                                  }}
                                />
                                </Col>
                              </Row>
                              <div className="Content">
                                <div>{this.renderDropzone()}</div>
                                <div className="Actions">
                                  {this.renderActions()}
                                </div>
                                {this.preload()}
                                <Row>
                                  <p>
                                    <b>Peso de archivos total:</b>{" "}
                                    {Number(this.state.pesoFiles.toFixed(1))}{" "}
                                    Mb.
                                  </p>
                                  {this.renderArchivosError()}
                                  <Col m={6} s={12}>
                                    <h4>Archivos</h4>
                                    <Collapsible>
                                      {this.state.files.map((file) => {
                                        if (
                                          this.state.errorFiles.includes(
                                            file.name
                                          )
                                        )
                                          return null;

                                        const iconoFile = this.renderIcon(
                                          file.name
                                        );
                                        return (
                                          <CollapsibleItem
                                            key={file.name}
                                            header={
                                              <React.Fragment>
                                                {`${file.name} - Ver Más`}
                                                {this.renderBadge(file.name)}
                                              </React.Fragment>
                                            }
                                            icon={`${
                                              iconoFile
                                                ? iconoFile.icon
                                                : "attachment"
                                            }`}
                                            iconClassName={`${
                                              iconoFile
                                                ? iconoFile.iconClassName
                                                : "orage-text"
                                            }`}
                                          >
                                            <ul className="collection with-header">
                                              {this.renderMensajes(file.name)}
                                              {this.tipoFactura(file.name)}
                                            </ul>
                                          </CollapsibleItem>
                                        );
                                      })}
                                    </Collapsible>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </React.Fragment>
                  ) : (
                    <Row>
                      <Col s={12}>
                        <ProgressBar
                          className=""
                          color="blue"
                          active={this.state.loaderGeneral}
                        />
                      </Col>
                    </Row>
                  )
                }
                no={() => <Redirect to="/inicio" />}
              />
            );
          } else {
            if (authenticated) {
              if (!user.info.Prestadore) {
                Swal.fire({
                  type: "error",
                  title: "No tiene un prestador asignado para la carga de soportes"
                });
              }
            }
            return <Redirect to="/inicio" />;
          }
        }}
      </AuthConsumer>
    );
  }
}

Upload.contextType = AuthConsumer;

export default Upload;
