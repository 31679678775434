// export const TiposLineamientos = () => {
//   const Tipos = [
//     { id: 1, descripcion: "Normativo" },
//     { id: 2, descripcion: "Operativo" }
//   ];

//   return Tipos;
// };

export const Transportadoras = () => {
  const Transportes = [
    { id: "Servientrega", descripcion: "Servientrega" },
    { id: "Envios", descripcion: "Envios" },
    { id: "EnviEnvigado", descripcion: "EnviEnvigado" },
    { id: "Enviotodo", descripcion: "Enviotodo" },
    { id: "4-72", descripcion: "4-72" },
    { id: "FedEx", descripcion: "FedEx" },
    { id: "Coordinadora", descripcion: "Coordinadora" },
    { id: "TCC", descripcion: "TCC" },
    { id: "Deprisa", descripcion: "Deprisa" },
    { id: "Envia", descripcion: "Envia" },
    { id: "Domina Entrega Total", descripcion: "Domina Entrega Total" },
    { id: "Redservi", descripcion: "Redservi" },
    { id: "Velotax", descripcion: "Velotax" },
    { id: "Coomotor", descripcion: "Coomotor" },
    { id: "Usaco", descripcion: "Usaco" },
    { id: "Rapido Ochoa", descripcion: "Rapido Ochoa" },
    { id: "Rapidisimo", descripcion: "Rapidisimo" },
    { id: "Serviexpress", descripcion: "Serviexpress" },
    { id: "Colocar Courier", descripcion: "Colocar Courier" },
    { id: "Redex Mensajeria", descripcion: "Redex Mensajeria" },
    { id: "Mensajeros ASAP", descripcion: "Mensajeros ASAP" }
  ];

  return Transportes;
};
