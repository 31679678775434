import React from "react";
import SuraAuthentication from './SuraAuthentication';
import { AuthConsumer } from "ContexApi/authContext";

export default props => {
    return (
        <AuthConsumer>
            {({ setAuthSura, logout }) => {
                return (<SuraAuthentication setAuthSura={setAuthSura} logout={logout} {...props} />)
            }}
        </AuthConsumer>
    )
}

