import React, { useState, useContext, useEffect } from "react";
import TextInput from "react-materialize/lib/TextInput";
import Row from "react-materialize/lib/Row";
import Col from "react-materialize/lib/Col";
import { AuthConsumer } from "../../ContexApi/authContext";
import Axios from "axios";
import Swal from "sweetalert2";
import Button from "react-materialize/lib/Button";
import { constServicios } from "../../constServicios";
import { set } from "js-cookie";
import BotonAyuda from "componentes/herramientas/BotonAyuda";

const Form = () => {
  const conxtFront = useContext(AuthConsumer);
  const [data, setData] = useState({
    telefonos: "",
    idPrestador: "",
    emailNotificacionSoportesHuerfanos: "",
    emailNotificacionFacturaHuerfanos: "",
    emailImagenesCorruptas: "",
    emailFacturaIlegible: "",
    emailFacturaElectronica: "",
  })
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    getPrestador();
  }, [])
  const chageData = (e) => {
    const { value, name } = e.target;
    let newData = { ...data };
    newData[name] = String(value);
    setData(newData);
  };
  const swal = (title, type, html, confirmButtonColor, confirmButtonText) => {
    Swal.fire({
      title: title,
      type: type,
      html: html,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirmButtonColor,
    });
  };
  const onSubmit = async () => {
    try {
      if (!data.emailFacturaIlegible || !data.emailFacturaElectronica || !data.emailImagenesCorruptas || !data.emailNotificacionFacturaHuerfanos || !data.emailNotificacionSoportesHuerfanos || !data.telefonos) {
        return swal(
          "Advertencia",
          "info",
          "Todos los campos son obligatorios",
          "#002c55",
          "Cerrar"
        );
      }
      if (data.emailFacturaIlegible) {
        if (data.emailFacturaIlegible.includes(",")) {
          return swal(
            "Advertencia",
            "info",
            "Debe separar los correos por ';' (PUNTO Y COMA)",
            "#002c55",
            "Cerrar"
          );
        }
        const array = data.emailFacturaIlegible.split(";");
        const regx = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        const correosNoValidos = [];
        for (const item of array) {
          if (!regx.test(item)) {
            correosNoValidos.push(item);
          }
        }
        if (correosNoValidos.length > 0) {
          const show = correosNoValidos.join(", ");
          return swal(
            "Advertencia",
            "info",
            `Correos no permitidos en campo correos factura ilegible: ${show}`,
            "#002c55",
            "Cerrar"
          );
        }
      }
      if (data.emailFacturaElectronica) {
        if (data.emailFacturaElectronica.includes(",")) {
          return swal(
            "Advertencia",
            "info",
            "Debe separar los correos por ';' (PUNTO Y COMA)",
            "#002c55",
            "Cerrar"
          );
        }
        const array = data.emailFacturaElectronica.split(";");
        const regx = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        const correosNoValidos = [];
        for (const item of array) {
          if (!regx.test(item)) {
            correosNoValidos.push(item);
          }
        }
        if (correosNoValidos.length > 0) {
          const show = correosNoValidos.join(", ");
          return swal(
            "Advertencia",
            "info",
            `Correos no permitidos en campo correos factura electronica: ${show}`,
            "#002c55",
            "Cerrar"
          );
        }
      }
      if (data.emailImagenesCorruptas) {
        if (data.emailImagenesCorruptas.includes(",")) {
          return swal(
            "Advertencia",
            "info",
            "Debe separar los correos por ';' (PUNTO Y COMA)",
            "#002c55",
            "Cerrar"
          );
        }
        const array = data.emailImagenesCorruptas.split(";");
        const regx = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        const correosNoValidos = [];
        for (const item of array) {
          if (!regx.test(item)) {
            correosNoValidos.push(item);
          }
        }
        if (correosNoValidos.length > 0) {
          const show = correosNoValidos.join(", ");
          return swal(
            "Advertencia",
            "info",
            `Correos no permitidos en campo correos imagenes corruptas: ${show}`,
            "#002c55",
            "Cerrar"
          );
        }
      }
      if (data.emailNotificacionFacturaHuerfanos) {
        if (data.emailNotificacionFacturaHuerfanos.includes(",")) {
          return swal(
            "Advertencia",
            "info",
            "Debe separar los correos por ';' (PUNTO Y COMA)",
            "#002c55",
            "Cerrar"
          );
        }
        const array = data.emailNotificacionFacturaHuerfanos.split(";");
        const regx = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        const correosNoValidos = [];
        for (const item of array) {
          if (!regx.test(item)) {
            correosNoValidos.push(item);
          }
        }
        if (correosNoValidos.length > 0) {
          const show = correosNoValidos.join(", ");
          return swal(
            "Advertencia",
            "info",
            `Correos no permitidos en campo correos factura huerfanas: ${show}`,
            "#002c55",
            "Cerrar"
          );
        }
      }
      if (data.emailNotificacionSoportesHuerfanos) {
        if (data.emailNotificacionSoportesHuerfanos.includes(",")) {
          return swal(
            "Advertencia",
            "info",
            "Debe separar los correos por ';' (PUNTO Y COMA)",
            "#002c55",
            "Cerrar"
          );
        }
        const array = data.emailNotificacionSoportesHuerfanos.split(";");
        const regx = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        const correosNoValidos = [];
        for (const item of array) {
          if (!regx.test(item)) {
            correosNoValidos.push(item);
          }
        }
        if (correosNoValidos.length > 0) {
          const show = correosNoValidos.join(", ");
          return swal(
            "Advertencia",
            "info",
            `Correos no permitidos en campo correos soportes huerfanos: ${show}`,
            "#002c55",
            "Cerrar"
          );
        }
      }
      setDisabled(true);
      const response = await Axios({
        method: "PUT",
        url: `${constServicios.url}${constServicios.actualizarInfoPrestador}`,
        data: data,
        headers: {
          Authorization: conxtFront.accessToken,
        },
      });

      if (response.status === 200) {
        const menu = document.getElementById("slide-out");
        const fondoMenu = document.getElementsByClassName("sidenav-overlay");
        console.log(menu.style.display);
        console.log(fondoMenu[0].style.display);

        // menu.style.display = 'block';
        // fondoMenu[0].style.display = 'block';
        setDisabled(false);
        swal(
          "Actualizado Correctamente",
          "success",
          "",
          "#002c55",
          "Cerrar"
        );
      }
    } catch (e) {
      setDisabled(false);
      // console.log(e);
      swal(
        "error",
        "error",
        "Ocurrio un error al actualizar prestador",
        "#002c55",
        "Cerrar"
      );
    }
  }
  const getPrestador = async () => {
    try {
      if (conxtFront.user.info.Prestadore.idPrestador) {
        const response = await Axios({
          method: "GET",
          url: `${constServicios.url}${constServicios.getPrestador}?idPrestador=${conxtFront.user.info.Prestadore.idPrestador}`,
          headers: {
            Authorization: conxtFront.accessToken,
          },
        });
        if (response.status === 200) {
          let newData = {
            correos: response.data.correo,
            telefonos: response.data.telefonos,
            idPrestador: response.data.numeroDocumento,
            emailNotificacionSoportesHuerfanos: response.data.correosSoporteHuerfano,
            emailNotificacionFacturaHuerfanos: response.data.correosFacturaHuerfana,
            emailImagenesCorruptas: response.data.correosImagenesCorruptas,
            emailFacturaIlegible: response.data.correosFacturaIlegible,
            emailFacturaElectronica: response.data.correosFacturaElectronica,
          }
          setData(newData)
        }
      } else {
        swal(
          "error",
          "error",
          `El usuario no cuenta con un prestador asignado.`,
          "#002c55",
          "Cerrar"
        );
      }

    } catch (e) {
      swal(
        "error",
        "error",
        `Ocurrio un error consultando los datos del prestador`,
        "#002c55",
        "Cerrar"
      );
    }
  }
  return <div>
    <Row>
      <Col
        m={12}
        s={12}
        style={{ textAlign: "right" }}
      >
        <BotonAyuda
          info={{
            texto: `
                    <div style="text-align: justify !important">
                    <p><b>&#10004; </b>  <strong>Correo para notificación de factura ilegible:</strong> Factura que
                    se encuentran ilegible o con información incompleta, por tal
                    motivo no es posible continuar con el proceso de auditoría y
                    pago.</p>
                    <p><b>&#10004; </b>  <strong>Correo para notificación de imágenes corruptas:</strong> Los
                    soportes cargados presentan errores, no permite visualizar
                    su contenido, por tal motivo no es posible continuar con el
                    proceso de auditoría y pago.</p>
                    <p><b>&#10004; </b>  <strong>Correo para notificación de facturas huérfanas:</strong> Factura
                    electrónica recibida en el buzón de sura, esperando el envío
                    de los soportes por medio del portal.</p>
                    <p><b>&#10004; </b>  <strong>Correo para notificación de soportes huérfanos:</strong> Soportes cargados por medio del portal, esperando el envío
                    de la factura electrónica (XML y representación gráfica) al
                    buzón de sura.</p>
                    </div>
                                  `,
            titulo: "Tips para una actualización de datos exitosa",
            ancho: 700,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col m={6}>
        <TextInput
          m={12}
          value={data.emailFacturaIlegible}
          label='Correo para notificación de factura ilegible'
          name='emailFacturaIlegible'
          onChange={(e) => chageData(e)}
          placeholder="Escribe uno o varios correos separados por punto y coma (;)"
        />
      </Col>
      <Col m={6}>
        <TextInput
          m={12}
          value={data.emailImagenesCorruptas}
          label='Correo para notificación de imágenes corruptas'
          name='emailImagenesCorruptas'
          onChange={(e) => chageData(e)}
          placeholder="Escribe uno o varios correos separados por punto y coma (;)"
        />
      </Col>
      <Col m={6}>
        <TextInput
          m={12}
          value={data.emailNotificacionFacturaHuerfanos}
          label='Correo para notificación de facturas huérfanas'
          name='emailNotificacionFacturaHuerfanos'
          onChange={(e) => chageData(e)}
          placeholder="Escribe uno o varios correos separados por punto y coma (;)"
        />
      </Col>
      <Col m={6}>
        <TextInput
          m={12}
          value={data.emailNotificacionSoportesHuerfanos}
          label='Correo para notificación de soportes huérfanos'
          name='emailNotificacionSoportesHuerfanos'
          onChange={(e) => chageData(e)}
          placeholder="Escribe uno o varios correos separados por punto y coma (;)"
        />
      </Col>
      <Col m={6}>
        <TextInput
          m={12}
          value={data.emailFacturaElectronica}
          label='Correo para notificación de factura electronica'
          name='emailFacturaElectronica'
          onChange={(e) => chageData(e)}
          placeholder="Escribe uno o varios correos separados por punto y coma (;)"
        />
      </Col>
      <Col m={6}>
        <TextInput
          m={12}
          value={data.telefonos}
          label='Teléfonos'
          name='telefonos'
          onChange={(e) => chageData(e)}
          onKeyPress={
            (event) => {
              var key = window.event ? event.which : event.keyCode;
              if (key === 59) {
                return false;
              }
              if (key < 48 || key > 57) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          }
          placeholder="Escribe uno o varios telefonos separados por punto y coma (;)"
        />
      </Col>
      <Button
        disabled={disabled}
        onClick={() => {
          onSubmit()
        }}
      >
        {!disabled ? 'Guardar' : 'Guardando...'}
      </Button>

      <Button
        onClick={() => {
          setData({
            telefonos: "",
            idPrestador: "",
            emailNotificacionSoportesHuerfanos: "",
            emailNotificacionFacturaHuerfanos: "",
            emailImagenesCorruptas: "",
            emailFacturaIlegible: "",
            emailFacturaElectronica: "",
          })
        }}
        style={{ marginLeft: "1vw" }}
      >
        Limpiar
      </Button>
    </Row>
  </div >
}
export default Form;
