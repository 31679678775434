import React, { Component } from "react";
import { DatePicker } from "react-materialize";
import { filtersDB, filtersPaginationDB } from "./filtersDB";
import "./css/style.css";
import Swal from "sweetalert2";
import { downloadEcxel } from "./utils";
import { limit } from "./pagination";
import moment from "moment";
import TableRIPS from "./tableRIPS.js";

class FilterCliente extends Component {
  state = {
    filters: {
      cliente: 0,
      prestador: 0,
      factura: 0,
      remision: 0,
      fechaInicio: 0,
      fechaFin: 0,
      offset: 0,
      limit: limit,
      currentPage: 1
    },
    dataRIPS: [],
    totalFacturas: 0,
    dataEcxel: []
  };

  pagePrevious = async () => {
    await this.setState({
      filters: {
        ...this.state.filters,
        offset: this.state.filters.offset - limit,
        currentPage: this.state.filters.currentPage - 1
      }
    });
    this.getData("viewData");
  };

  pageFallowing = async () => {
    await this.setState({
      filters: {
        ...this.state.filters,
        offset: this.state.filters.offset + limit,
        currentPage: this.state.filters.currentPage + 1
      }
    });
    this.getData("viewData");
  };

  finalPage = totalFacturas =>
    this.state.filters.currentPage === Math.ceil(Number(totalFacturas) / limit);

  onChangePag = async page => {
    await this.setState({
      filters: {
        ...this.state.filters,
        offset: page * limit,
        currentPage: page + 1
      }
    });
    this.getData("viewData");
  };

  onChange = e =>
    this.setState({
      filters: { ...this.state.filters, [e.target.name]: e.target.value || 0 }
    });

  getData = type => {
    switch (type) {
      case "viewData":
        filtersPaginationDB(
          this.props.token,
          this.props.usuario,
          this.state.filters
        )
          .then(async data => {
            console.log(data);
            if (data.status === 200) {
              this.setState({
                dataRIPS: data.result,
                totalFacturas: data.result.length
              });
            } else {
              this.setState({
                filters: { ...this.state.filters, currentPage: 1, offset: 0 },
                dataRIPS: []
              });
              Swal.fire({
                type: "error",
                text: "No hay datos para su consulta"
              });
            }
          })
          .catch(e => console.log("error peticions get:", e));
        break;
      case "excel":
        filtersDB(this.props.token, this.props.usuario, this.state.filters)
          .then(async data => {
            if (data.result.length > 0) {
              let dataEcxel = [];
              await dataEcxel.push([
                "ID prestador",
                "Nombre prestador",
                "Fecha de cargue Exitoso de los RIPS",
                "Numero Remision",
                "Numero de factura"
              ]);
              await data.result.map(dat => {
                dataEcxel.push([
                  dat.NMCODIGO_PRESTADOR,
                  this.props.usuario.info.Prestadore.nombrePrestador,
                  moment(dat.FECHAREGISTRO).format("LLL"),
                  dat.DSNUMERO_REMISION,
                  dat.DSNUMERO_FACTURA
                ]);
                return true;
              });
              downloadEcxel(dataEcxel);
            }
          })
          .catch(e => console.log("error peticions get:", e));
        break;

      default:
        break;
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const {
      cliente,
      prestador,
      factura,
      remision,
      fechaInicio,
      fechaFin
    } = this.state.filters;
    if (
      cliente === 0 &&
      prestador === 0 &&
      factura === 0 &&
      remision === 0 &&
      fechaInicio === 0 &&
      fechaFin === 0
    ) {
      this.setState({ dataRIPS: [] });
      Swal.fire({
        type: "error",
        text: "Poner un filtro minimo"
      });
    } else {
      this.getData("viewData");
    }
  };

  render() {
    return (
      <React.Fragment>
        <form className="" onSubmit={e => this.onSubmit(e)}>
          <div className="input-field col l12 s12">
            <input
              id="prestador"
              name="prestador"
              type="text"
              className="validate"
              onChange={e => this.onChange(e)}
            />
            <label htmlFor="prestador">Prestador</label>
          </div>
          <div className="input-field col l6 s12">
            <input
              id="factura"
              name="factura"
              type="text"
              className="validate"
              onChange={e => this.onChange(e)}
            />
            <label htmlFor="factura">Factura</label>
          </div>

          <div className="input-field col l6 s12">
            <input
              id="remision"
              name="remision"
              type="number"
              className="validate"
              onChange={e => this.onChange(e)}
            />
            <label htmlFor="remision">Remision</label>
          </div>

          <div className="input-field col l3 s6">
            <label htmlFor="fechaInicio">Fecha inicio</label>
            <DatePicker
              id="fechaInicio"
              name="fechaInicio"
              className="datepicker"
              options={{ autoClose: true }}
              onChange={e =>
                this.setState({
                  filters: { ...this.state.filters, fechaInicio: e }
                })
              }
            />
          </div>
          <div className="input-field col l3 s6">
            <label htmlFor="fechaFin">Fecha fin</label>
            <DatePicker
              id="fechaFin"
              name="fechaFin"
              className="datepicker"
              options={{ autoClose: true }}
              onChange={e =>
                this.setState({
                  filters: { ...this.state.filters, fechaFin: e }
                })
              }
            />
          </div>

          <div className="input-field col l6 s12">
            <button
              className="btn btn_form_repopreradicado"
              type="submit"
              name="action"
            >
              Enviar
              <i className="material-icons right">send</i>
            </button>
            {this.state.dataRIPS.length > 0 ? (
              <button
                className="btn btn_form_repopreradicado"
                type="button"
                onClick={() => this.getData("excel")}
              >
                Descargar
                <i className="material-icons right">get_app</i>
              </button>
            ) : (
              ""
            )}
          </div>
        </form>
        <div className="col s12">
          <TableRIPS dataRIPS={this.state.dataRIPS} user={this.props.usuario} />
          {/* {this.state.dataRIPS.length > 0 ? (
                <Pagination
                  currentPage={this.state.filters.currentPage}
                  previousPage={this.pagePrevious}
                  followingPage={this.pageFallowing}
                  onChangePag={this.onChangePag}
                  finalPages={this.finalPage(this.state.totalFacturas || 0)}
                  totalPages={Math.ceil(
                    Number(this.state.totalFacturas || 0) / limit
                  )}
                />
              ) : (
                ""
              )} */}
        </div>
      </React.Fragment>
    );
  }
}
export default FilterCliente;
