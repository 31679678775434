import Swal from 'sweetalert2';
import Tiff from 'tiff.js';
import { CANVAS_ID } from '../index';
import { BASE_URL } from '../../../constServicios';
const panzoom = require('panzoom');


const API_VISOR = ({ url, type }) => `${BASE_URL}api/visor?id=${url}&type=${type || 'S3'}`;

const tifExtensions = /(.TIF|.tif|.TIFF|.tiff)$/i;
const pdfExtensions = /(.PDF|.pdf)$/i;

const LOAD_START = 'Un momento por favor, cargando...',
  LOAD = 'Se cargo el archivo',
  LOAD_END = 'Termino la solicitud al archivo',
  ERROR = '¡Error solicitando el archivo!',
  ERROR_TIMEOUT = `¡Error, tiempo de carga agotado!`,
  ERROR_URL = `¡No se puede cargar el archivo!`,
  ERROR_EXTENSION = `Error solo archivos tif,pdf`,
  ABORT = 'Se cancelo la solicitud';

function eventFire(el, etype) {
  if (el) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}
const ApiVisor = async ({ page, url, container, scale, type }) => {
  let size = 0;
  let por = 0;
  let pags = 1;
  if (document.getElementById(container)) {
    document.getElementById(container).innerHTML = "";
    document.getElementById(`${container}_btns`).innerHTML = "";
    const theCanvas = document.createElement('canvas');
    theCanvas.id = CANVAS_ID;
    document.getElementById(container).appendChild(theCanvas);
    theCanvas.ondblclick = async e => {
      await eventFire(document.getElementById('resetZoom'), 'click');
      const theDiv = document.createElement('div');
      theDiv.id = 'theDiv';
      theCanvas.id = 'newCanvas';
      theDiv.appendChild(theCanvas)
      Swal.fire({
        html: theDiv,
        width: '100%',
        height: '100%',
        confirmButtonText: "Cerrar"
      }).then(result => {
        document.getElementById(container).appendChild(theCanvas);
        theCanvas.id = CANVAS_ID;
        zoom(theCanvas.id)
      })
      if (document.getElementById('newCanvas')) {
        zoom('newCanvas')
      }
    };
    const canvasContext = theCanvas.getContext("2d");
    canvasText({ theCanvas, canvasContext, text: `cargando... ${5}%` });

    const xhr = new XMLHttpRequest();

    xhr.responseType = 'arraybuffer';

    // xhr.addEventListener('loadstart', () => console.log(LOAD_START));

    // xhr.addEventListener('load', () => console.log(LOAD));

    // xhr.addEventListener('loadend', () => console.log(LOAD_END));

    xhr.addEventListener('error', e => {
      canvasText({ theCanvas, canvasContext, text: ERROR });
      console.log(ERROR, e);
    });

    xhr.addEventListener('abort', () => {
      abortXhr({ theCanvas, canvasContext, container });
      console.log(ABORT);
    });

    xhr.addEventListener('progress', async e => {
      if (e.lengthComputable) {
        por = await parseInt(e.loaded / e.total * 100);
        size = (e.total / 1000000);
        canvasText({ theCanvas, canvasContext, text: `cargando... ${por}%` });
        // console.log(`Cargando :: %${por}`);
      };
    });

    // btnAbort({ container, xhr });
    timeOut({ xhr, url, time: 15000, theCanvas, canvasContext });

    btnDownload({ container, url, xhr });

    // xhr.open('GET', API_VISOR({ url, type }));
    xhr.open('GET', API_VISOR({ url: !type ? encodeURIComponent(url) : url, type }));
    // xhr.setRequestHeader("key", KEY_ACCES_BACK_END);

    xhr.send();

    xhr.onload = async e => {
      if (xhr.status === 201 || xhr.status === 4 || xhr.status === 200) {
        if (url) {
          if (url.includes('.tif') || url.includes('.TIF') || url.includes('.tiff') || url.includes('.TIFF')) {
            const tiff = new Tiff({ buffer: xhr.response });
            const file = new Blob([xhr.response], { type: 'image/tiff' });
            const canvas = await tiff.toCanvas();
            if (canvas) {
              if (document.getElementById(container)) {
                document.getElementById(container).innerHTML = "";
                document.getElementById(`${container}_btns`).innerHTML = `
                  <div class="center-align">
                    <div class="pdf_btns_content">
                      <div class="col l12">
                      <p class="visor_document_size">${size.toFixed(2)} MB</p>
                        <button  id="zoomoutbutton" class="btn-color-arus btn btn-small" type="button">
                          reset zoom
                        </button>
                        <a id="downloadTiff" class="btn-color-arus btn btn-small" download>
                          <i class="material-icons left">cloud_download</i>
                          Descargar
                        </a>
                      </div>
                    </div>
                  </div>  
                      
                `;
                if (document.getElementById('zoomoutbutton')) {
                  document.getElementById('zoomoutbutton').addEventListener('click', () => {
                    zoom(theCanvas.id);
                  });
                }
                if (document.getElementById('downloadTiff')) {
                  document.getElementById('downloadTiff').href = URL.createObjectURL(file);
                }

                await canvas.setAttribute('style', `width:${tiff.width()}px; height:${tiff.height()}px;`);
                await canvas.setAttribute('class', 'canvas_tiff');
                await canvas.setAttribute('id', CANVAS_ID);
                document.getElementById(container).append(canvas);
                document.getElementById(CANVAS_ID).addEventListener('click', e => console.log(`Y:${e.clientY} X:${e.clientX}`));
                zoom(theCanvas.id);
              }
            }
          // } else if (pdfExtensions.exec(url)) {
            } else if (url.includes('.pdf') || url.includes('.PDF')) {
            if (document.getElementById(container)) {
              document.getElementById(container).innerHTML = "";
              document.getElementById(container).appendChild(theCanvas);
            }

            const file = new Blob([xhr.response], { type: 'application/pdf' });
            const pdfjsLib = window['pdfjs-dist/build/pdf'];// referencia en el index principal del front "public/index.html"
            pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://mozilla.github.io/pdf.js/build/pdf.worker.js';
            const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
            loadingTask.promise.then(async pdf => {
              await pdf.getPage(page || 1).then(pag => {
                const viewport = pag.getViewport({ scale: scale || 1.5 });
                canvasContext.clearRect(0, 0, theCanvas.width, theCanvas.height);
                theCanvas.height = viewport.height;
                theCanvas.width = viewport.width;
                const RenderTask = pag.render({ canvasContext, viewport });
                RenderTask.promise.then(async () => {

                })
                  .catch(error => console.log('error render pdf::', error));
                zoom(theCanvas.id);
              });
              if (document.getElementById(`${container}_btns`)) {
                document.getElementById(`${container}_btns`).innerHTML = `
                <div class="center-align">
                  <div class="pdf_btns_content">
                    <div class="col l12">
                      <span id="pags_totalPages" class="view_pages">Página: ${pags}/${pdf.numPages}</span>
      
                      <button id="pdfBtnAnt" class="btn-color-arus btn btn-small" onClick={this.prevPage}>
                        <i class="material-icons left">keyboard_arrow_left</i>
                      </button>
      
                      <button id="pdfBtnSig" class="btn-color-arus btn btn-small" >
                        <i class="material-icons right">keyboard_arrow_right</i>
                      </button>
                  
                  
                      <button  id="resetZoom" class="btn-color-arus btn btn-small" type="button">
                        reset zoom
                      </button>
                  
                      <a  id="pdfDownload" class="btn-color-arus btn btn-small" download>
                        <i class="material-icons left">cloud_download</i>
                        Descargar
                      </a>
                      <p class="visor_document_size">${size.toFixed(2)} MB</p> 
                    </div>
                  </div>
                </div>          
                `;
              }

              if (document.getElementById('pdfBtnAnt')) {
                document.getElementById('pdfBtnAnt').addEventListener('click', async () => {
                  if (pags > 1) {
                    pags = await pags - 1;
                    paginacion({ pags, pdf, scale, container });
                  }
                });
              }

              if (document.getElementById('pdfBtnSig')) {
                document.getElementById('pdfBtnSig').addEventListener('click', async () => {
                  if (pags < pdf.numPages) {
                    pags = await pags + 1;
                    paginacion({ pags, pdf, scale, container });
                  }
                });
              }

              if (document.getElementById('resetZoom')) {
                document.getElementById('resetZoom').addEventListener('click', () => {
                  zoom(theCanvas.id);
                });
              }

              if (document.getElementById('pdfNewPages')) {
                document.getElementById('pdfNewPages').href = URL.createObjectURL(file);
              }

              if (document.getElementById('pdfDownload')) {
                document.getElementById('pdfDownload').href = URL.createObjectURL(file);
              }

            }, reason => console.error('Error leyendo el archivo Pdf :: ', reason));
          } else {
            canvasText({ theCanvas, canvasContext, text: ERROR_EXTENSION });
          }

        } else {
          canvasText({ theCanvas, canvasContext, text: ERROR_URL });
          console.log('Error cargando la imagen en el visor link:', url ? url : null);

        }

      } else {
        console.log('Error cargando el archivo en el visor :: status', xhr.status);
      }
    };

  } else {
    throw 'No se puede renderizar el visor porque no ha montado el componente';
  }
};


const paginacion = ({ pags, pdf, scale, container }) => {
  document.getElementById('pags_totalPages').innerHTML = `Página: ${pags}/${pdf.numPages}`;
  pdf.getPage(pags).then(async pag => {
    const viewport = pag.getViewport({ scale: scale || 1.5 });
    if (document.getElementById(container)) {
      document.getElementById(container).innerHTML = "";
      const theCanvas = document.createElement('canvas');
      theCanvas.id = CANVAS_ID;
      await document.getElementById(container).appendChild(theCanvas);
      theCanvas.onclick = e => console.log(`Y:${e.clientY} X:${e.clientX}`);
      const canvasContext = theCanvas.getContext('2d');
      theCanvas.height = viewport.height;
      theCanvas.width = viewport.width;
      const RenderTask = pag.render({ canvasContext, viewport });
      RenderTask.promise.then(() => { })
        .catch(error => console.log('error render pdf::', error));
      zoom(theCanvas.id);
    }
  });
};

const zoom = (canvas) => {
  const element = document.getElementById(canvas);
  const options = { maxZoom: 10, minZoom: 1, zoomDoubleClickSpeed: 0, pinchSpeed: 2, smoothScroll: false }
  if (element) {
    panzoom(element, options);
  }
};

const canvasText = ({ theCanvas, canvasContext, text }) => {
  canvasContext.clearRect(0, 0, theCanvas.width, theCanvas.height);
  theCanvas.width = 450;
  theCanvas.height = 100;
  canvasContext.font = "30px Arial";
  canvasContext.fillText(text, 10, 50);
}

const abortXhr = ({ theCanvas, canvasContext, container }) => {
  canvasText({ theCanvas, canvasContext, text: `Cancelando...` });
  setTimeout(() => {
    canvasContext.clearRect(0, 0, theCanvas.width, theCanvas.height);
    canvasContext.fillText(`no hay archivos para mostrar`, 10, 50);
    const node = document.getElementById("btn_canvas_abort");
    node.parentNode.removeChild(node);
  }, 1500);
}

const btnAbort = ({ container, xhr }) => {
  const buttonCancel = document.createElement('button');
  buttonCancel.type = 'button';
  buttonCancel.id = 'btn_canvas_abort';
  buttonCancel.setAttribute('class', 'btn btn-small center-align');
  buttonCancel.setAttribute('style', 'display:block; margin:0 auto; margin-bottom:10px; ')
  buttonCancel.innerHTML = 'cancelar';
  buttonCancel.onclick = () => xhr.abort();
  document.getElementById(container).appendChild(buttonCancel);
}

const btnDownload = ({ container, url, xhr }) => {
  if (url && tifExtensions.exec(url) || pdfExtensions.exec(url)) {
    const divDownload = document.createElement('div');
    divDownload.setAttribute('style', 'display:block; margin:0 auto; margin-bottom:10px; ');
    divDownload.setAttribute('id', 'div_container_download_btn');
    divDownload.innerHTML = `
      <a href="${url}" class="btn btn-small" download target="_blank">
        <i class="material-icons left">cloud_download</i>
        Descargar
      </a>
    `;
    document.getElementById(container).appendChild(divDownload);
  }
}

const timeOut = ({ xhr, time, url, theCanvas, canvasContext }) => {
  xhr.timeout = time;
  xhr.ontimeout = e => canvasText({ theCanvas, canvasContext, text: ERROR_TIMEOUT });
}

export default ApiVisor;