import React, { Fragment } from "react";
import { AuthConsumer } from "../../../ContexApi/authContext";
import Can from "../../../ContexApi/Can";
import { Redirect } from "react-router-dom";
//Estilos Materialize
import { Tab, Tabs } from "react-materialize";
import FormPertinencia from "./form";
const Pertinencia = () => {
  return (
    <AuthConsumer>
      {({ authenticated, user }) =>
        authenticated ? (
          <Can
            rules={user.permisos}
            perform="reportePertinencia:ver"
            yes={() => (
              <Fragment>
                <Tabs className="tab-demo z-depth-1">
                  <Tab title="Reporte Pertinencia">
                    <div className="card-panel">
                      <FormPertinencia />
                    </div>
                  </Tab>
                </Tabs>
              </Fragment>
            )}
            no={() => <Redirect to="/inicio" />}
          />
        ) : (
          <Redirect to="/inicio" />
        )
      }
    </AuthConsumer>
  );
};

export default Pertinencia;
