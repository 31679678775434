import { MOSTRAR_MENU } from '../actions/types';

//Cada reducer tiene su propio state

const initialState = {
    menus: []
}

export default function(state = initialState, action){
    switch(action.type){
        case MOSTRAR_MENU:
            return{
                ...state,
                menus: action.payload
            }
        default:
            return state;
    }
}