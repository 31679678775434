import React, { Fragment, useContext, useState, useEffect } from 'react'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { AuthConsumer } from "../../../ContexApi/authContext"
import { constServicios } from "../../../constServicios"
import Axios from 'axios'
import Select from 'react-materialize/lib/Select'
import TextInput from 'react-materialize/lib/TextInput'
import DatePicker, { registerLocale } from "react-datepicker"
import Button from 'react-materialize/lib/Button'
import Swal from 'sweetalert2'
import GridPertinencia from './grid'
import es from "date-fns/locale/es";
const FormPertinencia = () => {
  const conxtFront = useContext(AuthConsumer)
  const [cliente, setCliente] = useState([])
  const [tipologias, setTipologias] = useState([])
  const [responsables, setResponsables] = useState([])
  const [estados, setEstados] = useState([])
  const [mostrar, setMostrar] = useState(false)
  const [datosGrid, setDatosGrid] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [data, setData] = useState(
    {
      cliente: '',
      tipologia: '',
      responsable: '',
      aplicativo: '',
      asignacion: '',
      factura: '',
      prestadorId: '',
      fechaRadInicio: '',
      fechaRadFin: ''
    }
  )
  const [estadosPorCliente, setEstadosPorCliente] = useState(null)
  const consultarClientes = async () => {
    try {
      let result = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlApiClientesGestion}`,
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      if (Number(conxtFront.user.info.Cliente.idCliente) === 3) {
        const indice = result.data.clientes.findIndex(i => i.id === 1)
        if (indice >= 0) {
          setData({
            cliente: result.data.clientes[indice].id,
            tipologia: '',
            responsable: '',
            aplicativo: '',
            asignacion: '',
            factura: '',
            prestadorId: '',
            fechaRadInicio: '',
            fechaRadFin: ''
          })
          setCliente([result.data.clientes[indice]])
          setTipologias(result.data.clientes[indice].tipologia)
        }
      }
      if (Number(conxtFront.user.info.Cliente.idCliente) === 2) {
        const indice = result.data.clientes.findIndex(i => i.id === 2)
        setTipologias(result.data.clientes[1].tipologia)
        if (indice >= 0) {
          setData({
            cliente: result.data.clientes[indice].id,
            tipologia: '',
            responsable: '',
            aplicativo: '',
            asignacion: '',
            factura: '',
            prestadorId: '',
            fechaRadInicio: '',
            fechaRadFin: ''
          })
          setCliente([result.data.clientes[indice]])
          setTipologias(result.data.clientes[indice].tipologia)
        }
      }
      if (Number(conxtFront.user.info.Cliente.idCliente) === 4) {
        const indice = result.data.clientes.findIndex(i => i.id === 3)
        if (indice >= 0) {
          setData({
            cliente: result.data.clientes[indice].id,
            tipologia: '',
            responsable: '',
            aplicativo: '',
            asignacion: '',
            factura: '',
            prestadorId: '',
            fechaRadInicio: '',
            fechaRadFin: ''
          })
          setCliente([result.data.clientes[indice]])
          setTipologias(result.data.clientes[indice].tipologia)
        }
      }
      if (Number(conxtFront.user.info.Cliente.idCliente) === 5) {
        setData({
          cliente: 1,
          tipologia: '',
          responsable: '',
          aplicativo: '',
          asignacion: '',
          factura: '',
          prestadorId: '',
          fechaRadInicio: '',
          fechaRadFin: ''
        })
        setCliente(result.data.clientes)
        setTipologias(result.data.clientes[0].tipologia)
      }

    } catch (error) {
      console.error(error);
    }
  }
  const consultarResponsable = async () => {
    try {
      let result = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlApiResponsablesGestion}`,
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      setResponsables(result.data);
    } catch (error) {
      console.error(error);
    }
  }
  const consultarEstados = async () => {
    try {
      let result = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlApiEstadosGestion}`,
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      setEstados([]);
      setEstadosPorCliente(result.data)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    consultarEstados()
    consultarResponsable()
    consultarClientes()
    registerLocale("es", es);
  }, [])

  const chageData = (e) => {
    const { value, name } = e.target
    let newData = { ...data }
    newData[name] = String(value)
    setData(newData)
    if (name === "cliente") {
      let indice = cliente.findIndex(i => i.id === Number(value))
      setTipologias(cliente[indice].tipologia)
      if (Number(value) === 2) {
        setEstados(estadosPorCliente.EPS)
      }
      if (Number(value) === 3) {
        setEstados(estadosPorCliente.ARL)
      }
      if (Number(value) === 3) {
        setEstados([])
      }
    }
  }
  const soloLetrasYNumeros = e => {
    let tecla = (document.all) ? e.keyCode : e.which;

    //Tecla de retroceso para borrar, siempre la permite
    if (tecla === 8 || tecla === 9 || tecla === 32) {
      return true;
    }

    // Patron de entrada, en este caso solo acepta numeros y letras
    let patron = /[A-Za-z0-9]/;
    let tecla_final = String.fromCharCode(tecla);
    let esc = patron.test(tecla_final);
    if (!esc) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  useEffect(() => {
    if (estadosPorCliente) {
      if (Number(data.cliente) === 1) {
        setEstados([])
      }
      if (Number(data.cliente) === 2) {
        setEstados(estadosPorCliente.EPS)
      }
      if (Number(data.cliente) === 3) {
        setEstados(estadosPorCliente.ARL)
      }
    }
  }, [data.cliente])
  const consultarPertinencia = async () => {
    try {
      setDisabled(true)
      await validarDatos()
      let result = await Axios({
        method: "post",
        url: `${constServicios.url}${constServicios.urlApiPertinenciaGestion}`,
        data: data,
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      if (result.data.length > 0) {
        setDatosGrid(result.data)
        setMostrar(true)
        setDisabled(false)
      } else {
        // Swal.fire("No se encontraron resultados")
        Swal.fire({
          title: "No se encontraron resultados",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
        setDisabled(false)
        setMostrar(false)

      }
    } catch (error) {
      Swal.fire({
        title: "No se encontraron resultados",
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
      setDisabled(false)
      setMostrar(false)

    }
  }
  const validarDatos = () => {
    try {
      if (!data.cliente && !data.tipologia && !data.aplicativo && !data.factura && !data.responsable && !data.fechaRadFin && !data.fechaRadInicio && !data.prestadorId) {
        // Swal.fire("No hay datos para consultar")
        Swal.fire({
          title: "No hay datos para consultar",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
        throw "No hay datos para consultar"
      }
      if (!data.cliente) {
        // Swal.fire("Tiene que seleccionar cliente")
        Swal.fire({
          title: "Tiene que seleccionar cliente",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
        throw "Tiene que seleccionar cliente"
      }
      if (!data.tipologia) {
        // Swal.fire("Tiene que seleccionar tipologia")
        Swal.fire({
          title: "Tiene que seleccionar tipologia",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
        throw "Tiene que seleccionar tipologia"
      }
      if (data.fechaRadInicio && !data.fechaRadFin) {
        // Swal.fire("No puedes consultar por fecha inicial si no seleccionas una fecha final")
        Swal.fire({
          title: "No puedes consultar por fecha inicial si no seleccionas una fecha final",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
        throw "No puedes consultar por fecha inicial si no seleccionas una fecha final"
      }
      if (!data.fechaRadInicio && data.fechaRadFin) {
        // Swal.fire("No puedes consultar por fecha final si no seleccionas una fecha inicial")
        Swal.fire({
          title: "No puedes consultar por fecha final si no seleccionas una fecha inicial",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
        throw "No puedes consultar por fecha final si no seleccionas una fecha inicial "
      }
      if (data.fechaRadFin && data.fechaRadInicio && data.fechaRadInicio > data.fechaRadFin) {
        // Swal.fire("La fecha inicial no puede ser mayor que la final");
        Swal.fire({
          title: "Error",
          type: "error",
          html: "La fecha inicial no puede ser mayor que la final",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
        throw "La fecha inicial no puede ser mayor que la final"
      }
    } catch (error) {
      throw error
    }
  }



  const crearForm = () => {
    return <Fragment>
      <Row>
        <Col m={4}>
          <Select label={"Cliente"} onChange={(e) => chageData(e)} m={12} value={data.cliente} name={"cliente"}>
            {
              cliente.map((i, key) => {
                return <option key={key} value={i.id}>
                  {i.descripcion}
                </option>
              })
            }
          </Select>
        </Col>
        <Col m={4}>
          <Select label={"Tipologia"} onChange={(e) => chageData(e)} m={12} value={data.tipologia} name={"tipologia"}>
            <option value="">
              Seleccione una tipologia
            </option>
            {
              tipologias.map((i, key) => {
                return <option key={key} value={i.id}>
                  {i.descripcion}
                </option>
              })
            }
          </Select>
        </Col>
        <Col m={4}>
          <Select label={"Responsable de Pertinencia"} onChange={(e) => chageData(e)} m={12} value={data.responsable} name={"responsable"}>
            <option value="">
              Seleccione un responsable
            </option>
            {
              responsables.map((i, key) => {
                return <option key={key} value={i.id}>
                  {i.responsable}
                </option>
              })
            }
          </Select>
        </Col>
        <Col m={4}>
          <Select label={"Estado del Aplicativo de Negocio"} onChange={(e) => chageData(e)} m={12} value={data.aplicativo} name={"aplicativo"}>
            <option value="">
              Seleccione un estado
            </option>
            {
              estados.map((i, key) => {
                return <option key={key} value={i.id}>
                  {i.estado}
                </option>
              })
            }
          </Select>
        </Col>
        <Col m={4}>
          <Select disabled={true} label={"Estado de Asignación"} onChange={(e) => chageData(e)} m={12} value={data.asignacion} name={"asignacion"}>
            <option value={0}>
              Seleccione un estado
            </option>
            {/* {
              estados.map((i, key) => {
                return <option key={key} value={i.id}>
                  {i.estado}
                </option>
              })
            } */}
          </Select>
        </Col>
        <Col m={4}>
          <TextInput onKeyPress={(e) => soloLetrasYNumeros(e)} label={"Numero de Factura"} onChange={(e) => chageData(e)} m={12} value={data.factura} name={"factura"} />
        </Col>
        <Col m={4}>
          <TextInput onKeyPress={
            (event) => {
              var key = window.event ? event.which : event.keyCode;
              if (key < 48 || key > 57) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          } label={"Id del prestador"} onChange={(e) => chageData(e)} m={12} value={data.prestadorId} name={"prestadorId"} />
        </Col>
        <Col m={4}>
          <label> Fecha de radicacion inicial </label>
          <div style={{ marginTop: "-0.5vw" }}>
            <DatePicker
              selected={data.fechaRadInicio}
              onChange={date => setData({ ...data, fechaRadInicio: date })}
              dateFormat="dd-MM-yyyy"
              onKeyDown={e => e.preventDefault()}
              placeholderText="Seleccione una fecha"
              locale="es"
            />
          </div>
        </Col>
        <Col m={4}>
          <label> Fecha de radicacion final </label>
          <div style={{ marginTop: "-0.5vw" }}>
            <DatePicker
              selected={data.fechaRadFin}
              onChange={date => setData({ ...data, fechaRadFin: date })}
              dateFormat="dd-MM-yyyy"
              onKeyDown={e => e.preventDefault()}
              locale="es"
              placeholderText="Seleccione una fecha"
            />
          </div>
        </Col>
      </Row>
      <div className="center" style={{ marginLeft: "1vw" }}>
        <Button disabled={disabled} onClick={() => consultarPertinencia()}>
          {disabled ? "Consultando..." : "Consultar"}
        </Button>
        <Button style={{ marginLeft: "1vw" }} onClick={() => {
          setData({
            cliente: data.cliente,
            tipologia: '',
            responsable: '',
            aplicativo: '',
            asignacion: '',
            factura: '',
            prestadorId: '',
            fechaRadInicio: '',
            fechaRadFin: ''
          })
          setMostrar(false)
        }}>
          Limpiar
        </Button>
      </div>
      {mostrarGrid()}
    </Fragment>
  }
  const mostrarGrid = () => {
    if (mostrar) {
      return <GridPertinencia datosGrid={datosGrid} />
    }
  }
  return (
    crearForm()

  )
}
export default FormPertinencia