export const constPRERADICADO = {
  mensajeInfoFisico:
    "Al seleccionar esta opción recuerde que usted debe entregar los soportes y/o facturas en las sedes que la EPS le indique de manera física",
  mensajeInfoDigital:
    "Al seleccionar esta opción recuerde que usted debe cargar los soportes y/o facturas por el portal web en el módulo carga de soportes",
  extencionesPermitidas: [".xls", ".xlsx"],
  tituloInfo: "Aviso",
  tituloError: "Error",
  nombreFisico: "fisico",
  nombreDigital: "digital",
  mensajeErrorExtenciones: "Tipo de archivo no permitido solo se permiten: ",
  mensajeErrorArchivo: "Debes selectionar un archivo",
  mensajeErrorTipo: "Debes selectionar un tipo",
  btnArchivoPreradicado: "Examinar",
  tituloTipo: "Tipo",
  labelTipoFisico: "Físico",
  labelTipoDigital: "Digital",
  btnCarga: "cargar",
  tagArchivo: "file",
  tagTipo: "data",
  rutaPlantilla: "",
  btnDecargarArchivo: "Descargar plantilla",
  msjPreRadicadoFisico:
    "Señor usuario, el pre-radicado generado debe ser impreso para ser presentado con las facturas físicamente en las oficinas que previamente la EPS le indique. Sin esta impresión no podrá confirmarse la recepción del mismo",
  msjPreRadicadoDigital: ""
};
