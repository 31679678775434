const _buttons = [
  {
    extend: "copy",
    className: "btn btn-color-arus"
  },
  {
    extend: "excel",
    className: "btn"
  },
  {
    extend: "csv",
    className: "btn"
  }
];

const _lenguageEspañol = {
  sProcessing: "Procesando...",
  sLengthMenu: "Mostrar _MENU_ registros",
  sZeroRecords: "No se encontraron resultados",
  sEmptyTable: "Ningún dato disponible en esta tabla",
  sInfo:
    "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
  sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
  sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
  sInfoPostFix: "",
  sSearch: "Buscar:",
  sUrl: "",
  sInfoThousands: ",",
  sLoadingRecords: "Cargando...",
  oPaginate: {
    sFirst: "Primero",
    sLast: "Último",
    sNext: "Siguiente",
    sPrevious: "Anterior"
  },
  oAria: {
    sSortAscending: ": Activar para ordenar la columna de manera ascendente",
    sSortDescending: ": Activar para ordenar la columna de manera descendente"
  }
};

const _dom = "Bfrtip";

export const constTablaGuias = {
  language: _lenguageEspañol,
  dom: _dom,
  buttons: _buttons
};

export const constTablaLineamientos = {
    language: _lenguageEspañol,
    order: [[ 1, "desc" ]],
    autoWidth: false,
    columnDefs: [
      { "width": "20%", "targets": 4 }
    ]
  };
