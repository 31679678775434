import React, { Component } from "react";
import FilterArus from "./filterArus";
import FilterCliente from "./filterCliente";
import FilterPrestador from "./filterPrestador";

//Auth
import { Redirect } from "react-router-dom";
import Can from "../../../ContexApi/Can";
import { AuthConsumer } from "../../../ContexApi/authContext";

class ReportesRips extends Component {
  state = {
    filters: {
      cliente: "",
      prestador: "",
      remision: "",
      factura: "",
      fechaInicial: "",
      fechaFinal: ""
    }
  };
  // constructor(props) {
  //   super(props);
  // }

  filterUsuarios = (user, token) => {
    return (
      <React.Fragment>
        <Can
          rules={user.permisos}
          perform="reportes:ripsFilterPrestador"
          yes={() => <FilterPrestador usuario={user} token={token} />}
          no={() => null}
        />
        <Can
          rules={user.permisos}
          perform="reportes:ripsFilterCliente"
          yes={() => <FilterCliente usuario={user} token={token} />}
          no={() => null}
        />
        <Can
          rules={user.permisos}
          perform="reportes:ripsFilterArus"
          yes={() => <FilterArus usuario={user} token={token} />}
          no={() => null}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user, accessToken }) =>
          authenticated ? (
            <Can
              rules={user.permisos}
              perform="reportes:visit"
              yes={() => (
                <div className="general_components card-panel">
                  <div className="row">{this.filterUsuarios(user, accessToken)}</div>
                </div>
              )}
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}
export default ReportesRips;
