import React, { Component } from "react";
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { constServicios } from "../constServicios";
import Cookies from "js-cookie";
//Const parametros
import { INACTIVE_TIME_APP, WAIT_TIME_APP } from "../Variables/constParams";

import { AUTH_CONFIG } from "../Variables/auth0-variables";
import { AuthProvider } from "./authContext";

//SweetAlert 2
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import aes256 from "aes256";
import { BrowserRouter as Router } from "react-router-dom";
import { WindowScroller } from "react-virtualized";
//Sockets
// import socketIOClient from "socket.io-client";

// const cookieName = "sura_integration";

const MySwal = withReactContent(Swal);

const UNAUTHORIZED = 401;

const auth = new auth0.WebAuth({
  domain: AUTH_CONFIG.domain,
  clientID: AUTH_CONFIG.clientId,
  redirectUri: AUTH_CONFIG.callbackUrl,
  responseType: AUTH_CONFIG.responseType,
  scope: AUTH_CONFIG.scope,
});

let key = "";

class Auth extends Component {
  state = {
    authenticated: false,
    authWeb: true,
    params: [],
    user: {
      role: "visitor",
    },
    accessToken: "",
    intervalNotificaciones: null,
    noti: { cantidad: 0, notificaciones: [] },
    notificaciones: false,
    validarPrestador: true
  };

  constructor() {
    super();
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { status } =
          typeof error.response != "undefined" ? error.response : { status: 0 };
        if (status === UNAUTHORIZED) {
          // this.logout();
          if (this.state.accessToken != "") {
            setTimeout(() => {
              // window.location.href = "/logout?token=" + this.state.accessToken;
            }, 3000);
          }
        }
        return Promise.reject(error);
      }
    );
    let time;
    this.inactivityTime();
  }

  crearSetIntervalNotificaciones = async (tiempo) => {
    this.setState({
      intervalNotificaciones: setInterval(() => {
        try {
          if (
            this.state.authenticated &&
            this.state.accessToken &&
            this.state.accessToken !== ""
          ) {
            axios
              .get(`${constServicios.url}${constServicios.urlNotifi}`, {
                headers: { Authorization: this.state.accessToken },
              })
              .then((resp) => {
                if (resp.status === 200) {
                  this.setState({
                    noti: resp.data,
                  });
                } else {
                  this.setState({
                    noti: { cantidad: 0, notificaciones: [] },
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (error) {
          console.log(error);
        }
      }, tiempo),
    });
  };

  borrarSetIntervalNotificaciones = async () => {
    try {
      this.setState({
        intervalNotificaciones: clearInterval(
          this.state.intervalNotificaciones
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  inactivityTime = async () => {
    window.onload = this.resetTimer;
    // DOM Events
    document.onmousemove = this.resetTimer;
    document.onkeypress = this.resetTimer;
  };

  resetTimer = () => {
    try {
      if (this.state.authenticated) {
        const inactivetimeapp = this.state.params.filter(
          (param) => param.key === INACTIVE_TIME_APP
        )[0]
          ? this.state.params.filter(
              (param) => param.key === INACTIVE_TIME_APP
            )[0].value
          : 900000;
        clearTimeout(this.time);
        this.time = setTimeout(() => {
          this.inactiveLogout();
        }, parseInt(inactivetimeapp)); //TODO tiempo parametrizable
        // 1000 milliseconds = 1 second
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentWillMount() {
    this.mount();
  }

  mount = async () => {
    try {
      const AuthLS = sessionStorage.getItem("AuthState");
      if (AuthLS) {
        const {data} = await axios.post(
          `${constServicios.url}${constServicios.urlDec}`,{
            data:AuthLS
          }
        );
        if (data.authenticated && data.notificaciones) {
          let tiempoNotificaciones = 300000;
          tiempoNotificaciones = data.params.filter(
            (x) => x.key === "timeGetNotificacion"
          );

          if (tiempoNotificaciones) {
            tiempoNotificaciones = parseInt(tiempoNotificaciones[0].value);
          }
          this.crearSetIntervalNotificaciones(tiempoNotificaciones);
        }
        this.setState(data);
      }
    } catch (error) {}
  };

  setAuthState = async() => {
    if (this.state.authenticated) {
      let copiaState = this.state;
      copiaState.socketIoClient = null;
      const {data:{data}} = await axios.post(
        `${constServicios.url}${constServicios.urlEnc}`,{
          data:copiaState
        }
      );
      sessionStorage.setItem("AuthState", data);
    }
  };

  componentDidUpdate() {
    this.setAuthState();
  }

  initiateLogin = () => {
    auth.authorize();
  };

  inactiveLogout = () => {
    try {
      let timerInterval;
      const timewaitapp = this.state.params.filter(
        (param) => param.key === WAIT_TIME_APP
      )[0].value;
      MySwal.fire({
        title: "Cierre de sesion por inactividad",
        html: "<strong></strong>",
        timer: parseInt(timewaitapp), //TODO tiempo parametrizable
        onBeforeOpen: () => {
          MySwal.showLoading();

          timerInterval = setInterval(() => {
            MySwal.getContent().querySelector(
              "strong"
            ).textContent = MySwal.getTimerLeft();
          }, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.dismiss === MySwal.DismissReason.timer) {
          window.location.href = "/logout?token=" + this.state.accessToken;
          // this.logout()
          // this.setState({
          //   authenticated: false,
          //   user: {
          //     role: "visitor"
          //   },
          //   accessToken: ""
          // });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  logout = (dataWso2 = null) => {
    if (dataWso2 != null) {
      if (dataWso2.state != undefined) {
        // Cookies.remove(cookieName);
        sessionStorage.clear();
        try {
          const data = {
            token: dataWso2.state,
          };
          axios.post(`${constServicios.urlLogout}`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } catch (err) {
          console.log(err);
        }

        if (this.state.intervalNotificaciones)
          this.borrarSetIntervalNotificaciones();

        this.setState({
          authenticated: false,
          authWeb: true,
          params: [],
          user: {
            role: "visitor",
          },
          accessToken: "",
          intervalNotificaciones: null,
          noti: { cantidad: 0, notificaciones: [] },
          notificaciones: false,
        });
      }
    }
  };

  handleAuthentication = () => {
    auth.parseHash((error, authResult) => {
      if (error) {
        console.log(error);
        console.log(`Error ${error.error} occured`);
        return;
      }

      this.setSession(authResult);
    });
  };

  async setSession(authResult, usuario = null, authWeb = true) {
    try {
      const tokendecode = jwt_decode(authResult.accessToken);

      if (usuario === null) {
        usuario = await axios.get(
          `${constServicios.url}${constServicios.urlInfoUser}`,
          {
            headers: {
              Authorization: authResult.accessToken,
            },
          }
        );
      }

      const params = await axios.get(
        `${constServicios.url}${constServicios.urlParamsApp}`,
        {
          headers: {
            Authorization: authResult.accessToken,
          },
        }
      );

      //se valida si esta activas las notificaciones
      let notificaciones = false;
      let tiempoNotificaciones = 300000;

      if (params.data) {
        notificaciones = params.data.filter((x) => x.key === "Notificaciones");
        if (notificaciones)
          notificaciones = notificaciones[0].value === "1" ? true : false;

        tiempoNotificaciones = params.data.filter(
          (x) => x.key === "timeGetNotificacion"
        );
        if (tiempoNotificaciones) {
          tiempoNotificaciones = parseInt(tiempoNotificaciones[0].value);
        }
      }

      if (notificaciones)
        this.crearSetIntervalNotificaciones(tiempoNotificaciones);

      const resPermisos = await axios.get(
        `${constServicios.url}${constServicios.urlGetPermisos}`,
        {
          headers: {
            Authorization: authResult.accessToken,
          },
        }
      );
      let permisos = { static: [] };
      if (resPermisos.status === 200) {
        permisos = resPermisos.data;
      }

      const { correo, primerNombre, primerApellido } = usuario.data;

      let rol = "";
      if (usuario.data.Role) {
        rol = usuario.data.Role.nombre;
      }

      let lineaDeNegocio = null;
      if (
        usuario.data.lineaDeNegocio &&
        usuario.data.lineaDeNegocio != undefined &&
        usuario.data.lineaDeNegocio != ""
      ) {
        lineaDeNegocio = usuario.data.lineaDeNegocio;
      }

      const user = {
        id: tokendecode.sub,
        info: usuario.data,
        email: correo ? correo : "N/A",
        name: `${primerNombre ? primerNombre : ""} ${
          primerApellido ? primerApellido : ""
        }`,
        role: rol,
        permisos,
        lineaDeNegocio,
      };
      this.setState({
        authenticated: true,
        authWeb,
        accessToken: authResult.accessToken,
        params: params.data,
        user,
        notificaciones,
      });
    } catch (error) {
      this.setState({
        // authenticated: true,
        accessToken: authResult.accessToken,
      });

      MySwal.fire({
        title: "Información",
        type: "warning",
        html: "Ocurrió un problema al iniciar sesión en la plataforma.",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#002c55",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onClose: () => {
          window.location.href = "/logout?token=" + authResult.accessToken;
        },
      }).then((result) => {
        if (result.value) {
          window.location.href = "/logout?token=" + authResult.accessToken;
        }
      });
    }
  }

  setAuthSura = async (token, user) => {
    await this.setSession({ accessToken: token }, user, false);
  };

  setAuthSanitas = async (token, user) => {
    await this.setSession({ accessToken: token }, user, false);
  };

  // Funcion inicial para cambiar cliente y prestador al tiempo
  setClientePrestador = async (datos) => {
    try {
      let clienteElegido = this.state.user.info.ClientesAsignados.filter(
        (item) => {
          if (item.idCliente == datos.cliente) {
            return item;
          }
        }
      );

      let user = this.state.user;

      user.info.Cliente = clienteElegido[0].Cliente;
      user.info.Prestadore = datos.datosPrestador;

      this.setState({
        user,
      });
      return 1;
    } catch (error) {
      return 0;
    }
  };

  // Cambiar cliente desde modulo administrativo portal
  setCliente = async (datos) => {
    try {
      let clienteElegido = this.state.user.info.ClientesAsignados.filter(
        (item) => {
          if (item.idCliente == datos.cliente) {
            return item;
          }
        }
      );

      let user = this.state.user;

      user.info.Cliente = clienteElegido[0].Cliente;
      user.info.Prestadore = null;

      this.setState({
        user,
      });
      return 1;
    } catch (error) {
      return 0;
    }
  };

  // Cambiar prestador desde modulo administrativo portal
  setPrestador = async (datos) => {
    try {
      let user = this.state.user;

      user.info.Prestadore = datos.datosPrestador;

      this.setState({
        user,
      });
      return 1;
    } catch (error) {
      return 0;
    }
  };
  setValidarPrestador = async (bool) => {
    try {
      this.setState({
        validarPrestador: bool
      });
      return 1;
    } catch (error) {
      return 0;
    }
  };
  render() {
    const authProviderValue = {
      ...this.state,
      initiateLogin: this.initiateLogin,
      handleAuthentication: this.handleAuthentication,
      logout: this.logout,
      setAuthSura: this.setAuthSura,
      setAuthSanitas: this.setAuthSanitas,
      setClientePrestador: this.setClientePrestador,
      setCliente: this.setCliente,
      setPrestador: this.setPrestador,
      setValidarPrestador: this.setValidarPrestador
    };
    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    );
  }
}

export default Auth;
