import React, { Component, Fragment } from "react";
import { Select, Row, Col, TextInput } from "react-materialize";
import { AuthConsumer } from "../../../ContexApi/authContext";
import axios from "axios";
import { constServicios } from "../../../constServicios";

class DocumentoTipoDocumentoPrestador extends Component {
  state = {
    TiposDocumento: [],
    select: "",
    prestador: ""
  };

  componentWillMount = async () => {
    try {
      const TiposDocumento = await axios.get(
        `${constServicios.url}${constServicios.urlTiposDocumentos}`,
        {
          headers: {
            Authorization: this.context.accessToken
          }
        }
      );

      this.setState({
        TiposDocumento: TiposDocumento.data
      });

      // Si el dato viene desde otro componente, ubico al proveedor
      if (this.props.idPrest) {
        await axios
          .get(
            `${constServicios.url}${constServicios.urlPrestador}/${this.props.idPrest}`,
            {
              headers: {
                Authorization: this.context.accessToken
              }
            }
          )
          .then(res => {
            if (res.data) {
              this.setState({
                select: res.data.TiposDocumentoIdTiposDocumento,
                prestador: res.data.numeroDocumento
              });

              this.props.tipoDocumento(res.data.TiposDocumentoIdTiposDocumento);
              this.documentoStateBlur(res.data.idPrestador);
            } else {
            }
          })
          .catch(err =>
            console.log(
              "Hubo un error en la consulta del Prestador en TipoPrestador" +
                err
            )
          );
      }
    } catch (error) {}
  };

  clienteState = value => {
    this.setState({
      select: value.target.value
    });
    this.props.tipoDocumento(value.target.value);
  };

  documentoState = value => {
    this.setState({
      prestador: value.target.value
    });
  };
  documentoStateBlur = value => {
    this.props.documento(this.state.prestador);
  };

  render() {
    return (
      <Fragment>
        <Select
          s={5}
          label="Tipo Documento"
          onChange={this.clienteState}
          value={this.state.select}
        >
          <option disabled value="">
            Seleccione
          </option>
          {this.state.TiposDocumento.map((item, key) => {
            return (
              <option key={key} value={item.idTiposDocumento}>
                {item.descripcion}
              </option>
            );
          })}
        </Select>
        <TextInput
          s={7}
          label="Número de documento prestador"
          onBlur={this.documentoStateBlur}
          value={this.state.prestador}
          onChange={this.documentoState}
        />
      </Fragment>
    );
  }
}

DocumentoTipoDocumentoPrestador.contextType = AuthConsumer;

export default DocumentoTipoDocumentoPrestador;
