import React, { Component } from "react";

//constantes guias
import { constGuias } from "./constGuias";

//estructura guia
import Guia from "./Guia";

//Estilos Materialize
import { Table } from "react-materialize";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//constante
import { constTablaGuias } from "../../Variables/constTabla";

import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import * as jzip from "jszip";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
window.JSZip = jzip;

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class TablaGuias extends Component {
  state = {};

  dttable = () => {
    $(document).ready(function() {
      if ($.fn.dataTable.isDataTable("#datatable")) {
        $("#datatable").DataTable();
      } else {
        $("#datatable").DataTable(constTablaGuias);
      }
    });
  };

  render() {
    try {
      if (this.props.guias === undefined) return null;
      if (this.props.guias === "" && !this.state.msgError) {
        Toast.fire({
          type: "error",
          title: `Nro Pre-Radicado no existe`
        });

        this.setState({
          msgError: true
        });
        return null;
      } else if (this.props.guias === "") return null;

      const { GuiasPreRadicados, idPreradicado } = this.props.guias;
      if (GuiasPreRadicados.length > 0) {
        return (
          <React.Fragment>
            <h5 className="text-center my-5">
              Listado guias asociadas a {idPreradicado}
            </h5>

            <div className="row">
              <div id="admin" className="col s12">
                <div className="material-table">
                  <Table id="datatable" className="display">
                    <thead>
                      <tr>
                        {/* <th data-field="id">{constGuias.columId}</th>
                        <th data-field="preradicado">
                          {constGuias.columPreRadicado}
                        </th> */}
                        <th data-field="numeroguia">
                          {constGuias.columNumGuia}
                        </th>
                        <th data-field="operador">
                          {constGuias.columOperadorLogistico}
                        </th>
                        {/* <th data-field="path">{constGuias.columPath}</th> */}
                        <th data-field="acciones">
                          {constGuias.columAcciones}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(GuiasPreRadicados).map(guia => (
                        <Guia
                          key={guia}
                          info={GuiasPreRadicados[guia]}
                          borrarGuiaTabla={this.props.borrarGuiaTabla}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            {this.dttable()}
          </React.Fragment>
        );
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default TablaGuias;
