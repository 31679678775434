import React, { Component, Fragment } from "react";
import { constENVIOCLIENTES } from "./constEnvioCliente";
import { constServicios } from "../../constServicios";
import "./css/EnvioCliente.css";
import { Row, ProgressBar, Col, Tab, Tabs } from "react-materialize";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Axios from "axios";
// import Axios, { post } from "axios";

//Auth
import { AuthConsumer } from "../../ContexApi/authContext";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";
// import { file } from "@babel/types";

import BotonAyuda from "../herramientas/BotonAyuda";

const MySwal = withReactContent(Swal);

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class EnvioCliente extends Component {
  state = {
    envioload: false
  };

  constructor() {
    super();
    this.files = React.createRef();
  }

  loadInputFile = () => {
    this.files.current.value = null;
    this.setState({
      refresh: Math.random(100)
    });
  };

  validaciones = () => {
    if (this.files.current.files.length === 0) {
      Toast.fire({
        type: "error",
        title: constENVIOCLIENTES.mensajeErrorArchivo
      });
      return false;
    } else {
      const nameFile = this.files.current.files[0].name;
      const extencion = nameFile.substring(nameFile.lastIndexOf("."));
      if (constENVIOCLIENTES.extencionesPermitidas.indexOf(extencion) < 0) {
        Toast.fire({
          type: "error",
          title: `${constENVIOCLIENTES.mensajeErrorExtenciones}${[
            ...constENVIOCLIENTES.extencionesPermitidas
          ]}`
        });
        return false;
      }
    }

    if (this.state.tipo === "") {
      Toast.fire({
        type: "error",
        title: constENVIOCLIENTES.mensajeErrorTipo
      });
      return false;
    }

    return true;
  };

  cargarPre = e => {
    try {
      e.preventDefault();
      if (!this.validaciones()) return null;

      const { accessToken } = this.context;
      this.setState({
        envioload: true
      });

      var formData = new FormData();
      formData.append(
        constENVIOCLIENTES.tagArchivo,
        this.files.current.files[0]
      );

      Axios.post(
        `${constServicios.url}${constServicios.urlArchivoEnvioCliente}`,
        formData,
        {
          timeout: 20000000,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: accessToken
          }
        }
      )
        .then(resp => {
          if (resp.status === 200) {
            MySwal.fire({
              title: constENVIOCLIENTES.tituloInfo,
              type: "success",
              html: resp.data.message,
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          } else {
            MySwal.fire({
              title: 'Ocurrio un error en el proceso. Comunicate con un administrador.',
              type: "error",
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          }
          this.setState(
            {
              envioload: false
            },
            this.loadInputFile
          );
        })
        .catch(err => {
          this.setState(
            {
              envioload: false
            },
            this.loadInputFile
          );
          if (!err.response) {
            MySwal.fire({
              title: "respuesta sin datos",
              type: "error",
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          } else {
            MySwal.fire({
              title: err.response.data.error,
              type: "error",
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
            this.loadInputFile();
          }
        });
    } catch (e) {
      MySwal.fire({
        title:e.message,
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
    }
  };

  preload = () => {
    if (!this.state.envioload) return null;
    return (
      <Row>
        <Col offset="s3" s={6}>
          <ProgressBar />
        </Col>
      </Row>
    );
  };

  renderBtnCarga = () => {
    return (
      <button
        className="btn btn-color-arus right-align"
        disabled={this.state.envioload}
      >
        {constENVIOCLIENTES.btnCarga}
      </button>
    );
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Can
              rules={user.permisos}
              perform="envioCliente:ver"
              yes={() => (
                <Fragment>
                  <Tabs className="tab-demo z-depth-1">
                    <Tab title="Envío Cliente">
                      <div className="general_components">
                        <div className="card-panel">
                          <BotonAyuda
                            info={{
                              texto: `Descarga la plantilla en el siguiente enlace: <a href="/files/FormatoEnvioCliente.xlsx" target="_blank" download className="btn waves-effect waves-light cyan accent-4">Descargar plantilla</a>`,
                              titulo: "Ayuda Envío Cliente"
                            }}
                          />
                          <form onSubmit={this.cargarPre}>
                            <div className="row">
                              <div className="col l8 m8 s12 offset-l2 offset-m2">
                                <div className="file-field input-field">
                                  <div className="btn btn-color-arus">
                                    <span>
                                      {
                                        constENVIOCLIENTES.btnArchivoEnvioCliente
                                      }
                                    </span>
                                    <input
                                      accept={
                                        constENVIOCLIENTES.extencionesPermitidas
                                      }
                                      type="file"
                                      ref={this.files}
                                      key={this.state.refresh}
                                    />
                                  </div>
                                  <div className="file-path-wrapper">
                                    <input
                                      className="file-path validate"
                                      type="text"
                                      placeholder="Arrastrar y soltar..."
                                      key={this.state.refresh}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.preload()}
                            <Row>
                              {/* <Col s={12} m={6}>
                                <Link
                                  to="/files/FormatoEnvioCliente.xlsx"
                                  target="_blank"
                                  download
                                  className="btn btn-color-arus"
                                >
                                  {constENVIOCLIENTES.btnDecargarArchivo}
                                </Link>
                              </Col> */}
                              <Col m={12} className="center">
                                {this.renderBtnCarga()}
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Fragment>
              )}
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}
EnvioCliente.contextType = AuthConsumer;
export default EnvioCliente;
