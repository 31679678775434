import React, { Component } from "react";

//Estilos Materialize
import {
  Row,
  Col,
  Collection,
  CollectionItem,
  Icon,
  Tab,
  Tabs,
} from "react-materialize";

//DataTable.net
import "datatables.net-dt/css/jquery.dataTables.css";

//Auth
import { AuthConsumer } from "../../ContexApi/authContext";
import { Redirect, Link } from "react-router-dom";
import Can from "../../ContexApi/Can";

class Reportes extends Component {
  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Can
              rules={user.permisos}
              perform="reportes:ver"
              yes={() => (
                <Tabs className="tab-demo z-depth-1">
                  <Tab title="Reportes">
                    <div className="general_components card-panel">
                      <Row>
                        <Col m={12} s={12}>
                          <Collection header="">
                            <Can
                              rules={user.permisos}
                              perform="reporteLineamientosOperativos:ver"
                              yes={() => (
                                <CollectionItem>
                                  Reporte lineamientos operativos
                                  <Link
                                    className="secondary-content color-arus"
                                    to="/reportes/lineamientosOperativos"
                                  >
                                    <Icon className="">send</Icon>
                                  </Link>
                                </CollectionItem>
                              )}
                              no={() => null}
                            />

                            <Can
                              rules={user.permisos}
                              perform="reportePertinencia:ver"
                              yes={() => (
                                <CollectionItem>
                                  Reporte de pertinencia
                                  <Link
                                    className="secondary-content color-arus"
                                    to="/reportes/reportePertinencia"
                                  >
                                    <Icon>send</Icon>
                                  </Link>
                                </CollectionItem>
                              )}
                              no={() => null}
                            />

                            <Can
                              rules={user.permisos}
                              perform="reportelogCargaSoportes:ver"
                              yes={() => (
                                <CollectionItem>
                                  Reporte log de cargas de soportes
                                  <Link
                                    className="secondary-content color-arus"
                                    to="/reportes/logDeCargasDeSoportes"
                                  >
                                    <Icon>send</Icon>
                                  </Link>
                                </CollectionItem>
                              )}
                              no={() => null}
                            />

                            <Can
                              rules={user.permisos}
                              perform="reporteRips:ver"
                              yes={() => (
                                <CollectionItem>
                                  Reporte log de RIPS
                                  <Link
                                    className="secondary-content color-arus"
                                    to="/reportes/ReporteRips"
                                  >
                                    <Icon>send</Icon>
                                  </Link>
                                </CollectionItem>
                              )}
                              no={() => null}
                            />
                            <Can
                              rules={user.permisos}
                              perform="reporteTrazabilidadNotificaciones:ver"
                              yes={() => (
                                <CollectionItem>
                                  Reporte Trazabilidad de Notificaciones
                                  <Link
                                    className="secondary-content color-arus"
                                    to="/reportes/reporteTrazabilidadNotificaciones"
                                  >
                                    <Icon>send</Icon>
                                  </Link>
                                </CollectionItem>
                              )}
                              no={() => null}
                            />
                            <Can
                              rules={user.permisos}
                              perform="reporteTrazabilidadNotificaciones:ver"
                              yes={() => (
                                <CollectionItem>
                                  Reporte de notificaciones enviadas
                                  <Link
                                    className="secondary-content color-arus"
                                    to="/reportes/reporteNotificacionesEnviadas"
                                  >
                                    <Icon>send</Icon>
                                  </Link>
                                </CollectionItem>
                              )}
                              no={() => null}
                            />
                            <Can
                              rules={user.permisos}
                              perform="reportelogCargaSoportes:ver"
                              yes={() => (
                                <CollectionItem>
                                  Reporte estados factura
                                  <Link
                                    className="secondary-content color-arus"
                                    to="/reportes/estadosFactura"
                                  >
                                    <Icon>send</Icon>
                                  </Link>
                                </CollectionItem>
                              )}
                              no={() => null}
                            />
                          </Collection>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                </Tabs>
              )}
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

Reportes.contextType = AuthConsumer;

export default Reportes;
