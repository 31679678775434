import React, { Component } from 'react';
import Iframe from 'react-iframe'

//constantes
import { constServicios } from '../../constServicios';

//Auth
import { AuthConsumer } from "../../ContexApi/authContext";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";

import Swal from "sweetalert2";

class RIPS extends Component {
    state = {}

    render() {
        return (
            <AuthConsumer>
                {({ authenticated, user, accessToken }) => {
                    if (authenticated && user.info.Prestadore) {
                        if (!user.info.Cliente.codCliente && user.info.Cliente.codCliente === "") {
                            Swal.fire({
                                type: "error",
                                title: "El cliente no puede cargar RIPS no tiene codigo",
                            });

                            return <Redirect to="/inicio" />
                        }

                        return (
                            <Can
                                rules={user.permisos}
                                perform="rips:ver"
                                yes={() => (
                                    <Iframe url={`${constServicios.urlRIPS}/?token=${accessToken}&cliente=${user.info.Cliente.idCliente}&prestador=${user.info.Prestadore.idPrestador}`}
                                        width="100%"
                                        height="900px"
                                        id="myId"
                                        display="initial"
                                        position="relative"
                                        allow="cookies"
                                    />)}
                                no={() => <Redirect to="/inicio" />}
                            />)
                    } else {
                        if (!user.info.Prestadore) {
                            Swal.fire({
                                type: "error",
                                title: "No tiene un prestador asignado para la carga RIPS",
                            });
                        }
                        return (<Redirect to="/inicio" />)
                    }
                }}
            </AuthConsumer>

        );
    }
}

export default RIPS;