import React, { Fragment } from "react";
import { Tab, Tabs } from "react-materialize";
import BuscarTablas from "./BuscarTablas";

const TablasMaestras = () => {
  return (
    <Fragment>
      <Tabs className="tab-demo z-depth-1">
        <Tab
          title="Tablas Maestras"
        >
          <BuscarTablas />
        </Tab>
      </Tabs>
    </Fragment>
  );
};

export default TablasMaestras;
