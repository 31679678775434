import React, { Component } from "react";
import { Select } from "react-materialize";
import { AuthConsumer } from "../../../ContexApi/authContext";

class ComboClientes extends Component {
  state = {
    clientesAsignados: [],
    select: ""
  };

  componentWillMount = () => {
    const clientesAsignados = this.context.user.info.ClientesAsignados;
    this.setState({
      clientesAsignados
    });
  };

  clienteState = value => {
    this.setState({
      select: value.target.value
    });
    this.props.comboCliente(value.target.value);
  };

  limpiar = () => {
    console.log("limpiador");
  }

  render() {
    return (
      <Select
        s={12}
        label="Clientes Asignados"
        onChange={this.clienteState}
        value={this.state.select}
      >
        <option disabled value="">
          Seleccione un cliente
        </option>
        {this.state.clientesAsignados.map((item, key) => {
          return (
            <option key={key} value={item.idCliente}>
              {item.Cliente.nombreCliente}
            </option>
          );
        })}
      </Select>
    );
  }
}

ComboClientes.contextType = AuthConsumer;

export default ComboClientes;
