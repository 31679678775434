/* eslint-disable array-callback-return */
import React, { useEffect, useState, Fragment } from "react"
import Table from "react-materialize/lib/Table"
import Row from "react-materialize/lib/Row"
import { Col, Button, Select, TextInput } from "react-materialize"
import { Pagination as Paginador } from "react-materialize"
import exportarCSV_XLSX from "./exportar";

const GridLogSoporte = (props) => {
  const [detallesPagina, setDetallesPagina] = useState(10)
  const [pagina, setPagina] = useState(1)
  const [paginas, setPaginas] = useState(1)
  const [dataFiltrada, setDataFiltrada] = useState(props.datosGrid)

  useEffect(() => {
    setDataFiltrada(props.datosGrid)
  }, [props])

  useEffect(() => {
    setPaginas(Math.ceil(dataFiltrada.length / detallesPagina))
  }, [detallesPagina, dataFiltrada, props])

  const detalleInicialPagina = (pagina - 1) * detallesPagina
  const detalleFinalPagina = detalleInicialPagina + detallesPagina
  const crearCabeceras = () => {
    if(dataFiltrada.length > 0){
      let cabeceraTabla = Object.keys(dataFiltrada[0])
      return cabeceraTabla.map((i, key) => {
        return <th key={key}>{i}</th>
      })
    }
    
  }
  const crearCuerpo = () => {
    return dataFiltrada.map((row, key) => {
      return <tr key={key}>
        {
          crearTd(row)
        }
      </tr>
    }).slice(detalleInicialPagina, detalleFinalPagina)
  }
  const crearTd = (row) => {
    let campo = Object.keys(row)
    return campo.map((i, key) => {
      return <td key={key}>{row[i]}</td>
    })
  }
  const buscaTabla = (e) => {
    let texto = e.target.value.toLowerCase();
    const filtro = props.datosGrid.filter((row) => {
        let keys = Object.keys(row)
        for (const clave of keys) {
            if (String(row[clave]).toLowerCase().indexOf(texto) !== -1) {
                return true
            }
        }
    })
    setDataFiltrada(filtro)
}
  const crearTabla = () => {
    return <Fragment>
      <Row style={{ marginTop: "2vw" }}>
        <Col l={3}>
          <Button onClick={() => { exportarCSV_XLSX(props.datosGrid, "trazabilidadNotificaciones") }} >
            Exportar
          </Button>
        </Col>
        <Col l={9}>
          <div className="right col l2" >
            <Select onChange={(e) => { setDetallesPagina(Number(e.target.value)) }} l={12} defaultValue="10" label="Cantidad">
              <option value="2">
                2
                                </option>
              <option value="5">
                5
                                </option>
              <option value="10">
                10
                                </option>
              <option value="50">
                50
                                </option>
              <option value="100">
                100
                                </option>
            </Select>
          </div>
          
          <div className="right col m4">
            <TextInput onKeyUp={(e) => {
              buscaTabla(e)
            }} name="buscar" l={12} label="Buscar" />
          </div>
          <div className="right col m6">
            <Paginador
              activePage={pagina}
              items={paginas}
              className="right"
              maxButtons={4}
              onSelect={index => setPagina(index)}
            />
          </div>
        </Col>
      </Row>
      <div style={{ overflow: "auto" }}>
        <Table style={{width: "180%"}}>
          <thead>
            <tr>
              {crearCabeceras()}
            </tr>
          </thead>
          <tbody>
            {crearCuerpo()}
          </tbody>
        </Table>
      </div>
    </Fragment>
  }

  return crearTabla()
}

export default GridLogSoporte;