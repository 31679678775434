import React, { Component, Fragment } from "react";
import { Tabs, Tab } from "react-materialize";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//Auth
import { AuthConsumer } from "../../ContexApi/authContext";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";
import CreacionUsuario from "../AdminUsuarios/CreacionUsuario";
import EditUsuario from "../AdminUsuarios/EditUsuario";
import AdminRoles from "../AdminUsuarios/AdminRoles";
import NoEnContrada from "../error_404/index";
import "../AdminUsuarios/css/AdminUsuarios.css";
const MySwal = withReactContent(Swal);

//Barcode
// const JsBarcode = require("jsbarcode");

// const Toast = MySwal.mixin({
//   toast: true,
//   position: "bottom-end",
//   showConfirmButton: false,
//   timer: 3000
// });

class Configuracion extends Component {
  state = {};

  btnAlert = () => {
    MySwal.fire({
      type: "warning",
      text: "La sesión ha expirado, por favor autentícate nuevamente.",
      width: 300
    });
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Fragment>
              <div className="general_components">
                <Can
                  rules={user.permisos}
                  perform="configuraciones:ver"
                  yes={() => (
                    <Fragment>
                      <Tabs className="tab-demo z-depth-1">
                        <Tab title="Crear Usuario" active>
                          <CreacionUsuario />
                        </Tab>
                        <Tab title="Actualizar usuario">
                          <EditUsuario />
                        </Tab>
                        <Tab title="Administrar Roles" className="tab disabled">
                          {1 === 2 ? <NoEnContrada /> : <AdminRoles />}
                        </Tab>
                      </Tabs>
                    </Fragment>
                  )}
                  no={() => <Redirect to="/inicio" />}
                />
              </div>
            </Fragment>
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}
export default Configuracion;
