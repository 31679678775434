import React, { Component } from "react";
import { Link } from "react-router-dom";

//axios
import axios from "axios";
import { constServicios } from "../../constServicios";

//Estilos Materialize
import {
  TextInput,
  Row,
  Col,
  Collection,
  CollectionItem,
  Icon,
  ProgressBar,
  Button
} from "react-materialize";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import TablaGuias from "./TablaGuias";
import { AuthConsumer } from "ContexApi/authContext";

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class MostrarGuias extends Component {
  constructor() {
    super();
    this.txtPreradicado = React.createRef();
  }

  state = {
    btnAsociar: false,
    preradicado: "",
    preload: false,
    msgError: false,
    guias: {}
  };

  borrarGuiaTabla = idGuia => {
    this.setState({
      guias: this.state.guias.GuiasPreRadicados.filter(
        guia => guia.idGuia !== idGuia
      )
    });
  };

  renderBtnAsociar = () => {
    if (this.state.guias === undefined) return null;
    else if (this.state.guias === "") return null;

    const { idPreradicado } = this.state.guias;

    if (idPreradicado !== "" && idPreradicado !== undefined)
      return (
        <Link to={`/crearGuia/${idPreradicado}`}>
          <Button floating className="btn-color-arus" waves="light">
            <i class="material-icons">add</i>
          </Button>
          {/* Asociar Guia a {idPreradicado} */}
        </Link>
      );
    else return null;
  };

  preload = () => {
    if (!this.state.preload) return null;
    return (
      <Row>
        <Col offset="s3" s={6}>
          <ProgressBar />
        </Col>
      </Row>
    );
  };

  renderGuias = () => {
    return (
      <TablaGuias
        key={this.state.preradicado}
        guias={this.state.guias}
        borrarGuiaTabla={this.borrarGuiaTabla}
      />
    );
  };

  consultar = e => {
    e.preventDefault();
    const pre = this.txtPreradicado.current.inputRef.value;
    if (pre === "") {
      Toast.fire({
        type: "error",
        title: `debe ingresar un nro pre-radicado`
      });
      return null;
    }

    this.setState({
      msgError: false,
      preradicado: pre,
      preload: true,
      guias: {}
    });
    const { accessToken, user } = this.context;
    const body = {
      idPreradicado: pre,
      idCliente: user.info.Cliente.idCliente,
      tipoUsuario: 1,
      accion: "buscar"
    };

    axios
      .post(`${constServicios.url}${constServicios.urlGuias}`, body, {
        headers: {
          Authorization: accessToken
        }
      })
      .then(resp => {
        if (resp.status === 200) {
          this.setState({
            guias: resp.data
          });
        }
        this.setState({
          preload: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          preload: false
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="card-panel">
          <form onSubmit={this.consultar}>
            <Row>
              <Col m={12} s={12} l={12} xl={12}>
                <Collection>
                  <CollectionItem className="center-align valign-wrapper">
                    <TextInput
                      label="Nro. Pre-Radicado"
                      ref={this.txtPreradicado}
                      type="number"
                      min="0"
                      m={10}
                      s={10}
                      l={10}
                      xl={10}
                    />
                    <Button
                      className="secondary-content btn-color-arus"
                      onClick={this.consultar}
                      style={{ marginRight: "5px" }}
                      disabled={this.state.preload}
                    >
                      Buscar
                    </Button>
                  </CollectionItem>
                </Collection>
              </Col>
              <Col />
            </Row>
            {this.preload()}
            {this.renderBtnAsociar()}
            <div className="row justify-content-center">
              {this.renderGuias()}
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

MostrarGuias.contextType = AuthConsumer;

export default MostrarGuias;
