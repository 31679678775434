import React, { Component } from "react";
import "../AdminUsuarios/css/AdminUsuarios.css";

class Loader extends Component {
  render() {
    return (
      <div className="progress ">
        <div className="indeterminate"></div>
      </div>
    );
  }
}

export default Loader;
