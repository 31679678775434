import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const downloadEcxel = async (dataEcxel) => {
  let wb = XLSX.utils.book_new();
  wb.Props = {
      Title:'Hoja de preradicados factura',
      Subject:'preradicos-facturas',
      Author:'cmi portal',
      CreatedDate:new Date()
  };
  wb.SheetNames.push('hoja 1');
  let ws_data = dataEcxel;
  let ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets['hoja 1'] = ws;
  
  let wbout = XLSX.write(wb,{ bookType:'xlsx',type:'binary' });

  const s2ab = s => {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for( let i = 0 ;i < s.length;i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf; 
  }
  saveAs( new Blob([s2ab(wbout)],{type:'application/octet-stream'}),'preradicados_facturas.xlsx');
};
