import React, { Component, Fragment } from "react";
import axios from "axios";
import { AuthConsumer } from "../../ContexApi/authContext";
//Redux
import { connect } from "react-redux";
//estilos materialize
import { Icon } from "react-materialize";
import { constServicios } from "../../constServicios";
import TipoDocumento from "./TipoDocumento";
//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Roles from "./Roles";
import Clientes from "./Clientes";
//import Prestadores from "./Prestadores";
import Loader from "../Utils/Loader";
import Ciudades from "./Ciudades";
import Sedes from "./Sedes";
import ClientesAsignados from "./ClientesAsignados";
import "../AdminUsuarios/css/AdminUsuarios.css";
import DocumentoTipoPrestador from "componentes/Utils/DocumentoTipoDocumentoPrestador/DocumentoTipoDocumentoPrestador";

const MySwal = withReactContent(Swal);
const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
const customControlStyles = base => ({
  height: 150
});
const ClienteArus = "5";
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class FormularioUsuario extends Component {
  constructor(props) {
    super(props);
    if (this.props.idClienteAsignado) {
      var value = [];
      this.props.idClienteAsignado.map((data, key) => {
        value.push(JSON.stringify(data.idCliente));
      });
    }


    this.state = {
      primerNombre: this.props.primerNombre,
      primerApellido: this.props.primerApellido,
      correo: this.props.correo,
      password: this.props.password,
      numDocumento: this.props.numDocumento,
      username: this.props.username,
      idusuario: this.props.idusuario,
      idCliente: this.props.idClienteEdit
        ? this.props.idClienteEdit
        : this.props.idCliente,
      idClienteAsignado: this.props.idClienteAsignado ? value : null,
      token: "",
      idRol: this.props.idRole,
      tipoDoc: this.props.idTipoDocumento,
      idSede: this.props.idSede,
      idCiudad: this.props.idCiudad,
      encontrado: true,
      errores: false,
      referencia: this.props.referencia,
      estadoa:
        this.props.idEstado == null
          ? true
          : this.props.idEstado === 1
          ? true
          : false,
      estadoi:
        this.props.idEstado == null
          ? false
          : this.props.idEstado === 1
          ? false
          : true,
      lista: [],
      loader: false,
      idWso2: "",
      listaPrestador: this.props.listaPrestador,
      showCiudad: false,
      password2: "",
      filtro: "",
      showFiltro: "0",
      consultaUsuario: 0,
      prestador: "",
      tipoDocumentoPrest: 0,
      nombrePrestador: "",
      showPrestador: true
    };
  }

  componentWillMount() {
    if (!this.state.estado) {
      if (this.props.idEstado === 1 || !this.state.referencia) {
        this.setState({
          estadoi: false,
          estadoa: true,
          estado: 1
        });
      } else {
        this.setState({
          estadoi: true,
          estadoa: false,
          estado: 2
        });
      }
    }
    //Muestra u oculta ciudad sede
    this.onChangeCli(this.state.idCliente);
  }

  componentDidMount() {
    //se ejecuta solo una vez

    const { accessToken } = this.context;
    this.setState({ token: accessToken });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  onChangeLinkName(valorHijo) {
    this.setState({
      tipoDoc: valorHijo
    });
  }
  onChangeTippoPrest(e) {
    if (JSON.stringify(e).length > 0) {
      this.setState({ tipoDocumentoPrest: e });
      if (this.state.prestador) {
        // this.consultaPrestador();
      } else {
      }
    }
  }

  onChangePrest(e) {
    if (e.length > 0) {
      this.setState({ prestador: e });

      if (this.state.tipoDocumentoPrest) {
        sleep(500).then(() => {
          this.consultaPrestador();
        });
      }
    } else {
      this.setState({ nombrePrestador: null, prestador: null });
    }
  }
  onChangeRol(valorHijo) {
    this.setState({
      idRol: valorHijo
    });
  }
  onChangeSede(valorHijo) {
    this.setState({
      idSede: valorHijo
    });
  }
  onChangeCiudad(valorHijo) {
    if (this.state.consultaUsuario === 1) {
      this.setState({
        idSede: ""
      });
    }
    this.setState({
      idCiudad: valorHijo,
      filtro: valorHijo,
      showFiltro: "0",
      consultaUsuario: 1
    });

    this.setState({
      showFiltro: "1"
    });

    //con valor hijo se debe filtrar idSede
  }
  onChangeCli(valorHijo) {
    this.setState({
      idCliente: valorHijo
    });

    if (String(valorHijo) !== String(ClienteArus)) {
      this.setState({
        idClienteAsignado: null,
        showCiudad: false
      });
    } else {
      this.setState({
        showCiudad: true
      });
    }
  }
  onChangeCliAsig(valorHijo) {
    this.setState({
      idClienteAsignado: valorHijo
    });
  }
  handleOptionActive = changeEvent => {
    this.setState({ estadoa: true, estadoi: false, estado: "1" });
  };

  handleOptionInactive = changeEvent => {
    this.setState({ estadoa: false, estadoi: true, estado: "2" });
  };

  consultaPrestador = async () => {
    this.setState({ nombrePrestador: null });
    if (!this.state.idCliente) {
      Toast.fire({
        title: "Por favor ingrese Cliente para consulta de Prestador"
      });
      return;
    }

    await axios
      .get(
        `${constServicios.url}${constServicios.urlPrestador}/${this.state.prestador}/${this.state.tipoDocumentoPrest}/${this.state.idCliente}`,
        {
          headers: {
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        if (res.data) {
          this.setState({
            nombrePrestador: res.data.nombrePrestador,
            prestador: res.data.idPrestador
          });
        } else {
          this.setState({
            showPrestador: false
          });
          Toast.fire({
            type: "error",
            title:
              "No se encuentra Prestador asociado al Tipo y Nro. Documento "
          });
          this.setState({
            nombrePrestador: null,
            prestador: null,
            listaPrestador: null,
            showPrestador: true
          });
          document.getElementById("create-course-form2").reset();
        }
      })
      .catch(err =>
        console.log("Hubo un error en la consulta del Prestador" )
      );
  };

  existeUsuario = async () => {
    //1.Consulta en WSO2
    var existe = false;
    var existeCMI = 0;
    var mensaje = "";
    var encontrado;

    await axios
      .get(
        `${constServicios.url}${constServicios.urlGetWSO2}${this.state.username}`,
        {
          headers: {
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        if (!res.data.userName) {
          existe = false;
          this.setState({
            encontrado: false
          });
        } else {
          existe = true;
          mensaje = " WSO2";
          this.setState({
            encontrado: true,
            idWso2: res.data.id
          });
        }
      })
      .catch(err => console.log("Hubo un error en la consulta de WSO2" ));
    //2.Consulta en CMI
    if ((this, this.state.referencia === true && existe === false)) {
      Toast.fire({
        title: "El Usuario No existe en Wso2 " + mensaje
      });
    }
    await axios
      .get(
        `${constServicios.url}${constServicios.urlInfoUserInd}${this.state.username}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        if (!res.data.username) {
          if (existe === false) {
            existe = false;
          } else {
            existeCMI = false;
          }

          this.setState({
            encontrado: false
          });
        } else {
          //cuando existe en cmi pero no en wso2 no se esta tomando en cuenta
          existe = true;
          this.setState({
            encontrado: true
          });
          mensaje += " CMI";
        }
        //Creación necesita que NO exista
        if (existe === true && this.props.referencia === false) {
          if (existeCMI === false) {
            mensaje =
              mensaje + "Por favor edite usuario por la pestaña Edición";
          }
          Toast.fire({
            type: "error",
            title: "El Usuario Ya Existe en el Sistema " + mensaje
          });
        }
        //Actualizacion necesita que exista
        if (encontrado === false && this.props.referencia === true) {
          Toast.fire({
            type: "error",
            title: "El Usuario No Existe en el Sistema " + mensaje
          });
        }
      })
      .catch(err =>
        console.log("Hubo un error en la consulta de Usuario CMI")
      );

    if (this.props.referencia === true && existeCMI === false) {
      //procedo a crearlo automaticamente en CMI
      existeCMI = await this.crearCMI();
    }

    return existe;
  };

  validarPassword = password => {
    if (!password || password === "") {
      Toast.fire({
        type: "error",
        title: `Ingrese Contraseña`
      });
      return false;
    } else {
      if (password.length < 8) {
        Toast.fire({
          type: "error",
          title: `Longitud de contraseña Menor a 8 caracteres`
        });
        return false;
      }
      if (password.includes(" ")) {
        Toast.fire({
          type: "error",
          title: `Contraseña no debe contener espacios en blanco`
        });
        return false;
      }
      if (password.includes(" ")) {
        Toast.fire({
          type: "error",
          title: `Contraseña no debe contener espacios en blanco`
        });
        return false;
      }
      var re = /[0-9]/;
      if (!re.test(password)) {
        Toast.fire({
          type: "error",
          title: `Contraseña debe contener al menos un número (0-9)!`
        });
        return false;
      }
      re = /\"/;
      if (re.test(password)) {
        Toast.fire({
          type: "error",
          title: `Contraseña no debe contener comillas dobles`
        });
        return false;
      }
      re = /^\w+$/;
      if (re.test(password)) {
        Toast.fire({
          type: "error",
          title: `Contraseña debe contener al menos un caracter especial`
        });
        return false;
      }
      re = /[a-z]/;
      if (!re.test(password)) {
        Toast.fire({
          type: "error",
          title: `Contraseña debe contener al menos una minúscula (a-z)!`
        });
        return false;
      }
      re = /[A-Z]/;
      if (!re.test(password)) {
        Toast.fire({
          type: "error",
          title: `Contraseña debe contener al menos una mayúscula (A-Z)!`
        });
        return false;
      }

      if (this.state.password !== this.state.password2) {
        Toast.fire({
          type: "error",
          title: `Passwords deben coincidir!`
        });
        return false;
      }
    }
  };

  validarForm = async () => {
    var retorno;
    const {
      primerNombre,
      primerApellido,
      correo,
      password,
      numDocumento,
      username,
      estado,
      idSede
    } = this.state;

    if (this.state.referencia === false) {
      retorno = this.validarPassword(password);
    }
    if (retorno === false) {
      return false;
    }

    if (!username || username === "") {
      Toast.fire({
        type: "error",
        title: `Ingrese Nombre Usuario`
      });
      return false;
    }

    if (!primerNombre || primerNombre === "") {
      Toast.fire({
        type: "error",
        title: `Ingrese Nombre Usuario`
      });
      return false;
    }

    if (!primerApellido || primerApellido === "") {
      Toast.fire({
        type: "error",
        title: `Ingrese Apellido Usuario`
      });
      return false;
    }

    if (!estado || estado === "") {
      this.setState({ idEstado: 1 });
    }
    if (!correo || correo === "") {
      Toast.fire({
        type: "error",
        title: `Ingrese Correo`
      });
      return false;
    }
    if (!idSede || idSede === "") {
      Toast.fire({
        type: "error",
        title: `Elija Sede u Oficina`
      });
      return false;
    }
    if (!numDocumento || numDocumento === "") {
      Toast.fire({
        type: "error",
        title: `Ingrese Número de Documento`
      });
      return false;
    } else {
      if (numDocumento.length > 15) {
        Toast.fire({
          type: "error",
          title: `Longitud de Documento Sobrepasa Máximo Permitido`
        });
        return false;
      }
      var re = /^\w+$/;
      if (!re.test(numDocumento)) {
        Toast.fire({
          type: "error",
          title: `Numero de Documento no debe contener caracteres especiales`
        });
        return false;
      }
      re = /[a-z]/;
      var re2 = /[A-Z]/;
      if (
        this.state.tipoDoc === "1" &&
        (re.test(numDocumento) === true || re2.test(numDocumento) === true)
      ) {
        Toast.fire({
          type: "error",
          title: `Solo se permiten Numeros en Cedula de Identidad`
        });
        return false;
      }
    }
  };

  crearCMI = async e => {
    var creado = 1;

    await axios
      .post(
        `${constServicios.url}${constServicios.urlUsuarios}`,
        {
          primerNombre: this.state.primerNombre,
          primerApellido: this.state.primerApellido,
          correo: this.state.correo,
          password: null,
          numeroDocumento: this.state.numDocumento,
          username: this.state.username,
          idCliente: this.state.idCliente,
          idRole: this.state.idRol,
          idEstado: !this.state.estado ? 1 : this.state.estado === 1 ? 1 : 2,
          idTipoDocumento: this.state.tipoDoc,
          idClienteAsignado: this.state.idClienteAsignado,
          idPrestador: this.state.nombrePrestador ? this.state.prestador : null,
          idSede: this.state.idSede,
          idCiudad: this.state.idCiudad
        },
        {
          headers: {
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        var campoErrado = this.encontrarError(res.data);
        if (campoErrado.length > 1) {
          this.setState({ errores: true });
          this.setState({ persons: campoErrado + " . Mensaje: " + res.data });
          Toast.fire({
            type: "error",
            title: campoErrado
          });
        } else {
          this.setState({ persons: "Usuario creado Exitosamente en CMI!" });
          creado = 2;
        }
      })
      .catch(err => {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.setState({ errores: false });
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({ persons: "Usuario creado Exitosamente en CMI!" });
          creado = 2;
        }
      });
    return creado;
  };
  ///
  cancelCourse = () => {
    this.setState({
      primerApellido: "",
      primerNombre: "",
      correo: "",
      password: "",
      numDocumento: "",
      username: "",
      idusuario: "",
      //idCliente: "",
      idClienteAsignado: "",
      errores: false,
      password2: ""
    });
    this.setState({
      showCiudad: false
    });
    this.onChangeCliAsig("");
    this.setState({
      showCiudad: true
    });

    document.getElementById("create-course-form").reset();
  };
  crear = async e => {
    /* creado = this.crearCMI();
    return;*/
    var creado = 0;
    e.preventDefault();
    this.setState({ persons: "", errores: false });
    const validado = await this.validarForm();
    if (validado === false) {
      return null;
    }

    const retorno = await this.existeUsuario();

    if (retorno === true && this.props.referencia === false) {
      return null;
    }
    if (retorno === false && this.props.referencia === true) {
      return false;
    }
    if (this.state.idCliente.length === 0) {
      Toast.fire({
        type: "error",
        title: " Por favor verifique Cliente Seleccionado vs Clientes Asignados"
      });

      return;
    }

    //Creacion referencia false
    if (this.props.referencia === false) {
      // return;
      //Crear en WSO2
      await axios
        .post(
          `${constServicios.url}${constServicios.urlGetWSO2}`,
          {
            primerNombre: this.state.primerNombre,
            primerApellido: this.state.primerApellido,
            correo: this.state.correo,
            username: this.state.username,
            password: this.state.password
          },
          {
            headers: {
              Authorization: this.state.token
            }
          }
        )
        .then(res => {
          creado = 1;
          this.setState({
            persons:
              this.state.persons + " Usuario creado Exitosamente en WSO2!"
          });
        })
        .catch(err => {
          //this.setState({ persons: res.data });
          if (err.response) {
            this.setState({ errores: true });
            this.setState({
              persons:
                " Usuario no pudo ser creado en WSO2 : " +
                err.response.data.error
            });
          } else if (err.request) {
            // The request was made but no response was received
          } else {
            // Something happened in setting up the request that triggered an Error
            this.setState({
              persons:
                this.state.persons +
                " Usuario no pudo ser creado en WSO2, por favor intente nuevamente"
            });
          }
        });

      if (creado === 0) return;
      creado = this.crearCMI();

      if (creado === 1) {
        Toast.fire({
          type: "error",
          title:
            "El usuario fue creado con inconsistencias, favor consultar en Editar Usuario para completar los datos solicitados..."
        });
      } else {
        this.cancelCourse();
      }
    } else {
      this.actualizar();
    }
  };

  actualizar = async e => {
    var actualizacionOk = 0;

    await axios
      .put(
        `${constServicios.url}${constServicios.urlUsuarios}${this.state.idusuario}`,
        {
          primerNombre: this.state.primerNombre,
          primerApellido: this.state.primerApellido,
          correo: this.state.correo,
          password: null,
          numDocumento: this.state.numDocumento,
          username: this.state.username,
          idCliente: this.state.idCliente,
          idRole: this.state.idRol,
          idEstado: this.state.estado,
          idTipoDocumento: this.state.tipoDoc,
          idPrestador: this.state.nombrePrestador ? this.state.prestador : null,
          idClienteAsignado: this.state.idClienteAsignado,
          idSede: this.state.idSede,
          idCiudad: this.state.idCiudad
        },
        {
          headers: {
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        /* if (res.status == 200) */
        this.setState({ persons: res.data });
        var campoErrado = this.encontrarError(res.data);
        this.setState({ errores: false });
        if (res.data.includes("Errores:")) {
          this.setState({ errores: true });
          Toast.fire({
            type: "error",
            title: campoErrado
          });
        } else {
          this.setState({ persons: "Usuario Actualizado Exitosamente CMI!" });
        }
      })
      .catch(err => {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });

    await axios
      .put(
        `${constServicios.url}${constServicios.urlGetWSO2}${this.state.idWso2}`,
        {
          primerNombre: this.state.primerNombre,
          primerApellido: this.state.primerApellido,
          correo: this.state.correo,
          password: this.state.password,
          username: this.state.username
        },
        {
          headers: {
            Authorization: this.state.token
          }
        }
      )
      .then(res => {
        /* if (res.status == 200) */

        actualizacionOk = 1;
        this.setState({ persons: res.data });
        this.setState({ errores: false });
        this.setState({ persons: "Usuario Actualizado Exitosamente!" });
      })
      .catch(err => {
        if (err.response) {
        } else if (err.request) {
        } else {
          this.setState({ persons: "Error Wso2!" });
        }
      });
    if (actualizacionOk > 0) {
      this.props.onChangeUser();
      Swal.fire({
        title: "Usuario Actualizado Exitosamente!",
        type: "success"
      });
    }
  };

  encontrarError = campo => {
    var txto = "Favor Corregir ";
    if (campo.includes("isEmail")) return txto + "formato correo electrónico";
    else if (campo.includes("password")) return txto + "formato password";
    else if (campo.includes("Prestador")) return txto + " Prestador Asociado";
    else if (campo.includes("correo cannot be null")) return txto + " Correo";
    else if (campo.includes("numeroDocumento"))
      return txto + " Número de Identificación";
    else if (campo.includes("EstadoIdEstado"))
      return txto + " Estado Activo/Inactivo vacío";
    else return "";
  };
  render() {
    return (
      <Fragment>
        <div className="row">
          <form
            className="col s12"
            onSubmit={this.crear}
            id="create-course-form"
          >
            <div className="row">
              <div className="input-field col s6">
                {!this.props.referencia ? (
                  <label htmlFor="username">Usuario</label>
                ) : (
                  <blockquote className="blockquote">Usuario</blockquote>
                )}
                <input
                  id="username"
                  name="username"
                  type="text"
                  className="validate"
                  value={this.state.username}
                  onChange={this.onChange.bind(this)}
                  disabled={this.state.referencia === true ? true : false}
                />
              </div>
              {!this.props.referencia ? (
                <span>
                  <div className="input-field col s3">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="validate"
                      value={this.state.password}
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor="password">Contraseña</label>
                  </div>
                  <div className="input-field col s3">
                    <input
                      id="password2"
                      name="password2"
                      type="password"
                      className="validate"
                      value={this.state.password2}
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor="password">Confirme Contraseña</label>
                  </div>
                </span>
              ) : null}
            </div>
            <div className="row">
              <div className="input-field col s6">
                <i className="material-icons prefix">account_circle</i>
                <input
                  id="primerNombre"
                  name="primerNombre"
                  type="text"
                  value={this.state.primerNombre}
                  onChange={this.onChange.bind(this)}
                />
                {!this.props.referencia ? (
                  <label htmlFor="primerNombre">Nombres</label>
                ) : (
                  <span className="helper-text">Nombres</span>
                )}
              </div>
              <div className="input-field col s6">
                <input
                  id="primerApellido"
                  name="primerApellido"
                  type="text"
                  value={this.state.primerApellido}
                  onChange={this.onChange.bind(this)}
                />
                {!this.props.referencia ? (
                  <label htmlFor="primerApellido">Apellidos</label>
                ) : (
                  <span className="helper-text">Apellidos</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="input-field col s2">
                <TipoDocumento
                  changeTipoDoc={this.onChangeLinkName.bind(this)}
                  referencia={this.props.referencia}
                  tipoDoc={this.props.idTipoDocumento}
                />
              </div>
              <div className="input-field col s4">
                <input
                  id="numDocumento"
                  name="numDocumento"
                  type="text"
                  className="validate"
                  data-length="15"
                  value={this.state.numDocumento}
                  onChange={this.onChange.bind(this)}
                  onKeyPress={event => {
                    var key = window.event ? event.which : event.keyCode;
                    if (this.state.tipoDoc === 1 && (key < 48 || key > 57)) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                />
                {!this.props.referencia ? (
                  <label htmlFor="numDocumento">Nro. Identificación</label>
                ) : (
                  <span className="helper-text">Nro. Identificación</span>
                )}
                <span
                  className="helper-text"
                  data-error="Verifique Longitud Ma x. 15"
                  data-success="Ok"
                ></span>
              </div>
              <div className="input-field col s6">
                <i className="material-icons prefix">email</i>
                <input
                  id="correo"
                  name="correo"
                  type="email"
                  className="validate"
                  value={this.state.correo}
                  onChange={this.onChange.bind(this)}
                />
                {!this.props.referencia ? (
                  <label htmlFor="correo">Correo Electronico</label>
                ) : null}
                <span
                  className="helper-text"
                  data-error="Formato de correo errado"
                  data-success="Ok"
                >
                  Formato Correo
                </span>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6">
                <blockquote className="blockquote">Elija Rol:</blockquote>
                <Roles
                  changeTipoDoc={this.onChangeRol.bind(this)}
                  referencia={this.props.referencia}
                  idRol={this.props.idRole}
                />
              </div>
              <div className="input-field col s6">
                <blockquote className="blockquote">Elija Cliente:</blockquote>
                <Clientes
                  changeTipoDoc={this.onChangeCli.bind(this)}
                  referencia={this.props.referencia}
                  idCliente={this.props.idClienteEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6">
                <blockquote className="blockquote">Elija Ciudad:</blockquote>
                <Ciudades
                  changeTipoDoc={this.onChangeCiudad.bind(this)}
                  referencia={this.props.referencia}
                  idCiudad={this.props.idCiudad}
                />
              </div>
              <div className="input-field col s6">
                <blockquote className="blockquote">Elija Sede:</blockquote>
                {this.state.showFiltro === "1" ? (
                  <Sedes
                    changeTipoDoc={this.onChangeSede.bind(this)}
                    idSede={this.state.idSede}
                    filtro={this.state.idCiudad}
                    referencia={this.props.referencia}
                    consultaUsuario={this.state.consultaUsuario}
                  />
                ) : null}
              </div>
            </div>
            {this.state.showCiudad === true ||
            this.state.idCliente === ClienteArus ? (
              <div className="row" styles={customControlStyles}>
                <div className="input-field col s6">
                  <blockquote className="blockquote">
                    Elija Cliente Asignado:
                  </blockquote>
                  <ClientesAsignados
                    changeTipoDoc={this.onChangeCliAsig.bind(this)}
                    referencia={this.props.referencia}
                    idClienteAsignado={this.props.idCliente}
                    clientesSeleccionados={this.props.idClienteAsignado}
                  />
                </div>
              </div>
            ) : null}
            <form id="create-course-form2" className="marginBlock">
              <div className="row">
                <blockquote className="blockquote">
                  <b>Datos Prestador:</b>
                </blockquote>
                {this.state.showPrestador ? (
                  <div className="col s12 center">
                    <div className="col s8 center">
                      <DocumentoTipoPrestador
                        tipoDocumento={this.onChangeTippoPrest.bind(this)}
                        documento={this.onChangePrest.bind(this)}
                        idPrest={this.state.listaPrestador}
                      />
                    </div>

                    {this.state.nombrePrestador ? (
                      <div className="col s4 left">
                        <label className="paddin">
                          <i className="material-icons prefix">
                            account_circle
                          </i>
                          {this.state.nombrePrestador}
                        </label>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </form>
            <div className="row">
              <div className="col s12 center">
                <label>
                  <input
                    name="ractivo"
                    id="ractivo"
                    type="radio"
                    className="with-gap"
                    checked={this.state.estadoa}
                    value="1"
                    ref="radioBtn1"
                    onChange={this.handleOptionActive}
                  />
                  <span>Activo</span>
                </label>
                <label>
                  <input
                    name="rinactivo"
                    id="rinactivo"
                    value="2"
                    ref="radioBtn2  "
                    onChange={this.handleOptionInactive}
                    checked={this.state.estadoi}
                    className="with-gap"
                    type="radio"
                  />
                  <span>Inactivo</span>
                </label>
              </div>
              <div></div>
            </div>
            {this.state.loader === true ? (
              <div>
                <Loader />
              </div>
            ) : null}
            {this.state.persons ? (
              this.state.errores === true ? (
                <div>
                  <div className="card-panel red lighten-2">
                    {JSON.stringify(this.state.persons)}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="card-panel green lighten-2">
                    {JSON.stringify(this.state.persons)}
                  </div>
                </div>
              )
            ) : null}
            <div className="row">
              <div className="input-field col s12 center">
                {!this.props.referencia ? (
                  <button
                    className="btn btn-color-arus"
                    type="submit"
                    name="action"
                  >
                    Crear
                  </button>
                ) : (
                  <button
                    className="btn btn-color-arus"
                    type="submit"
                    name="action"
                  >
                    Actualizar
                  </button>
                )}
              </div>
            </div>
            <div>
              <div className="input-field col s4"></div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, { FormularioUsuario })(FormularioUsuario);
FormularioUsuario.contextType = AuthConsumer;
