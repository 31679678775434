import React, { Fragment } from "react";
import { Tab, Tabs } from "react-materialize";
import { AuthConsumer } from "../../ContexApi/authContext";
import Can from "../../ContexApi/Can";
import { Redirect } from "react-router-dom";
import Form from "./form";

const ActPrestador = () => {
  return (
    <AuthConsumer>
      {({ authenticated, user }) =>
        authenticated ? (
          <Can
            rules={user.permisos}
            perform="actualizarInfoPrestador:ver"
            yes={() => (
              <Fragment>
                <Tabs className="tab-demo z-depth-1">
                  <Tab title="Actualizar información prestador">
                    <div className="card-panel">
                     <Form /> 
                    </div>
                  </Tab>
                </Tabs>
              </Fragment>
            )}
            no={() => <Redirect to="/inicio" />}
          />
        ) : (
          <Redirect to="/inicio" />
        )
      }
    </AuthConsumer>
  );
};
export default ActPrestador;
