import React, { Fragment, useState, useEffect, useContext } from "react";
import Row from "react-materialize/lib/Row";
import Col from "react-materialize/lib/Col";
import { AuthConsumer } from "../../../ContexApi/authContext";
import Axios from "axios";
import Swal from "sweetalert2";
import { ProgressBar } from "react-materialize";
import TextInput from "react-materialize/lib/TextInput";
import Select from "react-materialize/lib/Select";
import DatePicker, { registerLocale } from "react-datepicker";
import Button from "react-materialize/lib/Button";
import { constServicios } from "../../../constServicios";
import moment from "moment";
import es from "date-fns/locale/es";
import GridRips from "./grid";
import { Link } from "react-router-dom";

const FormRips = (props) => {
  const [clients, setClients] = useState([]);
  const conxtFront = useContext(AuthConsumer);
  const [data, setData] = useState({
    cliente: props.user.info.Cliente.numeroDocumento,
    idCliente: props.user.info.Cliente.idCliente,
    role: props.user.role,
    factura: "",
    fechaInicio: "",
    fechaFin: "",
    remision: "",
    prestador: "",
  });
  const [mostrar, setMostrar] = useState(false);
  const [datosGrid, setDatosGrid] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const chageData = (e) => {
    const { value, name } = e.target;
    let newData = { ...data };
    newData[name] = String(value);
    setData(newData);
  };
  useEffect(() => {
    getClients();
    registerLocale("es", es);
  }, []);

  const getClients = async () => {
    try {
      const response = await Axios({
        method: "GET",
        url: `${constServicios.url}${constServicios.urlCliente}`,
        headers: {
          Authorization: conxtFront.accessToken,
        },
      });
      if (response.data && response.data.length !== 0) {
        setClients(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const generateReport = async (role) => {
    setIsDisabled(true);
    try {
      for (const cliente of clients) {
        console.log(cliente.idCliente)
        console.log(data.idCliente)
        if (
          cliente.idCliente == data.idCliente
        ){
          console.log("entro")
          data.cliente = cliente.numeroDocumento
        }
      }
      console.log(data.idCliente, "idCliente")
      console.log(data.cliente, "cliente")
      const validado = await validarData();
      if (!validado) {
        setIsDisabled(false);
        return;
      }

      //se valida si es un prestador
      if (role === "prestadoresSura" || role === "prestadoresSanitas" || role === "prestadorSoat" || role === "prestadorPoliza" || role === "prestadorCM"){

        data.prestador = props.user.info.Prestadore.idPrestador;
      }

      
      const res = await Axios({
        method: "POST",
        url: `${constServicios.url}${constServicios.urlReporteLogRips}`,
        data,
        headers: {
          Authorization: conxtFront.accessToken,
        },
      });
      if (res.data.length > 0) {
        setDatosGrid(res.data);
        setMostrar(true);
        setIsDisabled(false);
      } else {
        swal("No se encontraron resultados", "error", "", "#002c55", "Cerrar");
        setIsDisabled(false);
        setDatosGrid([]);

      }
    } catch (error) {
      setIsDisabled(false);
      setDatosGrid([]);
      console.log("=======error=======> ", error);
      swal("Fallo al consultar RIPS", "error", "", "#002c55", "Cerrar");
    }
  };
  const validarData = async () => {

    if (data.cliente === "") {
      swal(
        "Selecciones un cliente es obligatorio",
        "error",
        "",
        "#002c55",
        "Cerrar"
      );
      return false;
    }

    if (!data.fechaFin || !data.fechaFin) {
      swal(
        "El rango de fechas es obligatorio",
        "error",
        "",
        "#002c55",
        "Cerrar"
      );
      return false;
    }
    const i = moment(data.fechaInicio);
    const f = moment(data.fechaFin);
    const diferencia = f.diff(i, "days");
    if (diferencia < 0) {
      swal(
        "Fecha inicial no puede ser mayor a la fecha final",
        "error",
        "",
        "#002c55",
        "Cerrar"
      );
      return false;
    }
    if (diferencia + 1 > 30) {
      swal(
        "No se puede consultar más de 30 días",
        "error",
        "",
        "#002c55",
        "Cerrar"
      );
      return false;
    }
    return true;
  };
  const swal = (title, type, html, confirmButtonColor, confirmButtonText) => {
    Swal.fire({
      title: title,
      type: type,
      html: html,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirmButtonColor,
    });
  };
  const clientes = () => {
    if (!props.user.info.Cliente.numeroDocumento) {
      return clients.map((cliente) => {
        if (cliente.numeroDocumento === "") {
          return (
            <option key={cliente.idCliente} value={cliente.idCliente} disabled>
              Selecciones Cliente
            </option>
          );
        }
        else {
          return (
            <option key={cliente.idCliente} value={cliente.idCliente}>
              {cliente.nombreCliente}
            </option>
          );
        }

      });
    } else {
      for (const cliente of clients) {
        if (
          cliente.idCliente === props.user.info.Cliente.idCliente && cliente.idCliente !== ""
        ) {
          return (
            <option
              key={cliente.idCliente}
              value={cliente.idCliente}
            >
              {cliente.nombreCliente}
            </option>
          );
        }
      }
    }
  };
  const crearForm = () => {

    const {
      user: { role },
    } = props;
    return (
      <Fragment>
        <Row>
          {role === "administrador" ||
            role === "clienteSanitas" ||
            role === "clienteSura" ||
            role === "clienteSoat" ||
            role === "clientePoliza" ||
            role === "arusOperativos" ||
            role === "arusLider" ? (
              <Col m={12}>
                <Select
                  label={"Selecione un cliente"}
                  onChange={(e) => {
                    chageData(e);
                  }}
                  m={12}
                  value={data.idCliente}
                  name={"idCliente"}
                >
                  {clientes()}
                </Select>
              </Col>
            ) : null}
        </Row>
        <Row>
          {role === "clienteSanitas" ||
            role === "clienteSura" ||
            role === "administrador" ||
            role === "arusOperativos" ||
            role === "arusLider" ||
            role === "prestadoresSanitas" ||
            role === "prestadorSoat" ||
            role === "clienteSoat" ||
            role === "prestadorPoliza" ||
            role === "prestadorCM" ||
            role === "clientePoliza" ||
            role === "prestadoresSura" ? (
              <Col m={4}>
                <TextInput
                  onChange={(e) => {
                    chageData(e);
                  }}
                  label={"Número  de factura"}
                  m={12}
                  value={data.factura}
                  name={"factura"}
                />
              </Col>
            ) : null}
          {role === "administrador" ||
            role === "prestadoresSanitas" ||
            role === "prestadorSoat" ||
            role === "prestadorPoliza" ||
            role === "prestadorCM" ||
            role === "prestadoresSura" ? (
              <Col m={4}>
                <TextInput
                  onChange={(e) => {
                    chageData(e);
                  }}
                  label={"Número  de remision"}
                  m={12}
                  value={data.remision}
                  name={"remision"}
                />
              </Col>
            ) : null}
          {role === "clienteSanitas" ||
            role === "clienteSura" ||
            role === "clienteSoat" ||
            role === "clientePoliza" ||
            role === "administrador" ||
            role === "arusOperativos" ||
            role === "arusLider" ? (
              <Col m={4}>
                <TextInput
                  onChange={(e) => {
                    chageData(e);
                  }}
                  label={"Nit del prestador"}
                  m={12}
                  value={data.prestador}
                  name={"prestador"}
                />
              </Col>
            ) : null}
          <Col m={4}>
            <label>Fecha inicio (Obligatorio)</label>
            <div>
              <DatePicker
                selected={data.fechaInicio}
                onChange={(date) => {
                  setData({ ...data, fechaInicio: date });
                }}
                dateFormat="dd-MM-yyyy"
                // onKeyDown={e => e.preventDefault()}
                locale="es"
                placeholderText="Seleccione una fecha"
                minDate={treeMonths()}
              />
            </div>
          </Col>
          <Col m={4}>
            <label>Fecha fin (Obligatorio)</label>
            <div>
              <DatePicker
                selected={data.fechaFin}
                onChange={(date) => {
                  setData({ ...data, fechaFin: date });
                }}
                dateFormat="dd-MM-yyyy"
                // onKeyDown={e => e.preventDefault()}
                locale="es"
                placeholderText="Seleccione una fecha"
                minDate={treeMonths()}
              />
            </div>
          </Col>
          <Button
            disabled={isDisabled ? true : false}
            onClick={() => {
              generateReport(role);
            }}
          >
            Consultar
          </Button>
          <Link to={"/reportes"}>
            <Button style={{ marginLeft: "1vw" }}>Salir</Button>
          </Link>
        </Row>
        <Row>{mostrarGrid()}</Row>
      </Fragment>
    );
  };
  const treeMonths = () => {
    var d = new Date();
    d.setMonth(d.getMonth() - 3);
    return d;
  };
  const mostrarGrid = () => {
    if (mostrar) {
      return <GridRips datosGrid={datosGrid} />;
    }
  };
  return crearForm();
};

export default FormRips;
