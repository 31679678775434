import React, { Fragment, useState, useEffect, useContext } from "react";
import Row from "react-materialize/lib/Row";
import Col from "react-materialize/lib/Col";
import { AuthConsumer } from "../../../ContexApi/authContext";
import Axios from "axios";
import Swal from "sweetalert2";
import { ProgressBar } from "react-materialize";
import TextInput from "react-materialize/lib/TextInput";
import Select from "react-materialize/lib/Select";
import DatePicker, { registerLocale } from "react-datepicker";
import Button from "react-materialize/lib/Button";
import { constServicios } from "../../../constServicios";
import moment from "moment";
import es from "date-fns/locale/es";
import GridLogSoporte from "./grid";
import { Link } from "react-router-dom";
const tiposNotificacion = [
  {
    value: 'facturaIlegible',
    name: 'Facturas ilegibles, sin factura o para otra línea de negocio'
  },
  {
    value: 'soporteHuerfano',
    name: 'Soportes Huérfanos'
  },
  {
    value: 'facturaHuerfana',
    name: 'Facturas Huérfanas'
  },
  {
    value: 'imagenCorrupta',
    name: 'Imágenes Corruptas'
  }
]
const FormNotificaciones = (user) => {
  const [data, setData] = useState({
    tipoNotificacion: '',
    fechaIni: '',
    fechaFin: '',
    nit:''
  });
  const [datosGrid, setDatosGrid] = useState([]);
  const conxtFront = useContext(AuthConsumer);
  const [mostrar, setMostrar] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const chageData = (e) => {
    const { value, name } = e.target;
    let newData = { ...data };
    newData[name] = String(value);
    setData(newData);
  };
  const generarReporte = async () => {
    try {
      if (!data.fechaIni || !data.fechaFin) {
        return swal(
          "Advertencia",
          "info",
          "Los campos fecha inicio y fecha fin son obligatorios",
          "#002c55",
          "Cerrar"
        );
      }
      const i = moment(data.fechaIni);
      const f = moment(data.fechaFin);
      const diferencia = f.diff(i, "days");
      if (diferencia < 0) {
        return swal(
          "Advertencia",
          "info",
          "Fecha inicial no puede ser mayor a la fecha final",
          "#002c55",
          "Cerrar"
        );
      }
      if (diferencia + 1 > 31) {
        return swal(
          "Advertencia",
          "info",
          "No se puede consultar más de 31 días",
          "#002c55",
          "Cerrar"
        );
      }
      setDisabled(true);
      const newData={...data}
      if (user.user.role === "prestadoresSura") {
         const prestador = await Axios({
              method: "GET",
              url: `${constServicios.url}${constServicios.getPrestador}?idPrestador=${conxtFront.user.info.Prestadore.idPrestador}`,
              headers: {
                Authorization: conxtFront.accessToken,
              },
          });
          newData.nit = prestador.data.numeroDocumento;
      }
      const response = await Axios({
        method: "POST",
        url: `${constServicios.url}${constServicios.reporteTrazabilidadNotificaciones}`,
        data: newData,
        headers: {
          Authorization: conxtFront.accessToken,
        },
      });
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          setDatosGrid(response.data);
          setMostrar(true);
        } else {
          swal(
            "Advertencia",
            "info",
            "No se encontraron datos para el reporte",
            "#002c55",
            "Cerrar"
          );
        }
      } else {
        swal(
          "error",
          "error",
          "Ocurrio un error al consultar la trazabilidad de notificaciones",
          "#002c55",
          "Cerrar"
        );
      }
      setDisabled(false);
    } catch (e) {
      setDisabled(false);
      swal(
        "error",
        "error",
        "Ocurrio un error al consultar la trazabilidad de notificaciones",
        "#002c55",
        "Cerrar"
      );
    }
  }
  const swal = (title, type, html, confirmButtonColor, confirmButtonText) => {
    Swal.fire({
      title: title,
      type: type,
      html: html,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirmButtonColor,
    });
  };
  const mostrarGrid = () => {
    if (mostrar) {
      return (
        <div>
          <GridLogSoporte datosGrid={datosGrid} />
        </div>
      );
    }
  };
  return <Fragment>
    <Row>
      <Col m={4}>
        <Select
          label={"Seleccione un tipo de notificación"}
          onChange={(e) => {
            chageData(e);
          }}
          m={12}
          value={data.tipoNotificacion}
          name={"tipoNotificacion"}
        >
          <option
            value={''}
          >
            Seleccione una opción
            </option>
          {tiposNotificacion.map((i, key) => {
            return <option
              key={key}
              value={i.value}
            >
              {i.name}
            </option>
          })}
        </Select>
      </Col>
      <Col m={4}>
        <label>Fecha inicio (Obligatorio)</label>
        <div>
          <DatePicker
            selected={data.fechaIni}
            onChange={(date) => {
              setData({ ...data, fechaIni: date });
            }}
            dateFormat="dd-MM-yyyy"
            // onKeyDown={(e) => e.preventDefault()}
            locale="es"
            placeholderText="Seleccione una fecha"
          />
        </div>
      </Col>
      <Col m={4}>
        <label>Fecha Fin (Obligatorio)</label>
        <div>
          <DatePicker
            selected={data.fechaFin}
            onChange={(date) => {
              setData({ ...data, fechaFin: date });
            }}
            dateFormat="dd-MM-yyyy"
            // onKeyDown={(e) => e.preventDefault()}
            locale="es"
            placeholderText="Seleccione una fecha"
          />
        </div>
      </Col>
    </Row>
    <div className="center" style={{ marginLeft: "1vw" }}>
      <Button
        disabled={disabled}
        onClick={() => {
          generarReporte();
        }}
      >
        {!disabled ? 'Consultar' : 'Consultando...'}
      </Button>

      <Button
        onClick={() => {
          setData({
            tipoNotificacion: '0',
            fechaIni: '',
            fechaFin: ''
          })
          setMostrar(false)
        }}
        style={{ marginLeft: "1vw" }}
      >
        Limpiar
          </Button>
      <Link to={"/reportes"}>
        <Button style={{ marginLeft: "1vw" }}>Salir</Button>
      </Link>

      {/* <Button style={{ marginLeft: "1vw" }} onClick={() => {
            setData({...data, prestador: '', factura: '', fechaInicio: '', fechaFin: '' });
            setDisabled({...disabled, fechaInicio: true, fechaFin: true, btn: true })
            setMostrar(false)
          }}>
            Limpiar
          </Button> */}
    </div>
    <Row>
      {mostrarGrid()}
    </Row>
  </Fragment>
};
export default FormNotificaciones;
