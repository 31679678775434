import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./Header/Header";
import Soportes from "./Soportes/upload/Upload";
import SoporteElectronico from "./Soportes/upload/UploadElectronico";

import MenuPreRadicado from "./MenuPreRadicado/MenuPreRadicado";
import CrearLineamiento from "./LineamientosOperativos/CrearLineamiento";
import VerLineamiento from "./LineamientosOperativos/VerLineamiento";
import MostrarLineamientos from "./LineamientosOperativos/MostrarLineamientos";
import EditarLineamiento from "./LineamientosOperativos/EditarLineamiento";
import AsociarGuias from "./AsociarGuias/AsociarGuias";
import EditarGuias from "./AsociarGuias/EditarGuias";
import Inicio from "./Inicio/Inicio";
import TablasMaestras from "./TablasMaestras";
import EnvioCliente from "./EnvioCliente/EnvioCliente";
import MediosMagneticos from "./MediosMagneticos/MediosMagneticos";
import CallbackPage from "../pages/callback";
import Auth from "../ContexApi/Auth";
import PaginaNoEncontrada from "./error_404";
import RIPS from "./RIPS/RIPS";
import SuraAuthentication from "./SuraAuthentication";
//integracion seus4
import SuraAuthenticationSeus4 from "./SuraAuthenticationSeus4";
import Configuracion from "./Configuracion/Configuracion";
import ConsFact from "./ConsultarImagenes";
//reportes
import ReportesPreRadicados from "./Reportes/preradicados";
//integracion seus4SSO
import SuraAuthenticationSeus4SSO from "./SuraAuthenticationSeus4SSO";
import CallbackSanitas from "../pages/callbackSanitas";
import ReportesRips from "./Reportes/RIPS";
import Logout from "./herramientas/Logout";
import CallbackLogOut from "pages/callbackLogout";
import SanitasAuthentication from "./SanitasAuthentication";
import Notificaciones from "./Notificaciones/Notificaciones";
import SeleccionClientePrestador from "./SeleccionClientePrestador/SeleccionClientePrestador";
import reportes from "./Reportes";
import reportesLineamientosOperativos from "./Reportes/lineamientosOperativos";
import Pertinencia from "./Reportes/reportePertinencia";
import logCargaSoportes from "./Reportes/reporteLogSoporte";
import reporteNotificaciones from "./Reportes/reporteTrazabilidadNotificaciones";
import reporteNotificacionesEnviadas from "./Reportes/reporteNotificacionesEnviadas";
import LogRips from "./Reportes/reporteRips"
import reporteEstados from "./Reportes/reporteEstadosDiogenes";
import ActPrestador from "./ActualizarPrestador/index"


import { Preloader } from "react-materialize";

class Router extends Component {
  state = {
    loader: true
  };

  componentDidMount = () => {
    // alert("asdf")
    this.setState({
      // loader: false
    });
  };

  render() {
    return (
      <Auth>
        <BrowserRouter>
          <Header />
          {/* <Preloader active={this.state.loader} color="blue" flashing={false} size="big" /> */}
          <div className="principal_container container">
            <Switch>
              <Route exact path="/" component={() => <Inicio />} />
              <Route exact path="/notificaciones" component={Notificaciones} />
              {/* <Route exact path="" component={Inicio} />          */}
              <Route exact path="/authSanitas" component={CallbackSanitas} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/callbacklogout" component={CallbackLogOut} />
              <Route exact path="/callback" component={CallbackPage} />
              <Route exact path="/inicio" component={Inicio} />
              <Route exact path="/soportes" component={Soportes} />
	      <Route exact path="/soportesElectronico" component={SoporteElectronico} />
              <Route exact path="/tablasmaestras" component={TablasMaestras} />
              <Route exact path="/preradicado" component={MenuPreRadicado} />
              <Route
                exact
                path="/cambiocliente"
                component={SeleccionClientePrestador}
              />
              <Route
                exact
                path="/lineamientos/:id"
                component={VerLineamiento}
              />
              <Route
                exact
                path="/lineamientos"
                component={MostrarLineamientos}
              />
              <Route exact path="/enviocliente" component={EnvioCliente} />
              <Route
                exact
                path="/mediosmagneticos"
                component={MediosMagneticos}
              />
              <Route exact path="/crearGuia/:id" component={AsociarGuias} />
              <Route exact path="/editarGuia/:id" component={EditarGuias} />
              <Route exact path="/rips" component={RIPS} />
              <Route exact path="/configuracion" component={Configuracion} />
              <Route
                exact
                path="/reportes/preradicados"
                component={ReportesPreRadicados}
              />
              <Route exact path="/reportes/rips" component={ReportesRips} />
              <Route
                exact
                path="/suraAuthentication"
                component={SuraAuthentication}
              />
              <Route
                exact
                path="/suraAuthenticationSeus4"
                component={SuraAuthenticationSeus4}
              />
              <Route
                exact
                path="/suraAuthenticationSeus4SSO"
                component={SuraAuthenticationSeus4SSO}
              />
              <Route
                exact
                path="/sanitasAuthentication"
                component={SanitasAuthentication}
              />
              <Route exact path="/consultarImagenes" component={ConsFact} />
              <Route exact path="/reportes" component={reportes} />
              <Route
                exact
                path="/reportes/lineamientosOperativos"
                component={reportesLineamientosOperativos}
              />
              <Route
                exact
                path="/reportes/reportePertinencia"
                component={Pertinencia}
              />
              <Route
                exact
                path="/reportes/logDeCargasDeSoportes"
                component={logCargaSoportes}
              />
              <Route
                exact
                path="/reportes/reporteTrazabilidadNotificaciones"
                component={reporteNotificaciones}
              />
               <Route
                exact
                path="/reportes/reporteNotificacionesEnviadas"
                component={reporteNotificacionesEnviadas}
              />
              <Route
                exact
                path="/reportes/ReporteRips"
                component={LogRips}
              />
              <Route
                exact
                path="/reportes/estadosFactura"
                component={reporteEstados}
              />
              <Route
                exact
                path="/actualizarInformacionPrestador"
                component={ActPrestador}
              />
              <Route exact path="*" component={PaginaNoEncontrada} />
            </Switch>
          </div>
          {/* <Footer /> */}
        </BrowserRouter>
      </Auth>
    );
  }
}

export default Router;
