import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";
import { Row, Col, TextInput, Button, Icon } from "react-materialize";
import { AuthConsumer } from "../../ContexApi/authContext";
import ComboClientes from "componentes/Utils/ComboClientes/ComboClientes";
import DocumentoTipoPrestador from "componentes/Utils/DocumentoTipoDocumentoPrestador/DocumentoTipoDocumentoPrestador";
import axios from "axios";
import { constServicios } from "../../constServicios";
import Swal from "sweetalert2";

class SelccionClientePrestador extends Component {
  state = {
    cliente: "",
    prestador: "",
    tipoDocumento: "",
    datosPrestador: "",
    cargando: false,
    showDocumentoTipoPrestador: true,
    showComboClientes: true
  };

  enviarCliente = async () => {
    this.setState({
      cargando: true
    });

    if (this.state.cliente == "") {
      Swal.fire({
        type: "error",
        title: "Debe seleccionar un cliente asignado",
      });
    } else {
      let res = await this.context.setCliente(this.state);
      if (res) {
        Swal.fire({
          title: "El cliente fue seleccionado exitosamente",
          type: "success",
          showCancelButton: true,
          confirmButtonColor: "#002c55",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cerrar",
          confirmButtonText: "Ir a inicio"
        }).then(result => {
          if (result.value) {
            this.props.history.push("/");
          }
        });
      } else {
        Swal.fire({
          type: "error",
          title: "Error en el proceso",
        });
      }
    }

    this.setState({
      showComboClientes: false
    });

    this.setState({
      cargando: false,
      showComboClientes: true
    });

    // document.getElementById("form").reset();
  };

  enviarPrestador = async () => {
    this.setState({
      cargando: true
    });

    if ((this.state.prestador == "") | (this.state.tipoDocumento == "")) {
      Swal.fire({
        type: "error",
        title: "Debe completar tipo documento y número de documento del prestador",
      });
    } else {
      const prestador = await axios.get(
        `${constServicios.url}${constServicios.urlPrestador}/${this.state.prestador}/${this.state.tipoDocumento}/${this.context.user.info.Cliente.idCliente}`,
        {
          headers: {
            Authorization: this.context.accessToken
          }
        }
      );

      if (prestador.data != null) {
        this.setState({
          datosPrestador: prestador.data
        });

        let res = await this.context.setPrestador(this.state);
        if (res) {
          Swal.fire({
            title: "El prestador fue seleccionado exitosamente",
            type: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cerrar",
            confirmButtonText: "Ir a inicio"
          }).then(result => {
            if (result.value) {
              this.props.history.push("/");
            }
          });
        } else {
          Swal.fire({
            type: "success",
            title: "Error en el proceso",
          });
        }
      } else {
        Swal.fire({
          type: "error",
          title: "No se encontraron datos con la información diligenciada",
        });
      }
    }

    this.setState({
      showDocumentoTipoPrestador: false
    });

    this.setState({
      cargando: false,
      showDocumentoTipoPrestador: true
    });
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user, accessToken }) =>
          authenticated && user.info.ClientesAsignados.length > 0 ? (
            <Can
              rules={user.permisos}
              perform="cambioClientePrestador:ver"
              yes={() => (
                <div className="card-panel">
                  <form id="form" onSubmit={event => event.preventDefault()}>
                    <Row>
                      <Col className="" s={12}>
                        <h6>
                          Selecciona un cliente asignado para administrar.
                        </h6>
                        <b>
                          Cliente:{" "}
                          {this.context.user.info.Cliente.nombreCliente}
                        </b>
                        <br />
                        <br />
                      </Col>
                      <Col className="" s={12}>
                        {this.state.showComboClientes === true ? (
                          <ComboClientes
                            comboCliente={e => this.setState({ cliente: e })}
                          />
                        ) : null}
                      </Col>
                      <Col className="" s={12}>
                        <Button
                          className="btn waves-light btn-color-arus right-align mr"
                          disabled={this.state.cargando}
                          node="button"
                          onClick={this.enviarCliente}
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="" s={12}>
                        <h6>
                          Selecciona el tipo de documento y digita el número de
                          documento del prestador que deseas administrar.
                        </h6>
                        <b>
                          Prestador:{" "}
                          {this.context.user.info.Prestadore
                            ? this.context.user.info.Prestadore.nombrePrestador
                            : "Ninguno"}
                        </b>
                        <br />
                        <br />
                      </Col>
                      <Col className="" s={12}>
                        {this.state.showDocumentoTipoPrestador === true ? (
                          <DocumentoTipoPrestador
                            tipoDocumento={e =>
                              this.setState({ tipoDocumento: e })
                            }
                            documento={e => this.setState({ prestador: e })}
                          />
                        ) : null}
                      </Col>
                      <Col className="" s={12}>
                        <Button
                          className="btn waves-light btn-color-arus right-align mr"
                          disabled={this.state.cargando}
                          node="button"
                          onClick={this.enviarPrestador}
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

SelccionClientePrestador.contextType = AuthConsumer;

export default SelccionClientePrestador;
