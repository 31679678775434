import React, { useEffect, useState, useContext } from "react";
import { Button, Switch, Icon, Select } from "react-materialize";
import "./index.css";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Componentes del proyecto
import authContext from "ContexApi/authContext";
import useAxiosInterceptor from "hooks/useAxiosInterceptor";
import FileUpload from "componentes/CustomUpload/FileUpload";
import { AuthConsumer } from "ContexApi/authContext";
import { ProgressBar } from "react-materialize";
import { Link } from "react-router-dom";
import Can from "../../ContexApi/Can";

import {
  URL_PLANTILLAS,
  API_MAESTRAS,
  API_CLIENTES,
  constServicios
} from "constServicios";

const MySwal = withReactContent(Swal);

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

// Constantes

const TablasMaestras = props => {
  const {
    user,
    user: {
      info: { Cliente }
    }
  } = useContext(authContext);
  useAxiosInterceptor();
  const [maestraSeleccionada, setMaestraSeleccionada] = useState("");
  const [nombreMaestraSeleccionada, setNombreMaestraSeleccionada] = useState(
    ""
  );
  const [clienteSeleccionado, setClienteSeleccionado] = useState("");
  const [rutaPlantilla, setRutaPlantilla] = useState("");
  const [tablasMaestras, setTablasMaestras] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultSelect, setDefaultSelect] = useState("Seleccione");

  const conxtFront = useContext(AuthConsumer);
  useEffect(() => {
    const obtenerListaCliente = async () => {
      try {
        const { user } = conxtFront;
        const {
          user: {
            info: { idUsuario }
          }
        } = conxtFront;
        setIsLoading(true);
        const response = await axios.get(
          `${API_CLIENTES}?idCliente=${Cliente.idCliente}&idUsuario=${idUsuario}`
        );
        if (response) setIsLoading(false);
        if (response.status === 200) {
          if (Array.isArray(response.data) && response.data.length > 1) {
            let listaPermisos = user.permisos;
            if (
              user.permisos.static.some(per => per === "maestras:listaClientes")
            ) {
              setListaClientes(response.data);
            } else {
              obtenerListaTablas(1);
            }
          } else {
            obtenerListaTablas(1);
          }
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        let mensajeDeError = "";
        if (e && e.response && e.response.status !== 401) {
          mensajeDeError = "";
          if (e.response.data.message) {
            mensajeDeError = e.response.data.message;
          } else {
            mensajeDeError =
              "Ocurrió un error al descargar lista de maestras, contactar a soporte.";
          }
          // Swal.fire(mensajeDeError);
          Swal.fire({
            title: mensajeDeError,
            type: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#002c55"
          });
        } else {
          mensajeDeError =
            "Ocurrió un error al descargar lista de maestras, contactar a soporte.";
        }
      }
    };
    obtenerListaCliente();
  }, [Cliente.idCliente]);

  const obtenerListaTablas = async ({ target }) => {
    try {
      const {
        user: {
          info: { idUsuario }
        }
      } = conxtFront;
      setIsLoading(true);
      let response;
      if (target) {
        setClienteSeleccionado(target.value);
        response = await axios.get(
          `${API_MAESTRAS}?idCliente=${target.value}&idUsuario=${idUsuario}`
        );
      } else {
        setDefaultSelect(Cliente.nombreCliente);
        setClienteSeleccionado(Cliente.idCliente);
        response = await axios.get(
          `${API_MAESTRAS}?idCliente=${Cliente.idCliente}&idUsuario=${idUsuario}`
        );
      }

      if (response) setIsLoading(false);
      if (response.status === 200) {
        setTablasMaestras(response.data);
      }
    } catch (e) {
      setIsLoading(false);
      let mensajeDeError = "";
      if (e && e.response && e.response.status !== 401) {
        if (e.response.data.message) {
          mensajeDeError = e.response.data.message;
        } else {
          mensajeDeError =
            "Ocurrió un error al descargar lista de maestras, contactar a soporte.";
        }
        // Swal.fire(mensajeDeError);
        Swal.fire({
          title: mensajeDeError,
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55"
        });
      } else {
        mensajeDeError =
          "Ocurrió un error al descargar lista de maestras, contactar a soporte.";
        // Swal.fire(mensajeDeError);
        Swal.fire({
          title: mensajeDeError,
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55"
        });
      }
    }
  };
  const manejoCambioDeTabla = ({ target }) => {
    setMaestraSeleccionada(target.value);
    //
    const maestraSeleccionada = tablasMaestras.find(
      maestra => maestra.idMaestra.toString() === target.value
    );
    if (maestraSeleccionada && maestraSeleccionada.nombre) {
      setNombreMaestraSeleccionada(maestraSeleccionada.nombre);
      if (maestraSeleccionada.rutaPlantilla) {
        setRutaPlantilla(maestraSeleccionada.rutaPlantilla);
      }
    }
  };

  const descargarPlantilla = () => {
    setIsLoading(true);
    const { accessToken } = conxtFront;
    try {
      axios
        .get(
          `${constServicios.url}${constServicios.urlMaestrasDescargarArchivo}${maestraSeleccionada}/${clienteSeleccionado}`,
          {
            headers: { Authorization: accessToken },
            responseType: "blob" //IMPORTANTE
          }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `tm_${nombreMaestraSeleccionada}.xlsx`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          Toast.fire({
            type: "error",
            title: "Archivo no encontrado."
          });
        });
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: "Ocurrió un problema descargando el archivo.",
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55"
      });
    }

    // window.open(`${URL_PLANTILLAS}${rutaPlantilla}`, "_blank");
  };

  const cargarTabla = async () => {
    if (!fileToUpload.name) {
      // alert("Debes seleccionar un archivo.");
      Swal.fire({
        title: "Debes seleccionar un archivo.",
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55"
      });
    } else if (/\.(xlsx|xls)$/i.test(fileToUpload.name) === false) {
      Swal.fire({
        title: "Formato de archivo incorrecto.",
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55"
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", fileToUpload);
    // formData.set("idCliente", conxtFront.user.info.Cliente.idCliente);
    formData.set("usuario", conxtFront.user.info.username);
    formData.set("nitCliente", conxtFront.user.info.Cliente.numeroDocumento);
    formData.set("idCliente", clienteSeleccionado);

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_MAESTRAS}/${maestraSeleccionada}/recibirCarga`,
        formData,
        config
      );
      if (response) setIsLoading(false);
      if (response.status === 200) {
        setMaestraSeleccionada("");
        setRutaPlantilla("");
        setFileToUpload(null);
        document.getElementById("form").reset();

        // Swal.fire("Carga realizada con éxito");
        Swal.fire({
          title: "Carga realizada con éxito",
          type: "success",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55"
        });
      }
    } catch (error) {
      setIsLoading(false);
      document.getElementById("limpiar").click();
      let mensajeDeError =
        "Ocurrió un error al cargar el archivo, contactar a soporte.";
      if (error.response) {
        if (error.response.data.message) {
          mensajeDeError = error.response.data.message;
        }
        if (error.response.data.error) {
          mensajeDeError = error.response.data.error;
        }
      }
      // Swal.fire(mensajeDeError);
      Swal.fire({
        title: mensajeDeError,
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55"
      });
    }
  };
  return (
    <div className="general_components">
      <div className="card-panel">
        {/* <div className="row">
          <div className="col s3 offset-s10">
            <Link
              to={`/files/maestras/${maestraSeleccionada}/tm_${nombreMaestraSeleccionada}.xlsx`}
              target="_blank"
              style={{ marginLeft: "1vw" }}
              download
              className="btn btn-color-arus floating"
              disabled={!maestraSeleccionada}
              title={`Descargar plantilla:  ${nombreMaestraSeleccionada}`}
            >
              <Icon className={{ margin: "30px" }}>cloud_download</Icon>
            </Link>
          </div>
        </div> */}
        <div className="row">
          <div className="col s3 offset-s1">
            <Select
              label="Cliente"
              id="clienteSeleccionado"
              name="clienteSeleccionado"
              onChange={obtenerListaTablas}
              value={clienteSeleccionado}
              disabled={listaClientes.length <= 1}
            >
              <option value="">{defaultSelect}</option>
              {listaClientes.map(cliente => (
                <option key={cliente.idCliente} value={cliente.idCliente}>
                  {cliente.nombreCliente}
                </option>
              ))}
            </Select>
          </div>
          <div className="col s5 input-master">
            <Select
              className="master-tables-input"
              label="Tabla maestra"
              id="maestraSeleccionada"
              name="maestraSeleccionada"
              onChange={manejoCambioDeTabla}
              value={maestraSeleccionada}
              disabled={tablasMaestras.length == 0}
            >
              <option value="">Seleccione</option>
              {tablasMaestras.map(maestra => (
                <option key={maestra.idMaestra} value={maestra.idMaestra}>
                  {maestra.nombre}
                </option>
              ))}
            </Select>
          </div>
          <div className="col s3  pt-btn-descarga">
            {/* <Link
              to={`/files/maestras/${maestraSeleccionada}/tm_${nombreMaestraSeleccionada}.xlsx`}
              target="_blank"
              style={{ marginLeft: "1vw" }}
              download
              className="btn btn-color-arus"
              disabled={!maestraSeleccionada}
              title={`Descargar plantilla:  ${nombreMaestraSeleccionada}`}
            > */}
            <Link
              // to={`/files/maestras/${maestraSeleccionada}/tm_${nombreMaestraSeleccionada}.xlsx`}
              // target="_blank"
              onClick={descargarPlantilla}
              style={{ marginLeft: "1vw" }}
              // download
              className="btn btn-color-arus"
              disabled={!maestraSeleccionada}
              title={`Descargar plantilla:  ${nombreMaestraSeleccionada}`}
            >
              <Icon>cloud_download</Icon>
            </Link>
          </div>
          <Can
            rules={user.permisos}
            perform="maestras:recibirCarga"
            yes={() => (
              <div className="col s10 offset-s1">
                <FileUpload
                  disabled={!maestraSeleccionada}
                  isLoading={isLoading}
                  file={fileToUpload}
                  setFile={setFileToUpload}
                  accept=".xlsx,.xls"
                />
              </div>
            )}
            no={() => null}
          />
        </div>

        {/* <div className="file-field input-field">
              <div className="btn btn-color-arus">
                <span>Examinar</span>
                <input
                  disabled={!maestraSeleccionada}
                  isLoading={isLoading}
                  file={fileToUpload}
                  setFile={setFileToUpload}
                  accept=".xlsx,.xls"
                  type="file"
                  // ref={this.file}
                />
              </div>
              <div className="file-path-wrapper">
                <input
                  className="file-path validate"
                  type="text"
                  placeholder="Arrastrar y soltar..."
                />
              </div>
            </div> */}
        {isLoading && (
          <div className="col m10 offset-m1">
            <ProgressBar />
          </div>
        )}

        <Can
          rules={user.permisos}
          perform="maestras:recibirCarga"
          yes={() => (
            <div className="row">
              <div className="col s4 offset-s5">
                <button
                  className="btn btn-color-arus"
                  type="file"
                  name="action"
                  disabled={!fileToUpload || isLoading}
                  onClick={cargarTabla}
                >
                  Cargar
                </button>
              </div>
            </div>
          )}
          no={() => null}
        />
      </div>
    </div>
  );
};

// TablasMaestras.contextType = AuthConsumer;

export default TablasMaestras;
