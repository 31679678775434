import React, { Component } from "react";
import { Link } from "react-router-dom";

//Estilos materialize
import { Button, Switch } from "react-materialize";
import axios from "axios";
import { constServicios } from "../../constServicios";
//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthConsumer } from "ContexApi/authContext";
import "./css/Lineamientos.css";
import moment from "moment";
const MySwal = withReactContent(Swal);

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class Lineamiento extends Component {
  state = {
    estadoSeleccionado: 0
  };

  static contextType = AuthConsumer;

  componentDidMount() {}

  cambiarEstado = async (e, id) => {
    let elemento = document.getElementById(id);
    elemento.setAttribute("disabled", true);

    const { accessToken, user } = this.context;

    let idEstado = e.target.checked ? 1 : 2;
    try {
      let response = await axios({
        method: "put",
        url: `${constServicios.url}${constServicios.urlEditarEstadoLineamento}${id}`,
        data: {
          idEstado,
          idCliente: user.info.Cliente.idCliente,
          idUsuario: user.info.idUsuario
        },
        headers: {
          Authorization: accessToken
        }
      });
      this.props.actualizarLineamiento(id, idEstado);

      Toast.fire({
        type: "success",
        title: `Lineamiento actualizado correctamente`
      });
    } catch (error) {
      console.error("Fallo al cambiar de estado", error);
      Toast.fire({
        type: "error",
        title: `Falló actualizando estado`
      });
    }
    elemento.removeAttribute("disabled");
  };

  checkSwith = idEstado => {
    return idEstado == 1 ? true : false;
  };
  validarUsuario = (Estado, idEstado, idLineamientos) => {
    if (
      this.context.user.info.Role.idRole === 9 ||
      this.context.user.info.Role.idRole === 8
    ) {
      return <td style={{ textAlign: "center" }}>{Estado.nombre}</td>;
    } else {
      return (
        <td className="opcionVigencia">
          <Switch
            id={`${idLineamientos}`}
            offLabel="Inactivo"
            onChange={e => this.cambiarEstado(e, idLineamientos)}
            onLabel="Activo"
            checked={this.checkSwith(idEstado)}
          />
        </td>
      );
    }
  };
  render() {
    const {
      idLineamientos,
      idEstado,
      TiposLineamiento,
      createdAt,
      descripcion,
      fechaAplicacion,
      path,
      updatedAt,
      Usuario,
      Estado
    } = this.props.dataLineamiento;

    return (
      <tr>
        <td style={{ textAlign: "center" }}>
          {idLineamientos ? idLineamientos : ""}
        </td>
        <td style={{ textAlign: "center" }}>
          {TiposLineamiento ? TiposLineamiento.nombre : ""}
        </td>
        {this.validarUsuario(Estado, idEstado, idLineamientos)}
        <td style={{ textAlign: "center" }}>
          {fechaAplicacion ? moment(fechaAplicacion).format("DD/MM/YYYY") : ""}
        </td>
        <td>{descripcion ? descripcion : " "}</td>
        {/* <td>{descripcion ? (descripcion.length > 20 ? descripcion.substring(0, 20) + '...' : descripcion.substring(0, 20)) : " "}</td> */}
        {Usuario ? (
          <td style={{ textAlign: "center" }}>
            {Usuario.primerNombre} {Usuario.primerApellido}
          </td>
        ) : (
          <td></td>
        )}
        <td>
          {path !== null ? (
            <Button
              onClick={() => this.props.descarga(idLineamientos, path)}
              floating
              tooltip="Descargar"
              small
              className="btn-color-arus"
            >
              <i class="material-icons">archive</i>
            </Button>
          ) : null}

          <Link to={`/lineamientos/${idLineamientos}`}>
            <Button
              floating
              small
              tooltip="Ver lineamiento"
              className="btn-color-arus"
              waves="light"
            >
              <i class="material-icons">pageview</i>
            </Button>
          </Link>
        </td>
      </tr>
    );
  }
}

Lineamiento.contenxtType = AuthConsumer;

export default Lineamiento;
