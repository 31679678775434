import React, { Component } from 'react';
import moment from 'moment';


class TablePreradicados extends Component {

    render() {
      if(this.props.dataPreradicado.length > 0 ){
        return (
            <table  className="responsive-table striped">
              <thead>
                <tr>
                    <th>Preradicado</th>
                    <th>Fecha factura</th>
                    <th>Soporte</th>
                    <th>Prestador</th>
                    <th>Factura</th>
                    <th>Factura</th>
                    <th>NPBS</th>
                    <th>Sucursal</th>
                </tr>
              </thead>
      
              <tbody>
                {this.props.dataPreradicado.map((data,key) => {
                  return(
                    <tr key={key}>
                      <td>{data.NumeroPreradicado}</td>
                      <td>{moment(data.FechaPreRadicado ).format('LLL')}</td>
                      <td>{data.OrigenSoporte}</td>
                      <td>{data.NitPrestador}</td>
                      <td>{data.NumeroFactura}</td>
                      <td>{data.ValorFactura}</td>
                      <td>{data.NPBS ? 1 : 0}</td>
                      <td>{data.SucursalNombre}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
        );
      }else{
        return '';
      }

    };
};
export default TablePreradicados;