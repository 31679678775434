import React, { Component } from "react";
import Router from "../componentes/Router";
import "./App.css";
import { Preloader } from "react-materialize";

//Redux
import { Provider } from "react-redux";
import store from "../store";
import LoadingOverlay from "react-loading-overlay";
import { AuthConsumer } from "../ContexApi/authContext";

// function App() {
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
  }

  componentDidMount() {
    if (!this.context.authenticated) {
      setTimeout(
        function() {
          this.setState({ isActive: false });
        }.bind(this),
        3000
      );
    } else {
      this.setState({ isActive: false });
    }
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text="Cargando..."
        className="loaderStyle"
      >
        <Provider store={store}>
          <Router />
        </Provider>
      </LoadingOverlay>
    );
  }
}

App.contextType = AuthConsumer;

export default App;
