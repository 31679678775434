import React, { Component, Fragment } from "react";
import { Select, Switch, Textarea } from "react-materialize";

//constantes
import { constLineamientos } from "./constLineamientos";

// import { agregarLineamiento } from "../../actions/lineamientosActions";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./css/Lineamientos.css";
import axios from "axios";
import { constServicios } from "../../constServicios";
import moment from "moment";

//DatePicker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { AuthConsumer } from "ContexApi/authContext";
registerLocale("es", es);

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
});
const day = Date.parse(moment(new Date()).add(3, "days").format());
class CrearLineamiento extends Component {
  state = {
    tipo: "-1",
    fechaAplicacion: "",
    estado: false,
    descripcion: "",
    tiposLineamientos: [],
    file: "",
    cargando: false,
  };

  componentDidMount() {
    this.cargarLineamientos();
  }

  cargarLineamientos = async () => {
    const { accessToken } = this.context;

    const respuesta = await axios.get(
      `${constServicios.url}${constServicios.urlTiposLineamientos}`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    if (respuesta.status === 200) {
      this.setState({
        tiposLineamientos: respuesta.data,
      });
    } else {
      MySwal.fire({
        title: "Error al consultar los tipos de lineamiento, por favor recargue la página.",
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55",
      });
    }
  };

  validarForm = () => {
    const { tipo, fechaAplicacion, descripcion } = this.state;
    let validacion = true;

    if (tipo === "-1") {
      Toast.fire({
        type: "error",
        title: `${constLineamientos.mensajeErrorTipo}`,
      });
      validacion = false;
    }

    if (fechaAplicacion === "") {
      Toast.fire({
        type: "error",
        title: `${constLineamientos.mensajeErrorFecha}`,
      });
      validacion = false;
    }

    if (descripcion === "") {
      Toast.fire({
        type: "error",
        title: `${constLineamientos.mensajeErrorDescripcion}`,
      });
      validacion = false;
    }

    if (descripcion.length > 255) {
      Toast.fire({
        type: "error",
        title: `${constLineamientos.mensajeErrorDescripcionCaracteres}`,
      });
      validacion = false;
    }

    return validacion;
  };

  isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  crear = async (e) => {
    try {
      e.preventDefault();
      if (!this.validarForm()) return null;
      this.setState({
        cargando: true,
      });
      const validarFile = this.FileUploadValid(this.state.file);
      if (this.state.file !== "" && !validarFile) {
        this.setState({
          cargando: false,
        });
        throw "Error al validar el archivo";
      }
      const formData = new FormData();
      formData.append("file", this.state.file);
      formData.set("descripcion", this.state.descripcion);
      formData.set("idUsuario", this.context.user.info.idUsuario);
      formData.set("idTiposLineamiento", this.state.tipo);
      formData.set("fechaAplicacion", new Date(this.state.fechaAplicacion));
      formData.set("idEstado", this.state.estado ? 1 : 2);
      formData.set("idCliente", this.context.user.info.Cliente.idCliente);
      const respuesta = await axios({
        method: "post",
        url: `${constServicios.url}${constServicios.urlLineamientos}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: this.context.accessToken,
        },
      });
      if (respuesta.status === 200 && respuesta.data.status == true) {
        await MySwal.fire({
          type: "success",
          html: "El lineamiento fue agregado correctamente.",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
        this.limpiar();
        this.props.activarTab(1);
        this.setState({
          cargando: false,
        });
      } else {
        MySwal.fire({
          title: respuesta.data.mensaje,
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
        this.setState({
          cargando: false,
        });
      }
    } catch (e) {
      // console.log(e);
      // MySwal.fire({
      //   title: "Error",
      //   type: "error",
      //   html: "ocurrio un error general",
      //   confirmButtonText: "Cerrar",
      //   confirmButtonColor: "#002c55"
      // });
      // this.limpiar();
    }
  };

  limpiar = () => {
    this.setState({
      tipo: "-1",
      fechaAplicacion: "",
      estado: false,
      descripcion: "",
      tiposLineamientos: [],
      file: "",
      cargando: false,
    });
    document.getElementById("crear_lineamiento").reset();
  };

  fechaAplicacionLineamiento = (e) => {
    this.setState({
      fechaAplicacion: e,
    });
  };

  tipoLineamiento = (e) => {
    this.setState({
      tipo: e.target.value,
    });
  };

  estadoLineamiento = (e) => {
    this.setState({
      estado: e.target.checked,
    });
  };

  descripcionLineamiento = (e) => {
    this.setState({
      descripcion: e.target.value,
    });
  };

  FileUploadValid = (file) => {
    if (file) {
      let allowedExtensions = /(.xlsx|.xls|.jpg|.jpeg|.png|.doc|.docx|.pdf|.tiff|.tif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        MySwal.fire({
          title: "Formato de archivo incorrecto",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
        return false;
      }
      if (Number(file.size) > 20000000) {
        MySwal.fire({
          title: "El archivo no puede pesar más de 20 MB",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55",
        });
        return false;
      }
      return file;
    }
  };

  render() {
    return (
      <Fragment>
        <div className="general_components">
          <div className="card-panel">
            <div className="row">
              <form
                id="crear_lineamiento"
                className="col s12"
                onSubmit={this.crear}
              >
                <div className="input-field col s12 m6">
                  <Select
                    s={12}
                    defaultValue="0"
                    label={constLineamientos.lbSelectTipo}
                    className="input-field col s12"
                    validate={true}
                    onChange={this.tipoLineamiento}
                  >
                    <option value="0" disabled>
                      {constLineamientos.valueDefaultSelectTipo}
                    </option>
                    {this.state.tiposLineamientos.map((tipo) => {
                      return (
                        <option
                          key={tipo.idTiposLineamiento}
                          value={`${tipo.idTiposLineamiento}`}
                        >
                          {tipo.nombre}
                        </option>
                      );
                    })}
                  </Select>
                </div>

                <div className="input-field col s12 m6">
                  <span className="col s12 spanDate">
                    {constLineamientos.lbDtFechaaplicacion}
                  </span>
                  <DatePicker
                    minDate={new Date(day)}
                    selected={this.state.fechaAplicacion}
                    name="fechaAplicacion"
                    onKeyDown={(e) => e.preventDefault()}
                    id="fechaAplicacion"
                    locale="es"
                    filterDate={this.isWeekday}
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => this.fechaAplicacionLineamiento(e)}
                    disabledKeyboardNavigation
                    autoComplete="off"
                  />
                </div>

                <div className="input-field col s12">
                  <Textarea
                    data-length={255}
                    label={constLineamientos.lbTextAreaDescripcion}
                    s={12}
                    onChange={this.descripcionLineamiento}
                    value={this.state.descripcion}
                  />
                </div>
                <div className="input-field file-field col s12">
                  <div className="btn btn-color-arus">
                    <span>{constLineamientos.btArchivo}</span>
                    <input
                      defaultValue={this.state.file}
                      id={"archivo"}
                      onChange={(e) =>
                        this.setState({ file: e.target.files[0] })
                      }
                      accept=".pdf, .jpg, .jpge, .png, .tiff, .tif , .xls, .xlsx, .doc, .docx"
                      type="file"
                    />
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                </div>
                <div className="input-field col s12">
                  <b>Opción de vigencia</b>
                  <Switch
                    id="opcionVigencia"
                    offLabel={constLineamientos.swEstadoOff}
                    onLabel={constLineamientos.swEstadoOn}
                    onChange={this.estadoLineamiento}
                  />
                </div>
                <div className="input-field col s12 center">
                  <button
                    className="btn btn-color-arus"
                    type="submit"
                    name="action"
                    disabled={this.state.cargando ? true : false}
                  >
                    {constLineamientos.btEnviar}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

CrearLineamiento.contextType = AuthConsumer;

export default CrearLineamiento;
