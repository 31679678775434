import React, { Fragment, useState, useContext, useEffect } from 'react'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import TextInput from 'react-materialize/lib/TextInput'
import { Button, Icon } from 'react-materialize'
import Swal from 'sweetalert2'
import Axios from 'axios'
import { AuthConsumer } from "../../ContexApi/authContext"
import { constServicios } from "../../constServicios"
import { ApiVisores, Visor, Position } from '../Visor_pdf_tiff';
import { async } from 'q'
const FormImg = (props) => {
  const conxtFront = useContext(AuthConsumer)
  const [buscar, setBuscar] = useState("Buscar");
  const [datos, setDatos] = useState({
    radicado: "",
    prefijo: "",
    factura: "",
    nit: "",
    idLote: ""
  })
  const [imagenes, setImagenes] = useState([])
  const [nombreActual, setNombreActual] = useState("")
  const [radicadoActual, setRadicadoActual] = useState("")
  const [facturaActual, setFacturaActual] = useState("")
  const [nitActual, setNitActual] = useState("")
  const [sucursalActual, setSucursalActual] = useState("")
  const [actual, setActual] = useState(0)
  const [NewActual, setNewActual] = useState(actual)
  const buscarDatos = async () => {
    if (!datos.radicado && !datos.nit && !datos.factura && !datos.prefijo && !datos.idLote) {
      // Swal.fire("No se puede realizar la consulta con los campos vacíos. Seleccione el filtro que requiere y genere nuevamente la consulta.")
      Swal.fire({
        type: "error",
        html: "No se puede realizar la consulta con los campos vacíos. Seleccione el filtro que requiere y genere nuevamente la consulta.",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
      return false
    }
    if (datos.prefijo && !datos.factura) {
      // Swal.fire("Si ingresas prefijo debes llenar el campo número de factura")
      Swal.fire({
        type: "error",
        html: "Si ingresas prefijo debes llenar el campo número de factura",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
      return false
    }
    if (datos.factura && !datos.nit) {
      // Swal.fire("Debe ingresar el Número de Factura y el Nit del Prestador para realizar la consulta")
      Swal.fire({
        type: "error",
        html: "Debe ingresar el Número de Factura y el Nit del Prestador para realizar la consulta",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
      return false
    }
    if (!datos.factura && datos.nit) {
      // Swal.fire("Debe ingresar el Nit del Prestador y Número de Factura para realizar la consulta")
      Swal.fire({
        type: "error",
        html: "Debe ingresar el Nit del Prestador y Número de Factura para realizar la consulta",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
      return false
    }
    try {
      setBuscar("Buscando...")
      let result = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlServicioImagenes}`,
        params: { ...datos },
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      let response = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlServicioImagenesAmazon}`,
        params: { url: result.data.listaImagenes[0].imagen },
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      setImagenes(result.data.listaImagenes)
      viewVisor(response.data)
      setNombreActual(result.data.listaImagenes[0].nombreArchivo)
      setRadicadoActual(result.data.listaImagenes[0].radicado)
      setFacturaActual(result.data.listaImagenes[0].factura)
      setNitActual(result.data.listaImagenes[0].nit)
      setSucursalActual(result.data.listaImagenes[0].sucursal)
      setActual(0)
      setNewActual(0)
      setBuscar("Buscar")
    } catch (error) {
      setBuscar("Buscar")
      // Swal.fire("No se encontraron resultados para esta factura")
      Swal.fire({
        type: "error",
        html: "No se encontraron resultados para esta factura",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
    }
  }
  const onChange = (e) => {
    const { value, name } = e.target
    const newData = { ...datos }
    if (name === 'prefijo') {
      newData[name] = value.toUpperCase();
    } else {
      newData[name] = value
    }
    setDatos(newData)
  }
  const crearCampos = () => {
    return <Fragment>
      <Row>
        <Col m={4}>
          <TextInput onChange={onChange} onKeyPress={
            (event) => {
              var key = window.event ? event.which : event.keyCode;
              if (key < 48 || key > 57) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          } m={12} name={"radicado"} value={datos.radicado} label="Radicado" />
        </Col>
        <Col m={4}>
          <TextInput onChange={onChange} m={3} name={"prefijo"} value={datos.prefijo} label="Prefijo" />
          <Col m={1}><span style={{ position: "absolute", marginTop: "2vw" }}>-</span></Col>
          <TextInput onChange={onChange} onKeyPress={
            (event) => {
              var key = window.event ? event.which : event.keyCode;
              if (key < 48 || key > 57) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          } m={8} name={"factura"} value={datos.factura} label="Número de Factura" />
        </Col>
        <Col m={4}>
          <TextInput onChange={onChange} onKeyPress={
            (event) => {
              var key = window.event ? event.which : event.keyCode;
              if (key < 48 || key > 57) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          } m={12} name={"nit"} value={datos.nit} label="Nit del prestador" />
        </Col>
      </Row>
      <Row>
        <Col m={4}>
          <TextInput onChange={onChange} onKeyPress={
            (event) => {
              var key = window.event ? event.which : event.keyCode;
              if (key < 48 || key > 57) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          } m={12} name={"idLote"} value={datos.idLote} label="Lote" />
        </Col>
        <Col m={4}>
          <Button
            className="btnNuevo btn-color-arus"
            disabled={buscar === "Buscar" ? false : true}
            onClick={() => {
              buscarDatos()
            }}
            style={{ marginTop: "5%" }}
          >
            {buscar}
          </Button>
        </Col>
        <Col m={4}>
          <Button
            className="btnNuevo btn-color-arus"
            onClick={() => {
              setDatos({
                radicado: "",
                prefijo: "",
                factura: "",
                nit: "",
                idLote : ""
              })
            }}
            style={{ marginTop: "5%" }}
          >
            Limpiar
          </Button>
        </Col>
      </Row>
    </Fragment >
  }
  const viewVisor = (url) => {
    ApiVisores({
      url,
      container: 'div_canvas',
    });
  }

  const antPdf = async () => {
    try {
      let newActual = actual - 1;
      let response = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlServicioImagenesAmazon}`,
        params: { url: imagenes[newActual].imagen },
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      viewVisor(response.data)
      setNombreActual(imagenes[newActual].nombreArchivo)
      setRadicadoActual(imagenes[newActual].radicado)
      setFacturaActual(imagenes[newActual].factura)
      setNitActual(imagenes[newActual].nit)
      setSucursalActual(imagenes[newActual].sucursal)
      setActual(newActual)
      setNewActual(newActual)
    } catch (error) {
      console.error(error)
    }
  }

  const sigPdf = async () => {
    try {
      let newActual = actual + 1;
      let response = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlServicioImagenesAmazon}`,
        params: { url: imagenes[newActual].imagen },
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      viewVisor(response.data)
      setNombreActual(imagenes[newActual].nombreArchivo)
      setRadicadoActual(imagenes[newActual].radicado)
      setFacturaActual(imagenes[newActual].factura)
      setNitActual(imagenes[newActual].nit)
      setSucursalActual(imagenes[newActual].sucursal)
      setActual(newActual)
      setNewActual(newActual)
    } catch (error) {
      console.error(error)
    }
  }
  const finPDF = async () => {
    try {
      let newActual = imagenes.length - 1;
      let response = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlServicioImagenesAmazon}`,
        params: { url: imagenes[newActual].imagen },
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      viewVisor(response.data)
      setNombreActual(imagenes[newActual].nombreArchivo)
      setRadicadoActual(imagenes[newActual].radicado)
      setFacturaActual(imagenes[newActual].factura)
      setNitActual(imagenes[newActual].nit)
      setSucursalActual(imagenes[newActual].sucursal)
      setActual(newActual)
      setNewActual(newActual)
    } catch (error) {
      console.error(error)
    }
  }
  const iniPDF = async () => {
    try {
      let newActual = 0;
      let response = await Axios({
        method: "get",
        url: `${constServicios.url}${constServicios.urlServicioImagenesAmazon}`,
        params: { url: imagenes[newActual].imagen },
        headers: {
          Authorization: conxtFront.accessToken
        }
      })
      viewVisor(response.data)
      setNombreActual(imagenes[newActual].nombreArchivo)
      setRadicadoActual(imagenes[newActual].radicado)
      setFacturaActual(imagenes[newActual].factura)
      setNitActual(imagenes[newActual].nit)
      setSucursalActual(imagenes[newActual].sucursal)
      setActual(newActual)
      setNewActual(newActual)
    } catch (error) {
      console.error(error)
    }
  }
  const onChangedNumber = (e) => {
    const { value } = e.target
    let newActual = value - 1
    setNewActual(newActual)
  }
  const pressEnter = async (e) => {
    var key = window.event ? e.which : e.keyCode;
    if (key < 48 || key > 57) {
      e.preventDefault();
    }
    let tecla = (document.all) ? e.keyCode : e.which;
    if (tecla == 13) {
      try {
        if (NewActual > imagenes.length - 1) {
          // Swal.fire(`Solo hay ${imagenes.length} imagenes`)
          Swal.fire({
            type: "error",
            html: `Solo hay ${imagenes.length} imagenes`,
            confirmButtonText: "Cerrar",
            confirmButtonColor: '#002c55',
          });
          return false
        }
        if (NewActual < 0) {
          return false
        }
        let response = await Axios({
          method: "get",
          url: `${constServicios.url}${constServicios.urlServicioImagenesAmazon}`,
          params: { url: imagenes[NewActual].imagen },
          headers: {
            Authorization: conxtFront.accessToken
          }
        })
        viewVisor(response.data)
        setNombreActual(imagenes[NewActual].nombreArchivo)
        setRadicadoActual(imagenes[NewActual].radicado)
        setFacturaActual(imagenes[NewActual].factura)
        setNitActual(imagenes[NewActual].nit)
        setSucursalActual(imagenes[NewActual].sucursal)
        setActual(NewActual)
      } catch (error) {
        console.error(error)
      }
    }
  }
  const pintarVisor = () => {
    if (imagenes.length > 0) {
      return <Fragment>
        <div className="card-panel cardVisor">
          <Row>
            <Col m={4}>
              <span><strong>Tipo documental: </strong>{nombreActual}</span><br></br>
              <span><strong>Radicado BH: </strong>{radicadoActual}</span><br></br>
              <span><strong>Número de factura: </strong>{facturaActual}</span><br></br>
              <span><strong>Nit del prestador: </strong>{nitActual}</span><br></br>
              <span><strong>Sucursal de origen: </strong>{sucursalActual}</span><br></br>
            </Col>
            <Col style={{ marginTop: "3vw" }} m={8}>
              <Button className="btnNuevo btn-color-arus" disabled={actual === 0 ? true : false} onClick={() => { iniPDF() }} m={2}>
                Ini
              </Button>
              <Button style={{ marginLeft: "1vw" }} className="btnNuevo btn-color-arus" disabled={actual === 0 ? true : false} onClick={() => { antPdf() }} m={2}>
                Ant
              </Button>
              <input onKeyPress={(event) => pressEnter(event)} onChange={(e) => onChangedNumber(e)} style={{ marginRight: "5vw", marginTop: "-1vw" }} className="col m2" value={NewActual + 1} />

              <Button className="btnNuevo btn-color-arus" disabled={actual < (imagenes.length - 1) ? false : true} onClick={() => { sigPdf() }} style={{ marginLeft: "1vw" }} m={2}>
                Sig
              </Button>

              <Button className="btnNuevo btn-color-arus" disabled={actual < (imagenes.length - 1) ? false : true} onClick={() => { finPDF() }} style={{ marginLeft: "1vw" }} m={2}>
                Fin
              </Button>
            </Col>
          </Row>
        </div>
        <Visor container={'div_canvas'} />
      </Fragment>
    }
  }
  return <div>
    {crearCampos()}
    {pintarVisor()}
  </div>
}

export default FormImg