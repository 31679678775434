import React from "react";
import {Redirect} from "react-router-dom";
import qs from "qs";

import {AuthConsumer} from "../ContexApi/authContext";

const CallbackLogOut = props => (
  <AuthConsumer>
    {({logout}) => {
      if (/state/.test(props.location.search)) {
        logout(qs.parse(props.location.search, { ignoreQueryPrefix: true }));
      }
      return <Redirect to="/inicio"/>;
    }}
  </AuthConsumer>
);

export default CallbackLogOut;