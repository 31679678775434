import React, { Component } from "react";
import { constPRERADICADO } from "./constPreRadicado";
import { constServicios } from "../../constServicios";
import "./css/PreRadicado.css";
import { RadioGroup, Row, ProgressBar, Col, Button } from "react-materialize";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";
import { AuthConsumer } from "../../ContexApi/authContext";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import BotonAyuda from "../herramientas/BotonAyuda";

const MySwal = withReactContent(Swal);

//Barcode
const JsBarcode = require("jsbarcode");

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class PreRadicado extends Component {
  state = {
    tipo: "",
    preload: false,
    preRadicado: {},
    token: ""
  };

  constructor() {
    super();
    this.file = React.createRef();
  }

  imprimirCodigo = divId => {
    var content = document.getElementById(divId).innerHTML;
    var mywindow = window.open("", "Print", "height=600,width=800");

    mywindow.document.write("<html><head><title>Código de barras</title>");
    mywindow.document.write("</head><body >");
    mywindow.document.write(content);
    mywindow.document.write("</body></html>");

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
    return true;
  };

  mensajePreRadicado = resp => {
    this.setState({
      preRadicado: resp
    });

    let mensaje = "";

    if (this.state.tipo === constPRERADICADO.nombreFisico)
      mensaje = constPRERADICADO.msjPreRadicadoFisico;
    else mensaje = constPRERADICADO.msjPreRadicadoDigital;

    const html = `<p>${mensaje}</p>
                  <center>
                    <div id="codigodebarras">
                      <svg id='barcode' />
                    </div>
                  </center>
                  <br/>
                  <br/>
                  <button id="imprimir" class="waves-effect waves-light btn blue">
                    Imprimir y/o descargar
                  </button>
                  <button id="cerrar" class="waves-effect waves-light btn red" disabled>
                    Cerrar
                  </button>`;

    MySwal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      title: "Pre-Radicado Registrado Exitosamente!",
      html: html,
      onBeforeOpen: () => {
        const content = MySwal.getContent();
        const $ = content.querySelector.bind(content);

        const imprimir = $("#imprimir");
        const cerrar = $("#cerrar");

        imprimir.addEventListener("click", () => {
          if (this.imprimirCodigo("codigodebarras")) cerrar.disabled = false;
        });

        cerrar.addEventListener("click", () => {
          MySwal.close();
        });
        JsBarcode("#barcode", String(resp.data.idPreradicado));
      },
      onClose: () => {
        this.setState({});
      }
    });
  };

  cargarPre = e => {
    try {
      // this.mensajePreRadicado(false);
      e.preventDefault();
      if (!this.validaciones()) return null;

      const { accessToken, user } = this.context;

      this.setState({
        preload: true
      });

      var formData = new FormData();
      formData.append(
        constPRERADICADO.tagArchivo,
        this.file.current.files[0],
        this.file.current.files[0].name
      );

      const data = {
        idTipoRecepcion:
          this.state.tipo === constPRERADICADO.nombreFisico ? 1 : 2,
        idUsuario: user.info.idUsuario,
        idCliente: user.info.Cliente.idCliente,
        nombreCliente: user.info.Cliente.nombreCliente,
        nombrePrestador: user.info.Prestadore.nombrePrestador,
        modulo: "preradicado",
        idPrestador: user.info.Prestadore.idPrestador
      };

      formData.append(constPRERADICADO.tagTipo, JSON.stringify(data));

      axios
        .post(
          `${constServicios.url}${constServicios.urlArchivoPreRadicado}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: accessToken
            }
          }
        )
        .then(resp => {
          if (resp.status === 200) {
            this.mensajePreRadicado(resp);
            this.setState({
              tipo: ""
            });
            document.getElementById("form_preradicado").reset();
          } else {
            MySwal.fire({
              title: 'Ocurrio un error en el proceso. Contacte a un administrador.',
              type: "error",
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          }
          this.setState({
            preload: false
          });
        })
        .catch(err => {
          this.setState({
            preload: false
          });
          if (!err.response) {
            MySwal.fire({
              title: "Respuesta sin datos",
              type: "error",
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          } else {
            let msj = "";
            if (Array.isArray(err.response.data.error.message)) {
              err.response.data.error.message.map(
                mensaje => (msj += `<p>${mensaje}</p>`)
              );
            } else {
              msj = err.response.data.error.message;
            }
            MySwal.fire({
              title: msj,
              type: "error",
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          }
        });
    } catch (e) {
      console.log("catch");
      MySwal.fire({
        title: e.message,
        type: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: '#002c55',
      });
    }
  };

  validaciones = () => {
    if (this.file.current.files.length === 0) {
      Toast.fire({
        type: "error",
        title: constPRERADICADO.mensajeErrorArchivo
      });
      return false;
    } else {
      const nameFile = this.file.current.files[0].name;
      const extencion = nameFile.substring(nameFile.lastIndexOf("."));
      if (constPRERADICADO.extencionesPermitidas.indexOf(extencion) < 0) {
        Toast.fire({
          type: "error",
          title: `${constPRERADICADO.mensajeErrorExtenciones}${[
            ...constPRERADICADO.extencionesPermitidas
          ]}`
        });
        return false;
      }
    }

    if (this.state.tipo === "") {
      Toast.fire({
        type: "error",
        title: constPRERADICADO.mensajeErrorTipo
      });
      return false;
    }

    if (this.file.current.files[0].size / 1024 / 1024 > 5) {
      Toast.fire({
        type: "error",
        title: "el archivo pesa más de 5 MB"
      });
      return false;
    }

    return true;
  };

  tipoCheck = e => {
    if (e.target.value === constPRERADICADO.nombreFisico) {
      MySwal.fire({
        title: constPRERADICADO.tituloInfo,
        type: "info",
        html: constPRERADICADO.mensajeInfoFisico,
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55"
      });
    } else if (e.target.value === constPRERADICADO.nombreDigital) {
      MySwal.fire({
        title: constPRERADICADO.tituloInfo,
        type: "info",
        html: constPRERADICADO.mensajeInfoDigital,
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#002c55"
      });
    }

    this.setState({
      tipo: e.target.value
    });
  };

  notificarActualizacion = () => {
    // //TODO realizar llamado axios
    try {
      const { accessToken, user } = this.context;
      const data = {
        titulo: "Actualización plantilla",
        descripcion:
          "por favor descargue la nueva plantilla actualizada para pre-radicar",
        link: "/preradicado",
        modulo: "Pre-Radicado",
        cliente: user.info.Cliente.idCliente
      };
      axios
        .post(
          `${constServicios.url}${constServicios.urlNotificarUsuariosCliente}`,
          data,
          {
            headers: {
              Authorization: accessToken
            }
          }
        )
        .then(dato => {
          if (dato.status === 200 && dato.data.status) {
            MySwal.fire({
              type: "success",
              html: `Se notificó correctamente a todos los usuarios del cliente ${user.info.Cliente.nombreCliente}`,
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          } else {
            MySwal.fire({
              type: "error",
              html: "Tuvimos problemas para notificar, vuelve a intentarlo.",
              confirmButtonText: "Cerrar",
              confirmButtonColor: '#002c55',
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  preload = () => {
    if (!this.state.preload) return null;
    return (
      <Row>
        <Col offset="s3" s={6}>
          <ProgressBar />
        </Col>
      </Row>
    );
  };

  renderBtnCarga = user => {
    return (
      <Can
        rules={user.permisos}
        perform="preradicados:cargar"
        yes={() => (
          <button
            className="btn btn-color-arus right-align"
            disabled={this.state.preload}
          >
            {constPRERADICADO.btnCarga}
          </button>
        )}
      />
    );
  };

  limpiarBtn = () => {
    this.setState({
      tipo: ""
    });
    document.getElementById("form_preradicado").reset();
  };

  renderBtnLimpiar = () => {
    if (this.state.tipo === "") return null;

    return (
      <button
        className="btn red darken-1 right-align"
        onClick={() => this.limpiarBtn()}
      >
        Cancelar
      </button>
    );
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) => {
          let opciones = [];
          if (user.permisos.static.includes("preradicado:opcionFisico"))
            opciones.push({
              label: constPRERADICADO.labelTipoFisico,
              value: constPRERADICADO.nombreFisico
            });

          if (user.permisos.static.includes("preradicado:opcionDigital"))
            opciones.push({
              label: constPRERADICADO.labelTipoDigital,
              value: constPRERADICADO.nombreDigital
            });

          if (authenticated && user.info.Prestadore)
            return (
              <React.Fragment>
                <div className="card-panel">
                  <BotonAyuda
                    info={{
                      texto: `Descarga la plantilla en el siguiente enlace: <a href="/files/${user.info.Cliente.idCliente}/PlantillaPreRadicado.xlsb" target="_blank" download className="btn waves-effect waves-light cyan accent-4">Descargar plantilla</a>`,
                      titulo: "Ayuda Pre-Radicado"
                    }}
                  />
                  <form onSubmit={this.cargarPre} id="form_preradicado">
                    <div className="row">
                      <div className="col l8 m8 s12 offset-l2 offset-m2">
                        <div className="file-field input-field">
                          <div className="btn btn-color-arus">
                            <span>
                              {constPRERADICADO.btnArchivoPreradicado}
                            </span>
                            <input
                              accept={constPRERADICADO.extencionesPermitidas}
                              type="file"
                              ref={this.file}
                            />
                          </div>
                          <div className="file-path-wrapper">
                            <input
                              className="file-path validate"
                              type="text"
                              placeholder="Arrastrar y soltar..."
                            />
                          </div>
                        </div>
                        {this.preload()}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col s10 m4 l2 offset-l5 offset-m4 offset-s1">
                        <ul className="collection with-header" id="task-card">
                          <li className="collection-header">
                            <h5>{constPRERADICADO.tituloTipo}</h5>
                          </li>
                          <RadioGroup
                            name="tipo"
                            label="Tipo Pre-Radicado"
                            onChange={this.tipoCheck}
                            value={this.state.tipo}
                            options={opciones}
                          />
                        </ul>
                      </div>
                    </div>

                    <Row>
                      <Col s={12} m={6}>
                        <Can
                          rules={user.permisos}
                          perform="preradicados:notificarActualizacion"
                          yes={() => (
                            <Button
                              className="btn-color-arus"
                              onClick={() => this.notificarActualizacion()}
                              type="button"
                            >
                              Notificar actualización plantilla
                            </Button>
                          )}
                        />
                      </Col>

                      <Col s={12} m={6} className="right-align">
                        {this.renderBtnCarga(user)}
                      </Col>
                    </Row>
                  </form>
                  {this.renderBtnLimpiar()}
                </div>
              </React.Fragment>
            );
          else {
            if (!user.info.Prestadore) {
              Swal.fire({
                type: "error",
                title: "No tiene un prestador asignado para la carga de pre-radicados",
              });
            }
            return <Redirect to="/inicio" />;
          }
        }}
      </AuthConsumer>
    );
  }
}

PreRadicado.contextType = AuthConsumer;

export default PreRadicado;
