import React, { Fragment } from "react";
import { Tab, Tabs } from "react-materialize";
import FormImg from "./form";
import "./css/index.css";
import { AuthConsumer } from "../../ContexApi/authContext";
import Can from "../../ContexApi/Can";
import { Redirect } from "react-router-dom";
const ConsFact = () => {
  return (
    <AuthConsumer>
      {({ authenticated, user }) =>
        authenticated ? (
          <Can
            rules={user.permisos}
            perform="consultarImagenes:ver"
            yes={() => (
              <Fragment>
                <Tabs className="tab-demo z-depth-1">
                  <Tab title="Consultar Imagenes">
                    <div className="card-panel">
                      <FormImg />
                    </div>
                  </Tab>
                </Tabs>
              </Fragment>
            )}
            no={() => <Redirect to="/inicio" />}
          />
        ) : (
          <Redirect to="/inicio" />
        )
      }
    </AuthConsumer>
  );
};
export default ConsFact;
