import React, { Component } from 'react';
import FilterArus from './filterArus';
import FilterCliente from './filterCliente';
import FilterPrestador from './filterPrestador';
import M from 'materialize-css';

class ReportesPreradicados extends Component {

    state={
        usuario:'',
        filters:{
            numeroFactura:'',
            numeroPreradicado:'',
            nitCliente:'',
            nitPrestador:'',
            alfa:'',
            fechaInicial:'',
            fechaFinal:''
        }
    }


    componentDidMount = () => {
        M.FormSelect.init(document.querySelectorAll('select'));
    };
    handleChange = (e) => this.setState({usuario:e.target.value})

    filterUsuarios = usuario => {
        switch (usuario) {
            case '3':
                return(<FilterArus usuario={usuario}/>)
                break;
            case '4':
                return(<FilterCliente usuario={usuario}/>)
                break;
            case '2':
                return(<FilterPrestador usuario={usuario}/>)
                break;        
            default:
                return null
                break;
        }
    }

    render() {
        return (
            <div className="general_components">
                    <div className="row">
                        <div className="input-field col s12">
                            <select onChange={this.handleChange}>
                            <option value="" disabled selected>(usuario simulado segun sus roles.)</option>
                            <option value="3">Arus</option>
                            <option value="2">Prestador</option>
                            <option value="4">Cliente</option>
                            </select>
                            <label>Tipo de Usuarios</label>
                        </div>
                        {this.filterUsuarios(this.state.usuario)}
                    </div>
                
            </div>
        );
    };
};
export default ReportesPreradicados;