import React, { Component } from "react";
import { AuthConsumer } from "../../ContexApi/authContext";
import { Link } from "react-router-dom";
import { Dropdown, Divider, Button } from "react-materialize";
import M from "materialize-css";
import Notificaciones from "componentes/Notificaciones/Notificaciones";

class User extends Component {
  state = {};

  componentDidMount = () => {
    M.Sidenav.init(document.querySelectorAll(".sidenav"));
  };

  renderLogout = (authWeb, logout) => {
    if (authWeb) {
      return <Link to={"/logout"}>Cerrar Sesión</Link>;
    } else {
      return (
        <Button waves="light" flat onClick={logout}>
          Cerrar Sesión
        </Button>
      );
    }
  };

  render() {
    return (
      <AuthConsumer>
        {({
          user,
          logout,
          authWeb,
          authenticated,
          accessToken,
          params,
          notificaciones
        }) => (
          <React.Fragment>
            <li>
              <img
                src="/images/logoCliente/arusLogo.png"
                alt=""
                height="70px"
                width="auto"
                className="hide-on-small-only"
              />
            </li>
            <li>
              {authenticated && notificaciones ? (
                <Notificaciones
                  authenticated={authenticated}
                  accessToken={accessToken}
                  params={params}
                />
              ) : null}
            </li>
            <li>
              <Dropdown
                trigger={
                  <Link to={`#`} className="othera">
                    <i className="material-icons">account_circle</i>
                  </Link>
                }
              >
                <Link to={"#"}>{user.name}</Link>
                {/* <Divider /> */}
                {this.renderLogout(authWeb, logout)}
              </Dropdown>
            </li>
          </React.Fragment>
        )}
      </AuthConsumer>
    );
  }
}

User.contextType = AuthConsumer;

export default User;
