
require("dotenv").config();

export const constServicios = {
  url:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8081/api"
      : "https://portal.solucionesdigitalesarus.com:8081/api",
  urlLogout:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8081/wso2/logout"
      : "https://portal.solucionesdigitalesarus.com:8081/wso2/logout",
  urlRIPS: "https://val.solucionesdigitalesarus.com",
  urlPortal:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://portal.solucionesdigitalesarus.com",
  urlWSO2: "wso2.solucionesdigitalesarus.com:8080",
  clientId_WSO2: "JEkbSoNkl3S40Zw2T8owGJvPgzga",
  urlMenu: "/modulosRole/",
  urlArchivoEnvioCliente: "/cargarEnvioCliente/",
  urlArchivoPreRadicado: "/preradicados/",
  urlLineamientos: "/lineamientos",
  urlLineamientosDescargarArchivo: "/lineamientoDescargarArchivo/",
  urlTiposLineamientos: "/tiposlineamiento/",
  urlGuias: "/guiaspreradicado/",
  urlSoportes: "/soportes/",
  urlReportesPreradicados: "/reportes/reportespreradicados?",
  urlReportesPreradicadosPagination:
    "/reportes/reportespreradicados/pagination?",
  urlReportesFacturasPreradicados: "/reportes/reportesfacturaspreradicadas?",
  urlReportesFacturasPreradicadosPagination:
    "/reportes/reportesfacturaspreradicadas/pagination?",
  urlReportesCargaRips: "/reportes/reportescargarips?",
  urlReportesCargaRipsPagination: "/reportes/reportescargarips/pagination?",
  urlInfoUser: "/usuariosUserName/",
  urlInfoUserInd: "/UserNameInd/",
  urlParamsApp: "/paramsapp/",
  urlClientesAsignados: "/getClientesAsignado/",
  urlUsuarios: "/usuarios/",
  urlTiposDocumentos: "/tipoDocumento",
  urlRol: "/roles",
  urlCliente: "/clientes",
  urlPrestador: "/prestadores",
  urlGetWSO2: "/wso2/",
  urlPrestadorCliente: "/prestadoresCli/",
  urlSedes: "/sedes/",
  urlCiudades: "/ciudades/",
  urlGetPermisos: "/permisosUser/",
  urlSedesCity: "/sedesByCity/",
  keyEncryptSesion: ".Arusksession*",
  urlCanNotificaciones: "/cantidadNotificaciones/",
  urlNotificaciones: "/obtenerNotificaciones/",
  urlMarcarNotificaciones: "/marcarNotificaciones/",
  urlModulos: "/modulos",
  urlModulosRol: "/modulosRole/",
  urlPermisosRol: "/permisosRoles/",
  urlPermisos: "/permisos/",
  urlPermisosModulo: "/permisosByModule/",
  urlNotificarUsuariosCliente: "/notificarUsuariosCliente/",
  urlServicioImagenes: "/listarImagenes",
  urlServicioImagenesAmazon: "/validarImagen",
  urlEditarEstadoLineamento: "/estadolineamiento/",
  urlEstados: "/estados",
  UPLOAD_FILE_PATH_OUT_LINEAMIENTOS:"/CMI/lineamientos_operativos/",
  urlReporteLineamientosOperativos: "/lineamientosReporte",
  urlApiClientesGestion: "/clientesGestion",
  urlApiResponsablesGestion :"/responsablesGestion",
  urlApiEstadosGestion : "/estadosGestion",
  urlApiPertinenciaGestion : "/pertinenciaGestion",
  urlNotifi: "/obtenerNotificaciones",
  urlReporteLogCargaSoporte: "/logCargaSoporte",
  urlReporteLogRips: "/logRips",
  urlMaestrasDescargarArchivo: "/maestraDescargarArchivo/",
  urlParamsAppKES: "/paramsappkes",
  urlTipologiasGestion: "/tipologiasGestion",
  urlParamsClientKey: "/paramsclientkey",
  actualizarPrestadorArl: '/actualizarPrestadorArl',
  reporteEstadosFactura: '/reportes/estadosFactura',
  estadosPrestador: '/reportes/estadosFactura/estadosPrestador',
  validarMensajeBienvenida: '/mensaje',
  actualizarPrestadorArl: '/actualizarPrestadorArl/',
  reporteTrazabilidadNotificaciones: '/reporteTrazabilidadNotificacion/',
  reporteNotificacionesEnviadas: '/reporteNotificacionesEnviadas/',
  actualizarInfoPrestador: '/actualizarInfoPrestador/',
  getPrestador: '/datosPrestador',
};

export const URL_DOMAIN_COOKIE = ".solucionesdigitalesarus.com";
// export const URL_DOMAIN_COOKIE = "localhost";
export const API_AUTH_SURA = `${constServicios.url}/authSura`;
export const API_AUTH_SANITAS = `${constServicios.url}/authSanitas`;
export const API_VALIDATE_TOKEN_SURA = `${constServicios.url}/validateTokenSura`;
export const BASE_URL =
process.env.NODE_ENV === "development"
? "http://localhost:8081/"
: "https://portal.solucionesdigitalesarus.com:8081/";

export const URL_PLANTILLAS = `${BASE_URL}static`;
export const API_MAESTRAS = `${constServicios.url}/maestras`;
export const API_CLIENTES = `${constServicios.url}/clientesMaestra`;
export const nitArl = '890903790';

export const API_AUTH_SURA_SEUS4 = `${constServicios.url}/authSuraSeus4`;
export const API_AUTH_SURA_SEUS4_SSO = `${constServicios.url}/authSuraAssert`;
export const API_AUTH_SURA_LOGIN_SSO = `${constServicios.url}/doLogin`;
