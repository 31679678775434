import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthConsumer } from "../../../ContexApi/authContext";
import "./css/Sidebar.css";
import { Icon, Button } from "react-materialize";
// import { Redirect } from "react-router-dom";
import Can from "../../../ContexApi/Can";

class UserSidebar extends Component {
  state = {};
  render() {
    return (
      <AuthConsumer>
        {({ user }) => (
          <li>
            <div className="user-view">
              <div className="background" />
              <Link to="/">
                <img
                  src={`/images/logoCliente/${
                    user.info ? user.info.Cliente.logo : "arusLogo.png"
                  }`}
                  alt={`${user.info ? user.info.Cliente.logo : "arusLogo.png"}`}
                  height="auto"
                  width="100%"
                  className="sidenav-close"
                />
                {/* <img className="circle" src="/images/avatar/avatar-7.png" /> */}
              </Link>
              <Link to="#">
                <span className="color_texto name">
                  {user.name}
                  {"  "}
                  {`(${user.role})`}
                </span>
              </Link>
              <Link to="#">
                <span className="color_texto email">{user.email}</span>
              </Link>

              <Can
                rules={user.permisos}
                perform="cambioClientePrestador:ver"
                yes={() =>
                  user.info.ClientesAsignados && user.info.ClientesAsignados.length > 0 ? (
                    <Link to="/cambioCliente">
                      <div className="color_texto email sidenav-close contenido">
                        <div>
                          <Icon tiny>edit</Icon> <b>CLIENTE:</b>{" "}
                          {user.info.Cliente.nombreCliente}
                        </div>
                        <div className="contenido">
                          <Icon tiny>edit</Icon> <b>PRESTADOR: </b>
                          {user.info.Prestadore ? (
                            user.info.Prestadore.nombrePrestador
                          ) : (
                            "Ninguno"
                          )}
                        </div>
                      </div>
                    </Link>
                  ) : null
                }
                no={() => null}
              />
            </div>
          </li>
        )}
      </AuthConsumer>
    );
  }
}

export default UserSidebar;
