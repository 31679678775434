import React, { Component } from 'react';
import TablePreradicados from './tablePreradicados.js';
import { DatePicker} from "react-materialize";
import { filtersDB,filtersPaginationDB } from  './filtersDB';
import './css/style.css';
import Swal from 'sweetalert2';
import { downloadEcxel } from './utils';
import {Pagination,limit} from './pagination';
import moment from 'moment';


class FilterPrestador extends Component {
    state={
        filters:{
            nitCliente:0,
            nitPrestador:0,
            numFactura:0,
            alpha:0,
            idPreradicado:0,
            fechaInicio:0,
            fechaFin:0,
            offset:0,
            limit:limit,
            currentPage:1
        },
        dataPreradicado:[],
        totalFacturas:0,
        dataEcxel:[],	
    }

	pagePrevious = async () => {
		await this.setState({
			filters: {
                ...this.state.filters,
                offset: this.state.filters.offset - limit,
				currentPage: this.state.filters.currentPage - 1
			}
        });
        this.getData('viewData');
	}

	pageFallowing = async () => {
		await this.setState({
			filters: {
                ...this.state.filters,
                offset: this.state.filters.offset + limit,
				currentPage: this.state.filters.currentPage + 1
			}
        });
        this.getData('viewData');
	}

    finalPage = totalFacturas => this.state.filters.currentPage === Math.ceil(Number(totalFacturas) / limit);
  
    onChangePag = async page => {
    await this.setState({
        filters: {
            ...this.state.filters,
            offset: page * limit,
            currentPage: page + 1
        }
    });
    this.getData('viewData');
    };

    onChange =   e => this.setState({ filters: { ...this.state.filters, [e.target.name]:e.target.value || 0 } });

    getData = (type) => {
        switch (type) {
            case 'viewData':
                filtersPaginationDB(this.props.usuario,this.state.filters)
                .then( async data => {
                    if(data.rows.length > 0){
                        this.setState({
                            dataPreradicado:data.rows,
                            totalFacturas:data.count
                        })
                    }else{
                        this.setState({
                            filters:{...this.state.filters,currentPage:1,offset:0},
                            dataPreradicado:[]
                        }) 
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: 'No hay datos para su consulta'
                            })
                    }
                })
                .catch(e => console.log('error peticions get:',e));                
                break;
            case 'excel':
                filtersDB(this.props.usuario,this.state.filters)
                .then( async data => {
                    if(data.length > 0){
                        let dataEcxel = [];
                        await dataEcxel.push([
                            'Numero preradicado',
                            'Fecha',
                            'Origen soporte',
                            'Nit prestador',
                            'Numero factura',
                            'Valor factura',
                            'NPBS',
                            'Sucursal'
                        ]);
                        await data.map( dat => {
                            dataEcxel.push([
                                dat.NumeroPreradicado,
                                moment(dat.FechaPreRadicado).format('LLL'),
                                dat.OrigenSoporte,
                                dat.NitPrestador,
                                dat.NumeroFactura,
                                dat.ValorFactura,
                                dat.NPBS ? 1 : 0,
                                dat.SucursalNombre
                            ]);
                            return true;
                        });
                        downloadEcxel(dataEcxel);
                    }
                })
                .catch(e => console.log('error peticions get:',e));
                break;    
        
            default:
                break;
        }
    }

	onSubmit = e => {
        e.preventDefault();
        const {nitCliente,
            nitPrestador,
            numFactura,
            alpha,
            idPreradicado,
            fechaInicio,
            fechaFin} = this.state.filters;
        if(nitCliente === 0 && nitPrestador === 0 && numFactura === 0 && alpha === 0 
            && idPreradicado === 0 && fechaInicio === 0 && fechaFin === 0 ){
            this.setState({dataPreradicado:[]}) 
            Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Poner un filtro minimo'
              }); 
        }else{
            this.getData('viewData');
        }
    
    }

    render() {
        return (
            <div className="">
            <form className="" onSubmit={e => this.onSubmit(e)}>
                    <div className="input-field col l6 s12">
                        <input
                            id="idPreradicado"
                            name="idPreradicado"
                            type="number"
                            className="validate"
                            onChange={(e) =>  this.onChange(e)}
                        />
                        <label htmlFor="idPreradicado">Pre radicado</label>
                    </div>

                    <div className="input-field col l6 s12">
                        <input  
                            id="numFactura"
                            name="numFactura"
                            type="number"
                            className="validate"
                            onChange={(e) =>  this.onChange(e)}
                        />
                        <label htmlFor="numFactura">Numero Factura</label>
                    </div>

                    <div className="input-field col l3 s6">
                        <label htmlFor="fechaInicio">Fecha inicio</label>
                        <DatePicker
                            id="fechaInicio"
                            name="fechaInicio"
                            className="datepicker"
                            options={{autoClose:true}}
                            onChange={e => this.setState({ filters: { ...this.state.filters, fechaInicio:e } })}
                        />
                        
                    </div>
                    <div className="input-field col l3 s6">
                        <label htmlFor="fechaFin">Fecha fin</label>
                        <DatePicker
                                id="fechaFin"
                                name="fechaFin"
                                className="datepicker"
                                options={{autoClose:true}}
                                onChange={ e => this.setState({ filters: { ...this.state.filters, fechaFin:e } })}
                            />
                    </div>

                    <div className="input-field col l6 s12">
                        <button className="btn btn_form_repopreradicado" type="submit" name="action">Enviar
                            <i className="material-icons right">send</i>
                        </button>
                        {this.state.dataPreradicado.length > 0 
                            ? <button 
                                className="btn btn_form_repopreradicado"
                                type="button"
                                onClick={ () =>  this.getData('excel')}
                                >Descargar
                                <i className="material-icons right">get_app</i>
                            </button>
                            :''
                        }
                    </div>
            </form>
            <div className="col s12">
                <TablePreradicados dataPreradicado={this.state.dataPreradicado} /> 
                {
                    this.state.dataPreradicado.length > 0 
                        ? <Pagination
                            currentPage={this.state.filters.currentPage}
                            previousPage={this.pagePrevious}
                            followingPage={this.pageFallowing}
                            onChangePag={this.onChangePag}
                            finalPages={this.finalPage(this.state.totalFacturas || 0)}
                            totalPages={Math.ceil(Number(this.state.totalFacturas || 0) / limit)}
                        />
                        : ''
                }

            </div>
        </div>
        );
    };
};
export default FilterPrestador;