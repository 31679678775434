import React, { Component } from "react";
import { Button, Col } from "react-materialize";

//SweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class BotonAyuda extends Component {
  state = {};

  onClickAyuda = () => {
    MySwal.fire(this.props.info.titulo, this.props.info.texto, "question");

    let width = this.props.info.ancho ? this.props.info.ancho : null;

    Swal.fire({
      title: this.props.info.titulo,
      type: "question",
      html: this.props.info.texto,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#002c55",
      width: width
    });
  };
  componentDidMount = () => {
    this.onClickAyuda();
  }
  render() {
    return (
      <React.Fragment>
        <Col className="text-align-right" m={12} s={12}>
          Información importante a tener en cuenta antes del envío de información{"  "}
          <Button
            floating
            className="btn-color-arus"
            onClick={() => this.onClickAyuda()}
          >
            <i class="material-icons">live_help</i>
          </Button>
        </Col>
      </React.Fragment>
    );
  }
}

export default BotonAyuda;
