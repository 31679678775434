import { MOSTRAR_LINEAMIENTO, ELIMINAR_LINEAMIENTO, AGREGAR_LINEAMIENTO, MOSTRAR_LINEAMIENTOS, EDITAR_LINEAMIENTO } from '../actions/types';

const initialState = {
    lineamientos:[]
}

export default function(state = initialState, action){
    switch(action.type){
        case MOSTRAR_LINEAMIENTOS:
            return{
                ...state,
                lineamientos: action.payload
            }
        case MOSTRAR_LINEAMIENTO:
            return{
                ...state,
                lineamiento: action.payload
            }
        case EDITAR_LINEAMIENTO:
                    return{
                        ...state,
                        lineamientos: state.lineamientos.map(
                            lineamiento => lineamiento.idLineamientos === action.payload.idLineamientos 
                            ? (lineamiento = action.payload) 
                            : lineamiento
                        )
                    }
        case ELIMINAR_LINEAMIENTO:
                return{
                    ...state,
                    lineamientos: state.lineamientos.filter(lineamiento => lineamiento.idLineamientos !== action.payload)
                }
        case AGREGAR_LINEAMIENTO:
                return{
                    ...state,
                    lineamientos: [...state.lineamientos, action.payload]
                }
        default:
            return state
    }
}

