import React from "react";
import {Redirect} from "react-router-dom";

import {AuthConsumer} from "../ContexApi/authContext";

const CallbackSanitas = props => (
  <AuthConsumer>
    {({handleAuthenticationSanitas}) => {
      console.log(props);
      if (/access_token|id_token|error/.test(props.location.hash)) {
        handleAuthenticationSanitas();
      }
      return <Redirect to="/"/>;
    }}
  </AuthConsumer>
);

export default CallbackSanitas;