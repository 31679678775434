import React, { useState, useEffect } from "react";
import {
  Preloader
} from "react-materialize";
import Cookies from "js-cookie";
import axios from "axios";
import { API_AUTH_SURA_SEUS4_SSO, URL_DOMAIN_COOKIE, API_AUTH_SURA_LOGIN_SSO } from "constServicios.js";
import "./SuraAuthentication.css";
//SweetAlert 2
import Swal from "sweetalert2";
import queryString from 'query-string';

const cookieName = "sura_integration";
const cookieNameSaml = "saml_response";

const SuraAuthentication = props => {
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});
  // const { setAuthSura, logout } = props;
  const { setAuthSura } = props;

  async function handleError() {
    await Swal.fire({
      title: "Error!",
      text: message,
      type: "error",
      confirmButtonText: "Aceptar"
    });
    props.history.push("/");
  }

  async function setAuth() {
    await setAuthSura(token, { data: user });
    props.history.push("/inicio");
  }
  useEffect(() => {

    async function authWithBackend(datosDesdePortalSura) {
      try {
        setLoading(true);
        console.log("----------------llegue-----------------")
        let params = queryString.parse(props.location.search)
        if (!params.token && !Cookies.get(cookieNameSaml)) {
          const response = await axios.get(
            API_AUTH_SURA_SEUS4_SSO,
            datosDesdePortalSura,
            {
              timeout: 20000
            }
          );
          console.log(response)
          if (response.data) {
            window.location.replace(response.data);
            console.log("redirecciono")
          }
        }
        if (Cookies.get(cookieNameSaml))
        {
          console.log("------------ultimo ingreso---------------")
          const samlRespon = Cookies.get(cookieNameSaml);
          const response = await axios.post(
            API_AUTH_SURA_LOGIN_SSO,
            {samlResponse:samlRespon,
             datosDesdePortalS:datosDesdePortalSura},
            {
              timeout: 20000
            }
          );
          console.log(response)
          if (response.data) {
            console.log("------------hubo respuesta----------")
            Cookies.remove("idCarga");
            //Cookies.remove(cookieName);
            // document.cookie = `sura_integration=prueba; expires=Fri, 3 Aug 2017 20:47:11 UTC; path=/; domain=${URL_DOMAIN_COOKIE}`;
            setUser(response.data.user);
            setToken(response.data.access_token);
            console.log("has ingresado a la pagina web de arus", )
          }
        }
        // if (params.token) {
        //     //Cookies.remove(cookieName);
        //     const data= jwt_decode(params.token);
        //     // document.cookie = `sura_integration=prueba; expires=Fri, 3 Aug 2017 20:47:11 UTC; path=/; domain=${URL_DOMAIN_COOKIE}`;
        //     setUser(data.user);
        //     setToken(data.access_token);
        //     console.log("has ingresado a la pagina web de arus",)
        // }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setMessage(error.response.data.message);
          setOpenModal(true);
        } else {
          setMessage("Ocurrio un error al intentear autenticar contra Portal");
          setOpenModal(true);
        }
      }
    }
    let datosDesdePortalSuraFront = readCookie(cookieName);
    if (!datosDesdePortalSuraFront){
      const datosDesdePortalSuraBack = Cookies.get(cookieName);
      document.cookie = `sura_integration=${datosDesdePortalSuraBack};SameSite=None; Secure;expires=${new Date(Date.now() + 90000000)}`;
      datosDesdePortalSuraFront = readCookie(cookieName);
    } 
    if (typeof datosDesdePortalSuraFront === "undefined") {
      setMessage("Data de integración no encontrada.");
      setOpenModal(true);
    } else {
      try {
        // Cookies.remove(cookieName);
        authWithBackend(datosDesdePortalSuraFront);
      } catch (error) {
        setMessage("Ocurrió un error al parsear datos.");
        setOpenModal(true);
      }
    }
  }, [setOpenModal, setMessage, setUser]);

  function readCookie(name) {

    var nameEQ = name + "="; 
    var ca = document.cookie.split(';');
  
    for(var i=0;i < ca.length;i++) {
  
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) {
        return decodeURIComponent( c.substring(nameEQ.length,c.length) );
      }
  
    }
  
    return null;
  
  }
  if (openModal) {
    handleError();
  }
  if (token && loading) {
    setLoading(false);
    setAuth();
  }
  return (
    <div className="center-align">
      {loading ? <Preloader size="big" /> : null}
    </div>
  );
};
export default React.memo(SuraAuthentication);
