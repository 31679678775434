// export default Header;
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import UserSidebar from "./Sidebar/UserSidebar";
import OpcSidebar from "./Sidebar/OpcSidebar";
import M from "materialize-css";
import "./css/Header.css";
import { AuthConsumer } from "../../ContexApi/authContext";
import User from "./User";
import Login from "./Login";
import { Button, Icon, Dropdown, Divider } from "react-materialize";
import { constServicios } from "../../constServicios";
import Axios from "axios";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom"
import moment from "moment";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noneSideButton: false,
      redirigir: false,
      pintarMenu: true
    };
  }
  static contextType = AuthConsumer;

  componentWillMount = () => { };

  componentDidMount = () => {
    M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"));
  };

  closeSideNav = () => {
    return {
      closeOnClick: true
    };
  };

  renderSide = auth => {
    if (!auth) return null;

    return (
      <div className="row">
        <div className="container">
          <ul id="slide-out" className="sidenav">
            <UserSidebar />
            <OpcSidebar />
          </ul>
        </div>
      </div>
    );
  };

  navTop = () => (
    <div className="nav_top">
      <Button className="btn_menu" waves="light"><i class="material-icons">menu</i></Button>
      <Link to="/">
        <img
          src="/images/logo/logo-arus2.png"
          alt=""
          height="200px"
          width="auto"
        />
      </Link>
      <Dropdown
        trigger={
          <Button className="btn_user_session">
            <Icon className="user_session" small>
              account_circle
            </Icon>
          </Button>
        }
      >
        <Divider />
        <Link to="#">Perfil</Link>
        <Divider />
        <Link to="#">cerrar session</Link>
      </Dropdown>
      <Icon className="user_notification" small>
        notifications
      </Icon>
    </div>
  );
  validarPrestadorActualizado = async (user, accessToken) => {
    try {
      if (user.role === "prestadoresSura") {
        const response = await Axios({
          method: "GET",
          url: `${constServicios.url}${constServicios.getPrestador}?idPrestador=${user.info.Prestadore.idPrestador}`,
          headers: {
            Authorization: accessToken,
          },
        });
        if (response.status === 200) {
          // if (Number(response.data.actualizado) === Number(0)) {
          //   const menu = document.getElementById("slide-out");
          //   const fondoMenu = document.getElementsByClassName("sidenav-overlay");
          //   menu.style.display = 'none';
          //   fondoMenu[0].style.display = 'none';
          //   await Swal.fire({
          //     icon: 'info',
          //     title: 'Debe actualizar prestador.',
          //     confirmButtonText: "Aceptar"
          //   })
          //   this.setState({ redirigir: true })
          // } else {
          //   const i = moment(response.data.fechaActualizacion);
          //   const f = moment();
          //   const diferencia = f.diff(i, "days");
          //   if (diferencia > Number(response.data.diasValidacion)) {
          //     const menu = document.getElementById("slide-out");
          //     const fondoMenu = document.getElementsByClassName("sidenav-overlay");
          //     menu.style.display = 'none';
          //     fondoMenu[0].style.display = 'none';
          //     await Swal.fire({
          //       icon: 'info',
          //       title: 'Debe actualizar prestador.',
          //       confirmButtonText: "Aceptar"
          //     })
          //     this.setState({ redirigir: true })
          //   } else {
              const menu = document.getElementById("slide-out");
              const fondoMenu = document.getElementsByClassName("sidenav-overlay");
              menu.style.display = 'block';
              fondoMenu[0].style.display = 'block'
            // }
          // }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    if (this.state.redirigir) {
      this.setState({ redirigir: false })
      return <Fragment>
        <AuthConsumer>
          {({ authenticated, user, accessToken }) => (
            <React.Fragment>
              <div className="navbar-fixed nav_top">
                <Link
                  to={"#"}
                  data-target="slide-out"
                  className="sidenav-trigger"
                  onClick={() => {
                    this.validarPrestadorActualizado(user, accessToken);
                  }}
                >
                  {authenticated ? (
                    <i id="i_menu" className="material-icons">
                      menu
                    </i>
                  ) : null}
                </Link>
                <div className="">
                  <Link to="/" className="">
                    <img
                      src={`/images/logoCliente/${user.info ? user.info.Cliente.logoMenu : "arusLogo.png"
                        }`}
                      className={`${user.info ? user.info.Cliente.logoMenuCss : "nav_logo"
                        }`}
                      alt={`${user.info ? user.info.Cliente.logoMenu : "arusLogo.png"
                        }`}
                    />
                  </Link>
                </div>
                <nav className="valign">
                  <div className="nav-wrapper">
                    <h4 className="brand-logo center hide-on-med-and-down valign-wrapper">
                      <strong>Portal Soluciones Inteligentes</strong>
                    </h4>

                    <ul id="nav-mobile" className="right">
                      <div className="profile_container">
                        {authenticated ? <User /> : <Login />}
                      </div>
                    </ul>
                  </div>
                </nav>
              </div>

              {this.renderSide(authenticated)}
            </React.Fragment>
          )}
        </AuthConsumer>
        <Redirect to={"/actualizarInformacionPrestador"} />
      </Fragment>
    } else {
      return (
        <AuthConsumer>
          {({ authenticated, user, accessToken }) => (
            <React.Fragment>
              <div className="navbar-fixed nav_top">
                <Link
                  to={"#"}
                  id="idMenu"
                  data-target="slide-out"
                  className="sidenav-trigger"
                  onClick={() => {
                    this.validarPrestadorActualizado(user, accessToken);
                  }}
                >
                  {authenticated ? (
                    <i id="i_menu" className="material-icons">
                      menu
                    </i>
                  ) : null}
                </Link>
                <div className="">
                  <Link to="/" className="">
                    <img
                      src={`/images/logoCliente/${user.info ? user.info.Cliente.logoMenu : "arusLogo.png"
                        }`}
                      className={`${user.info ? user.info.Cliente.logoMenuCss : "nav_logo"
                        }`}
                      alt={`${user.info ? user.info.Cliente.logoMenu : "arusLogo.png"
                        }`}
                    />
                  </Link>
                </div>
                <nav className="valign">
                  <div className="nav-wrapper">
                    <h4 className="brand-logo center hide-on-med-and-down valign-wrapper">
                      <strong>Portal Soluciones Inteligentes</strong>
                    </h4>

                    <ul id="nav-mobile" className="right">
                      <div className="profile_container">
                        {authenticated ? <User /> : <Login />}
                      </div>
                    </ul>
                  </div>
                </nav>
              </div>

              {this.renderSide(authenticated)}
            </React.Fragment>
          )}
        </AuthConsumer>
      );
    }
  }
}

export default Header;
