import React, { useState, useEffect } from "react";
import {
  Preloader
} from "react-materialize";
import Cookies from "js-cookie";
import axios from "axios";
import { API_AUTH_SANITAS, URL_DOMAIN_COOKIE } from "constServicios.js";
import "./SanitasAuthentication.css";
//SweetAlert 2
import Swal from "sweetalert2";
import qs from "qs";
import aes256 from 'aes256'

const cookieName = "sanitas_integration";

const SanitasAuthentication = props => {
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});
  // const { setAuthSanitas, logout } = props;
  const { setAuthSanitas } = props;

  async function handleError() {
    await Swal.fire({
      title: "Error!",
      text: message,
      type: "error",
      confirmButtonText: "Aceptar"
    });
    props.history.push("/");
  }

  async function setAuth() {
    await setAuthSanitas(token, { data: user });
    props.history.push("/inicio");
  }
  useEffect(() => {

    async function authWithBackend(datosDesdePortalSanitasJson) {
      try {
        setLoading(true);
        const response = await axios.post(
          API_AUTH_SANITAS,
          datosDesdePortalSanitasJson,
          {
            timeout: 20000,
            headers: {
              authorization: datosDesdePortalSanitasJson.access_token
            }
          }
        );
        if (typeof response.data.access_token != "undefined") {
          // Cookies.remove(cookieName);
          document.cookie = `sanitas_integration=prueba; expires=Fri, 3 Aug 2017 20:47:11 UTC; path=/; domain=${URL_DOMAIN_COOKIE}`;
          setUser(response.data.user);
          setToken(response.data.access_token);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setMessage(error.response.data.message);
          setOpenModal(true);
        } else {
          setMessage("Ocurrio un error al intentear autenticar contra Portal");
          setOpenModal(true);
        }
      }
    }
    const key = 'AT>DCPS,J3g6Rm<#eZ/bdUpN5';
    const datosDesdePortalSanitas = aes256.decrypt(key, Cookies.get(cookieName));

    if (typeof datosDesdePortalSanitas === "undefined") {
      setMessage("Data de integración no encontrada.");
      setOpenModal(true);
    } else {
      try {
        const datosDesdePortalSanitasJson = qs.parse(datosDesdePortalSanitas);
        // Cookies.remove(cookieName);
        document.cookie = `sanitas_integration=prueba; expires=Fri, 3 Aug 2017 20:47:11 UTC; path=/; domain=${URL_DOMAIN_COOKIE}`;
        authWithBackend(datosDesdePortalSanitasJson);
      } catch (error) {
        setMessage("Ocurrió un error al parsear datos.");
        console.log(error);
        
        setOpenModal(true);
      }
    }
  }, [setOpenModal, setMessage, setUser]);

  if (openModal) {
    handleError();
  }
  if (token && loading) {
    setLoading(false);
    setAuth();
  }
  return (
    <div className="center-align">
      {loading ? <Preloader size="big" /> : null}
    </div>
  );
};
export default React.memo(SanitasAuthentication);
