import React, { Component } from "react";
import { AuthConsumer } from "../../ContexApi/authContext";
import { AUTH_CONFIG } from "../../Variables/auth0-variables";
import axios from "axios";
import Cookies from "js-cookie";

class Logout extends Component {
  state = {};
  componentDidMount() {
    document.getElementById("formlogout").submit();
    // const { accessToken, user } = this.context;
  }

  render() {
    return (
      <AuthConsumer>
        {({ accessToken }) => {
          Cookies.remove("idCarga")
          if (accessToken == "") {
            accessToken =
              this.props.location.search !== undefined
                ? this.props.location.search.replace("?token=", "")
                : "";
          }
          return (
            <React.Fragment>
              <form
                method="post"
                action={`${AUTH_CONFIG.logoutUrl}`}
                id="formlogout"
              >
                <input
                  type="hidden"
                  name="id_token_hint"
                  value={`${accessToken}`}
                />
                <input
                  type="hidden"
                  name="post_logout_redirect_uri"
                  value={`${AUTH_CONFIG.callbacklogoutUrl}`}
                />
                <input type="hidden" name="state" value={`${accessToken}`} />
              </form>
            </React.Fragment>
          );
        }}
      </AuthConsumer>
    );
  }
}

Logout.contextType = AuthConsumer;

export default Logout;
