import React, { Component } from "react";
import { constServicios } from "../../constServicios";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../ContexApi/authContext";
import Axios from "axios";

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

class Ciudades extends Component {
  constructor(props) {
    super();
    this.state = {
      token: "",
      lista: [],
      tipoSeleccionado: props.idCiudad
    };
  }

  componentDidMount() {
    var ciudadNew;
    var ciudadNew2 = {
      idCiudad: 0,
      nombreCiudad: "Seleccione Ciudad.."
    };
    try {
      //e.preventDefault();

      const { accessToken } = this.context;

      this.setState({ token: accessToken });

      Axios.get(`${constServicios.url}${constServicios.urlCiudades}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken
        }
      })
        .then(resp => {
          if (resp.status === 200) {
            ciudadNew = resp.data.reverse();
            ciudadNew.push(ciudadNew2);
            ciudadNew = ciudadNew.reverse();
            this.setState({
              lista: ciudadNew //resp.data
            });
            //asigno el valor por defecto
            if (
              this.props.idCiudad &&
              this.props.referencia === true &&
              this.props.idCiudad
            ) {
              this.props.changeTipoDoc(this.props.idCiudad);
            } else {
              this.props.changeTipoDoc(resp.data[0].idCiudad);
            }
          } else {
            alert("Estado inesperado en consulta de Sedes. Intente Nuevamente");
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch");
    }
  }

  onHandleChange(event) {
    if (event.target.value === "0") return;
    this.setState({
      tipoSeleccionado: event.target.value
    });
    sleep(500).then(() => {
      this.props.changeTipoDoc(this.state.tipoSeleccionado);
    });
  }

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <React.Fragment>
              <div>
                <select
                  id="sciudad"
                  name="sciudad"
                  onChange={event => this.onHandleChange(event)}
                  className="browser-default"
                  value={this.state.tipoSeleccionado}
                >
                  {this.state.lista.map(lists => (
                    <option key={lists.idCiudad} value={lists.idCiudad}>
                      {lists.nombreCiudad}
                    </option>
                  ))}
                </select>
              </div>
            </React.Fragment>
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

Ciudades.contextType = AuthConsumer;
export default Ciudades;
