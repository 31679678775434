import React, { Component, Fragment } from "react";
import PreRadicado from "../PreRadicado/PreRadicado";
import { Tabs, Tab } from "react-materialize";
import MostrarGuias from "../AsociarGuias/MostrarGuias";

//Auth
import { AuthConsumer } from "../../ContexApi/authContext";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";

class MenuPreRadicado extends Component {
  state = {};
  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Fragment>
              <div className="general_components">
                <Can
                  rules={user.permisos}
                  perform="preradicados:ver"
                  yes={() => (
                    <Fragment>
                      <Tabs className="tab-demo z-depth-1">
                        <Tab title="Pre-Radicado" active>
                          <PreRadicado />
                        </Tab>
                        <Tab title="Asociar Guías">
                          <MostrarGuias />
                        </Tab>
                      </Tabs>
                    </Fragment>
                  )}
                  no={() => <Redirect to="/inicio" />}
                />
              </div>
            </Fragment>
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

export default MenuPreRadicado;
