import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class plantilla_componente extends Component {
    

    componentWillMount = () => {

    };

    componentDidMount = () => {

    };

    render() {
        return (
            <div className="general_components center-align">
                <h1 className="">Página no encontrada 404</h1>
                <Link to="/">REGRESAR</Link>
            </div>
        );
    };
};
export default plantilla_componente;