import React, { Component } from "react";
import { constServicios } from "../../constServicios";
import Axios from "axios";
import "../AdminUsuarios/css/AdminUsuarios.css";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import "../AdminUsuarios/css/AdminUsuarios.css";
import withReactContent from "sweetalert2-react-content";
import Loader from "../Utils/Loader";

const MySwal = withReactContent(Swal);

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class PermisosModulos extends Component {
  constructor(props) {
    super();
    this.state = {
      token: props.token,
      idModulo: props.idModulo,
      permisos: [],
      modulos: [],
      permisosAsignados: [],
      moduloChecked: props.moduloChecked,
      showPerm: true,
      loader: false,
      seleccionados: [],
      todos: false
    };
  }

  async consultaPermisoRol() {
    try {
      await Axios.get(
        `${constServicios.url}${constServicios.urlPermisosRol}${this.props.rolId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.state.token
          }
        }
      )
        .then(resp => {
          if (resp.status === 200) {
            this.setState({
              permisosAsignados: resp.data
            });

            //asigno el valor por defecto
          } else {
            alert(
              "Ocurrio un error recuperando los Permisos por Modulo, Por favor intente de nuevo."
            );
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch" + e);
    }
  }
  async consultaPermisos() {
    this.setState({ loader: true });

    await this.consultaPermisoRol();
    //Permisos en general que existen por modulo elegido
    try {
      await Axios.get(
        `${constServicios.url}${constServicios.urlPermisosModulo}${this.props.idModulo}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.state.token
          }
        }
      )
        .then(resp => {
          if (resp.status === 200) {
            this.setState({
              permisos: this.newLista(resp.data),
              showPerm: true,
              loader: false
            });

            // alert("serv biensd" + JSON.stringify(this.state.permisos));
          } else {
            alert(
              "Ocurrio un error recuperando los Permisos x Modulo, Por favor intente de nuevo."
            );
          }
          this.setState({
            showPerm: true,
            loader: false
          });
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch" + e);
      this.setState({
        showPerm: true,
        loader: false
      });
    }
  }
  componentDidMount() {
    this.consultaPermisos();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rolId !== this.props.rolId) {
      this.consultaPermisos();
    }
  }

  arrayPermisos = idPermiso => {
    return {
      idPermiso: idPermiso,
      idRol: this.props.rolId,
      idEstado: 1
    };
  };

  crear = async () => {
    var arrMain, arra2;

    this.setState({
      showPerm: false,
      loader: false
    });

    var arrP = [];

    this.state.seleccionados.map(d => {
      arrP.push(this.arrayPermisos(d.idPermiso));
    });

    await Axios.post(
      `${constServicios.url}${constServicios.urlPermisosRol}`,
      arrP,
      {
        headers: {
          Authorization: this.state.token
        }
      }
    )
      .then(res => {
        if (res) {
          Toast.fire({
            title: "Permiso Creado Exitosamente"
          });
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Error", this.state.persons);
        }
      });
    this.consultaPermisos();
  };

  eliminar = async () => {
    this.setState({
      showPerm: false,
      loader: true
    });
    var arrp = [];

    this.state.seleccionados.map(d => {
      arrp.push(this.arrayPermisos(d.idPermiso));
    });

    await Axios.delete(
      `${constServicios.url}${constServicios.urlPermisosRol}`,
      {
        data: arrp,
        headers: {
          Authorization: this.state.token
        }
      }
    )
      .then(res => {
        if (res.status === 200) {
          Toast.fire({
            title: "Permiso Eliminado Exitosamente"
          });
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Error", this.state.persons);
        }
      });

    this.consultaPermisos();
  };

  actualizar = async (idPermisoRol, activa) => {
    this.setState({
      showPerm: false,
      loader: true
    });
    await Axios.put(
      `${constServicios.url}${constServicios.urlPermisosRol}${idPermisoRol}`,
      {
        EstadoIdEstado: activa === false ? 2 : 1 // activar o desactivar leer el estado o checked
      },
      {
        headers: {
          Authorization: this.state.token
        }
      }
    )
      .then(res => {
        if (res) {
          Toast.fire({
            title: "Permiso Actualizado Exitosamente"
          });
        }

        this.consultaPermisos();
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Error", this.state.persons);
        }
      });
  };

  newLista(arrOld) {
    var arrOriginal = [];
    var permisoActivo = [];
    var permisoRol;
    var selecion = false;
    var estado;
    var count = 0;
    //si todos los permisos estan llenos todos es true
    //Filtro de los permisos seleccionados por rol solo los de este modulo

    var arrSeleccionados = this.state.permisosAsignados.filter(
      data =>
        String(data.Permiso.ModuloIdModulo) === String(this.state.idModulo)
    );

    arrOriginal = arrOld.map(d => {
      var permisoMod = arrSeleccionados.filter(
        data => String(data.PermisoIdPermiso) === String(d.idPermiso)
      );
      if (permisoMod.length > 0) {
        selecion = true;
        estado = permisoMod[0].EstadoIdEstado;
        permisoRol = permisoMod[0].idPermisoRol;
      } else {
        selecion = false;
        estado = permisoRol = 0;
      }

      return {
        idPermiso: d.idPermiso,
        nombre: d.nombre,
        descripcion: d.descripcion,
        seleccion: selecion,
        estado: estado,
        permisoRol: permisoRol
      };
    });

    if (arrSeleccionados.length === arrOriginal.length) {
      this.setState({
        todos: true
      });
    }
    return arrOriginal;
  }

  limpiar = async e => {};

  async seleccionarTodos(check) {
    var arrP = [];

    this.state.permisos.map(d => {
      //si voy a crear no debo volver a crear los ya marcados
      //si voy a eliminar just o elimino los que si encuentre
      var permisoMod = this.state.permisosAsignados.filter(
        data => String(data.PermisoIdPermiso) === String(d.idPermiso)
      );
      if (permisoMod.length === 0 && check === true)
        arrP.push(this.arrayPermisos(d.idPermiso)); //no se encuentra ya asignado
      if (permisoMod.length > 0 && check === false)
        arrP.push(this.arrayPermisos(d.idPermiso)); //no se encuentra ya asignado
    });
    this.setState({
      seleccionados: arrP,
      todos: check
    });

    //tomar el permiso por modulo y agregarlo ttodo al state seleccionados con un map.
    //se debe excluir los que ya estan seleccionados
  }

  async seleccionPermiso(event, dataPermiso) {
    //event.preventDefault();
    var origin = event.target.name.substr(0, 2); //sw -> switch , se -> chechbox, to->todos
    let checked = event.target.checked;
    var bnd = false;
    var sel = [];
    this.setState({
      seleccionados: []
    });

    await Swal.fire({
      title:
        checked === true
          ? origin === "sw"
            ? "Desea Activar el permiso?" // true y activar
            : origin === "se"
            ? "Desea Agregar el permiso?" //true y selecc
            : " Está seguro que desea agregar todos los permisos?"
          : origin === "sw"
          ? "Desea Desactivar el permiso?"
          : origin === "se"
          ? "Desea Eliminar el permiso?"
          : " Está seguro que desea eliminar todos los permisos?",
      //text: "Se modificara el permiso del Rol " + this.props.rolname,
      type: "info",
      showCancelButton: true,
      cancelButtonColor: "gray",
      confirmButtonText: "Si",
      confirmButtonColor: '#002c55',
    }).then(result => {
      if (result.value) {
        //LLenamos el state seleccionados de acuerdo a si son todos o uno
        if (origin === "to") {
          this.seleccionarTodos(checked);
        } else {
          sel[0] = this.arrayPermisos(dataPermiso.idPermiso);
          this.setState({
            seleccionados: sel
          });
        }

        if (!(checked === true && origin === "se") && !(origin === "to")) {
          // no se filtra si se trata de un nuevo permiso
          var arrSeleccionado = this.state.permisosAsignados.filter(
            data =>
              String(data.PermisoIdPermiso) === String(dataPermiso.idPermiso)
          );
        }

        origin === "sw"
          ? this.actualizar(arrSeleccionado[0].idPermisoRol, checked)
          : checked === true
          ? this.crear()
          : this.eliminar(); // e implementacion del controlador falta el filtro hasta aca en eliminar todos

        bnd = false;
      } else {
        event.preventDefault();

        return;
      }
    });

    if (bnd === true) {
      let permisosChecked;

      //cuando elija a falso lo debo buscar en los asignados por idPErmiso con find para obetener el idpermiso
      permisosChecked = this.state.permisos.map(d => {
        return {
          idPermiso: d.idPermiso,
          nombre: d.nombre,
          descripcion: d.descripcion,
          seleccion:
            d.idPermiso === dataPermiso.idPermiso ? checked : d.seleccion,
          estado: d.estado,
          idPermisoRol: d.idPermisoRol
        };
      });
      this.setState({
        permisos: permisosChecked // de permisos
      });
    }
  }
  render() {
    return (
      <TableRow>
        {this.state.loader === true && !this.state.showPerm ? (
          <div>
            <Loader />
          </div>
        ) : null}
        {this.state.showPerm ? (
          <TableCell colSpan={3}>
            {this.state.loader === true ? (
              <div>
                <Loader />
              </div>
            ) : null}
            <p key="ptodos">
              <label key="ltodos">
                <input
                  key="todos"
                  id="todos"
                  name="todos"
                  className="filled-in"
                  type="checkbox"
                  checked={this.state.todos}
                  onChange={event => this.seleccionPermiso(event, 0)}
                />
                <span className="thick">Seleccionar Todos</span>
              </label>
            </p>
            {this.state.permisos.map((data, key) => (
              <p key={`p${data.idPermiso}`}>
                <label key={`lab${data.idPermiso}`}>
                  <input
                    key={`sel${data.idPermiso}`}
                    id={`sel${data.idPermiso}`}
                    name={`sel${data.idPermiso}`}
                    className="with-gap"
                    type="checkbox"
                    checked={data.seleccion}
                    onChange={event => this.seleccionPermiso(event, data)}
                  />
                  <span>
                    {data.descripcion ? data.descripcion : data.nombre}
                  </span>
                </label>
                <a
                  className="hover "
                  onClick={() => {
                    this.actualizar(data.permisoRol, true);
                  }}
                >
                  {" "}
                  <u>
                    {data.estado > 0
                      ? data.estado === 2
                        ? "(Inactivo)"
                        : null
                      : null}{" "}
                  </u>
                </a>
                <div class="switch">
                  {data.seleccion ? (
                    <label className="right" key={`lab${data.idPermiso}`}>
                      Off
                      <input
                        key={`sw${data.idPermiso}`}
                        id={`sw${data.idPermiso}`}
                        name={`sw${data.idPermiso}`}
                        type="checkbox"
                        checked={data.estado === 1 ? true : false}
                        onChange={event => this.seleccionPermiso(event, data)}
                      />
                      <span class="lever"></span>
                      On
                    </label>
                  ) : null}
                </div>
              </p>
            ))}
          </TableCell>
        ) : null}
      </TableRow>
    );
  }
}
export default PermisosModulos;
