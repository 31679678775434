export const constLineamientos = {
    columId: "Id",
    columDescripcion: "Descripción",
    columTipoLineamiento: "Tipo Lineamiento",
    columEstado: "Opción de Vigencia",
    columFechaCreacion: "Fecha Creación",
    columFechaAplica: "Fecha Aplicación",
    columPath: "Archivo",
    columFechaActualizacion: "Fecha Actualizado",
    columAcciones: "Acciones",
    mensajeErrorTipo: "Debe seleccionar un tipo de lineamiento",
    mensajeErrorFecha: "Debe ingresar una fecha de aplicación",
    mensajeErrorDescripcion: "Debe ingresar una descripción",
    mensajeErrorDescripcionCaracteres: "La descripción debe contener menos de 255 letras",
    lbSelectTipo: "Tipo lineamiento",
    valueDefaultSelectTipo: "Seleccione un tipo",
    lbDtFechaaplicacion: "Fecha Aplicacion",
    btArchivo: "Archivo",
    swEstadoOff: "Inactivo",
    swEstadoOn: "Activo",
    lbTextAreaDescripcion: "Descripción",
    btEnviar: "Enviar",
    limpiar: "limpiar",
    nombreUsuario: 'Usuario',
    consultar: 'Consultar',
    FechaInicio: 'Fecha Inicio',
    FechaFin: 'Fecha Fin',
    exportar: 'Exportar Resultados',
    mensajeErrorImagen: 'Archivo no encontrado'
}