import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Collection,
  CollectionItem,
  Breadcrumb,
  ProgressBar,
  Button
} from "react-materialize";

import { Link } from "react-router-dom";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./css/Lineamientos.css";
import axios from "axios";
import { constServicios } from "../../constServicios";

//DatePicker
import "react-datepicker/dist/react-datepicker.css";
import { AuthConsumer } from "ContexApi/authContext";
import Can from "../../ContexApi/Can";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import { constLineamientos } from "./constLineamientos";

const MySwal = withReactContent(Swal);

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class CrearLineamiento extends Component {
  state = {
    tipo: "-1",
    fechaAplicacion: "",
    estado: false,
    descripcion: "",
    lineamiento: {},
    file: "",
    cargando: true,
    query: {}
  };

  componentDidMount() {
    this.cargarLineamiento();
    this.setState({ query: queryString.parse(this.props.location.search) });
  }

  cargarLineamiento = async () => {
    const { accessToken, user } = this.context;
    try {
      const respuesta = await axios.get(
        `${constServicios.url}${constServicios.urlLineamientos}/${this.props.match.params.id}/${user.info.Cliente.idCliente}`,
        {
          headers: {
            Authorization: accessToken
          }
        }
      );
      if (respuesta.status === 200) {
        this.setState({
          lineamiento: respuesta.data,
          cargando: false
        });
      } else {
        this.props.history.push("/lineamientos");
        MySwal.fire({
          title: 'Error al consultar el lineamiento.',
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#002c55"
        });
      }
    } catch (error) {
      this.props.history.push("/");
    }
  };

  descarga = async (idLineamiento, archivo) => {
    const { accessToken, user } = this.context;
    archivo = archivo.replace(
      `${constServicios.UPLOAD_FILE_PATH_OUT_LINEAMIENTOS}`,
      ""
    );

    axios
      .get(
        `${constServicios.url}${constServicios.urlLineamientosDescargarArchivo}${idLineamiento}/${user.info.Cliente.idCliente}`,
        {
          headers: { Authorization: accessToken },
          responseType: "blob" //IMPORTANTE
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", archivo);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        Toast.fire({
          type: "error",
          title: `${constLineamientos.mensajeErrorImagen}`
        });
      });
  };

  render() {
    const {
      idLineamientos,
      path,
      descripcion,
      createdAt,
      fechaAplicacion,
      Estado,
      TiposLineamiento,
      Usuario
    } = this.state.lineamiento;
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Can
              rules={user.permisos}
              perform="lineamientos:ver"
              yes={() =>
                !this.state.cargando ? (
                  <Fragment>
                    <div className="general_components">
                      <div className="card-panel">
                        <Row>
                          <Col m={12}>
                            <Breadcrumb className="btn-color-arus" cols={12}>
                              <Link
                                to={
                                  this.state.query.reporte
                                    ? `/reportes/lineamientosOperativos`
                                    : `/lineamientos`
                                }
                              >
                                {this.state.query.reporte
                                  ? `Volver a Reporte Lineamientos`
                                  : `Volver a Lineamientos`}
                              </Link>
                              <a href="#">Ver Lineamiento</a>
                            </Breadcrumb>
                            <Collection header="Datos Lineamiento">
                              <CollectionItem>
                                <b>Tipo de Lineamiento:</b>{" "}
                                {TiposLineamiento
                                  ? TiposLineamiento.nombre
                                  : ""}
                              </CollectionItem>
                              <CollectionItem>
                                <b>Opción de Vigencia:</b>{" "}
                                {Estado ? Estado.nombre : ""}
                              </CollectionItem>
                              <CollectionItem>
                                <b>Fecha de Creación:</b>{" "}
                                {createdAt
                                  ? new Date(createdAt).toLocaleString()
                                  : ""}
                              </CollectionItem>
                              <CollectionItem>
                                <b>Fecha de Aplicación:</b>{" "}
                                {fechaAplicacion
                                  ? new Date(fechaAplicacion).toLocaleString()
                                  : ""}
                              </CollectionItem>
                              <CollectionItem>
                                <b>Usuario:</b>{" "}
                                {Usuario ? Usuario.primerNombre : ""}{" "}
                                {Usuario ? Usuario.primerApellido : ""}
                              </CollectionItem>
                              <CollectionItem>
                                <b>Descripción:</b>{" "}
                                {descripcion ? descripcion : ""}
                              </CollectionItem>
                              {path !== null ? (
                                <CollectionItem>
                                  <b>Descargar archivo: </b>
                                  <Button
                                    onClick={() =>
                                      this.descarga(idLineamientos, path)
                                    }
                                    floating
                                    small
                                    className="btn-color-arus"
                                    disabled={path == null ? true : false}
                                  >
                                    <i class="material-icons">archive</i>
                                  </Button>
                                </CollectionItem>
                              ) : null}
                            </Collection>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Row>
                    <Col s={12}>
                      <ProgressBar
                        className=""
                        color="blue"
                        active={this.state.cargando}
                      />
                    </Col>
                  </Row>
                )
              }
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

CrearLineamiento.contextType = AuthConsumer;

export default CrearLineamiento;
