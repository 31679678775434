import { constServicios } from '../constServicios';

export const AUTH_CONFIG = {
    domain: `${constServicios.urlWSO2}/oauth2`,
    logoutUrl: `https://${constServicios.urlWSO2}/oidc/logout`,
    clientId: `${constServicios.clientId_WSO2}`,
    callbacklogoutUrl: `${constServicios.urlPortal}/callbacklogout`,
    callbackUrl: `${constServicios.urlPortal}/callback`,
    scope: "openid",
    responseType: "token"
};