import React, { Component } from "react";
import { constServicios } from "../../constServicios";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../ContexApi/authContext";
import Axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 5000
});

class Sedes extends Component {
  constructor(props) {
    super();
    this.state = {
      token: "",
      lista: [],
      tipoSeleccionado: props.idSede,
      filtro: props.filtro,
      referencia: props.referencia,
      consultaUsuario: props.consultaUsuario
    };
  }

  componentDidMount() {
    if (this.props.filtro === 0) return;
    try {
      //e.preventDefault();

      const { accessToken } = this.context;

      this.setState({ token: accessToken });
      var path;
      path = `${constServicios.url}${constServicios.urlSedesCity}${this.state.filtro}`;

      Axios.get(path, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken
        }
      })
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data[0]) {
              this.setState({
                lista: resp.data
              });
            } else {
              Toast.fire({
                type: "error",
                title:
                  "La ciudad seleccionada no tiene sede asignada por favor asignela antes de Continuar "
              });
            }
            //asigno el valor por defecto
            if (this.props.referencia === true && this.props.idSede) {
              this.props.changeTipoDoc(this.props.idSede);
              this.setState({
                tipoSeleccionado: this.props.idSede
              });
            } else {
              this.props.changeTipoDoc(resp.data[0].idSede);
              this.setState({
                tipoSeleccionado: this.props.idSede
              });
            }
          } else {
            alert("Estado inesperado en consulta de Sedes. Intente Nuevamente");
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch");
    }
  }

  onHandleChange(event) {
    this.setState({
      tipoSeleccionado: event.target.value
    });
    sleep(500).then(() => {
      this.props.changeTipoDoc(this.state.tipoSeleccionado);
    });
  }

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <React.Fragment>
              <div>
                <select
                  id="ssede"
                  name="ssede"
                  onChange={event => this.onHandleChange(event)}
                  className="browser-default"
                  value={this.state.tipoSeleccionado}
                >
                  {this.state.lista.map(lists => (
                    <option key={lists.idSede} value={lists.idSede}>
                      {lists.descripcion}
                    </option>
                  ))}
                </select>
              </div>
            </React.Fragment>
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

Sedes.contextType = AuthConsumer;
export default Sedes;
