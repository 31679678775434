import React from "react";
import SanitasAuthentication from './SanitasAuthentication';
import { AuthConsumer } from "ContexApi/authContext";

export default props => {
    return (
        <AuthConsumer>
            {({ setAuthSanitas, logout }) => {
                return (<SanitasAuthentication setAuthSanitas={setAuthSanitas} logout={logout} {...props} />)
            }}
        </AuthConsumer>
    )
}

