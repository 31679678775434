import React, { Component, Fragment } from "react";
import { constPRERADICADO } from "./constMediosMagneticos";
import { constServicios } from "../../constServicios";
import "./css/MediosMagneticos.css";
import { Row, Preloader, Col, Tab, Tabs } from "react-materialize";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Axios from "axios";

//Auth
import { AuthConsumer } from "../../ContexApi/authContext";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";

import BotonAyuda from "../herramientas/BotonAyuda";

const MySwal = withReactContent(Swal);

//Barcode
const JsBarcode = require("jsbarcode");

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class MediosMagneticos extends Component {
  state = {
    tipo: "",
    preload: false,
    preRadicado: {}
  };

  constructor() {
    super();
    this.file = React.createRef();
  }

  mensajePreRadicado = resp => {
    this.setState({
      preRadicado: resp
    });

    let mensaje = "";

    if (this.state.tipo === constPRERADICADO.nombreFisico)
      mensaje = constPRERADICADO.msjPreRadicadoFisico;
    else mensaje = constPRERADICADO.msjPreRadicadoDigital;

    const html = `<p>${mensaje}</p>
                  <center>
                    <svg id='barcode' />
                  </center>
                  <br/>
                  <br/>
                  <button id="imprimir" class="waves-effect waves-light btn blue">
                    Imprimir y/o descargar
                  </button>
                  <button id="cerrar" class="waves-effect waves-light btn red" disabled>
                    Cerrar
                  </button>`;

    MySwal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      title: "Pre-Radicado Registrado Exitosamente!",
      html: html,
      onBeforeOpen: () => {
        const content = MySwal.getContent();
        const $ = content.querySelector.bind(content);

        const imprimir = $("#imprimir");
        const cerrar = $("#cerrar");

        imprimir.addEventListener("click", () => {
          this.imprimirCodigo(String(resp.data.idPreradicado));
          cerrar.disabled = false;
        });

        cerrar.addEventListener("click", () => {
          MySwal.close();
        });
        JsBarcode("#barcode", String(resp.data.idPreradicado));
      },
      onClose: () => {
        this.setState({});
      }
    });
  };

  cargarPre = e => {
    try {
      e.preventDefault();
      if (!this.validaciones()) return null;

      this.setState({
        preload: true
      });

      var formData = new FormData();
      formData.append(
        constPRERADICADO.tagArchivo,
        this.file.current.files[0],
        this.file.current.files[0].name
      );

      const data = {
        idTipoRecepcion:
          this.state.tipo === constPRERADICADO.nombreFisico ? 1 : 2,
        idUsuario: 1,
        modulo: "preradicado"
      };

      formData.append(constPRERADICADO.tagTipo, JSON.stringify(data));

      Axios.post(
        `${constServicios.url}${constServicios.urlArchivoPreRadicado}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
        .then(resp => {
          if (resp.status === 200) {
            this.mensajePreRadicado(resp);
          } else {
            MySwal.fire(constPRERADICADO.tituloError, "", "error");
          }
          this.setState({
            preload: false
          });
        })
        .catch(err => {
          this.setState({
            preload: false
          });
          if (!err.response) {
            MySwal.fire(
              constPRERADICADO.tituloError,
              "respuesta sin datos",
              "error"
            );
          } else {
            MySwal.fire(
              constPRERADICADO.tituloError,
              err.response.data.error.message,
              "error"
            );
          }
        });
    } catch (e) {
      MySwal.fire(constPRERADICADO.tituloError, e.message, "error");
    }
  };

  validaciones = () => {
    if (this.file.current.files.length === 0) {
      Toast.fire({
        type: "error",
        title: constPRERADICADO.mensajeErrorArchivo
      });
      return false;
    } else {
      const nameFile = this.file.current.files[0].name;
      const extencion = nameFile.substring(nameFile.lastIndexOf("."));
      if (constPRERADICADO.extencionesPermitidas.indexOf(extencion) < 0) {
        Toast.fire({
          type: "error",
          title: `${constPRERADICADO.mensajeErrorExtenciones}${[
            ...constPRERADICADO.extencionesPermitidas
          ]}`
        });
        return false;
      }
    }

    return true;
  };

  preload = () => {
    if (!this.state.preload) return null;
    return (
      <Row>
        <Col offset="s5" s={2}>
          <Preloader size="big" />
        </Col>
      </Row>
    );
  };

  renderBtnCarga = () => {
    return (
      <button
        className="btn waves-effect waves-light btn-color-arus right-align"
        disabled={this.state.preload}
      >
        {constPRERADICADO.btnCarga}
      </button>
    );
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Can
              rules={user.permisos}
              perform="mediosMagneticos:ver"
              yes={() => (
                <Fragment>
                  <Tabs className="tab-demo z-depth-1">
                    <Tab title="Cargue de Medios Magnéticos">
                      <div className="general_components">
                        <div className="card-panel">
                          <BotonAyuda
                            info={{
                              texto: `Descarga la plantilla en el siguiente enlace: <a href="/files/LibroFormato.xlsx" target="_blank" download className="btn waves-effect waves-light cyan accent-4">Descargar plantilla</a>`,
                              titulo: "Ayuda Cargue Medios Magnéticos"
                            }}
                          />
                          <form onSubmit={this.cargarPre}>
                            <div className="row">
                              <div className="col l8 m8 s12 offset-l2 offset-m2">
                                <div className="file-field input-field">
                                  <div className="btn btn-color-arus">
                                    <span>
                                      {constPRERADICADO.btnArchivoPreradicado}
                                    </span>
                                    <input
                                      accept={
                                        constPRERADICADO.extencionesPermitidas
                                      }
                                      type="file"
                                      ref={this.file}
                                    />
                                  </div>
                                  <div className="file-path-wrapper">
                                    <input
                                      className="file-path validate"
                                      type="text"
                                      placeholder="Arrastrar y soltar..."
                                    />
                                  </div>
                                </div>
                                {this.preload()}
                              </div>
                            </div>

                            <Row>
                              {/* <Col s={12} m={6}>
                                <Link
                                  to="/files/LibroFormato.xlsx"
                                  target="_blank"
                                  download
                                  className="btn btn-color-arus"
                                >
                                  {constPRERADICADO.btnDecargarArchivo}
                                </Link>
                              </Col> */}
                              <Col s={12} m={6} className="right-align">
                                {this.renderBtnCarga()}
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Fragment>
              )}
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

export default MediosMagneticos;
