import React, { Fragment } from "react";
import { AuthConsumer } from "../../../ContexApi/authContext";
import Can from "../../../ContexApi/Can";
import { Redirect } from "react-router-dom";
//Estilos Materialize
import { Tab, Tabs } from "react-materialize";
import FormlogCargaSoportes from "./form";
const logCargaSoportes = () => {
  return (
    <AuthConsumer>
      {({ authenticated, user }) =>
        authenticated ? (
          <Can
          rules={user.permisos}
          perform="reportelogCargaSoportes:ver"
          yes={() => (
            <Fragment>
                <Tabs className="tab-demo z-depth-1">
                  <Tab title="REPORTE ESTADOS FACTURA">
                    <div className="card-panel">
                      <FormlogCargaSoportes  user={user}/>
                    </div>
                  </Tab>
                </Tabs>
              </Fragment>
            )}
            no={() => <Redirect to="/inicio" />}
          />
        ) : (
          <Redirect to="/inicio" />
        )
      }
    </AuthConsumer>
  );
};

export default logCargaSoportes;
