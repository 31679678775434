import React, { Component, Fragment } from "react";

//estilos materialize
import { TextInput, Row, Col, Preloader, Tab, Tabs } from "react-materialize";

import axios from "axios";
import { constServicios } from "constServicios";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthConsumer } from "ContexApi/authContext";
import { Link } from 'react-router-dom';
const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class EditarGuias extends Component {
  constructor() {
    super();
    this.file = React.createRef();
  }

  componentDidMount() {
    try {
      const { accessToken } = this.context;
      axios
        .get(
          `${constServicios.url}${constServicios.urlGuias}${this.props.match.params.id}`,
          {
            headers: {
              Authorization: accessToken
            }
          }
        )
        .then(respuesta => {
          if (respuesta.status === 200) {
            const {
              numeroGuia,
              operadorLogistico,
              PreradicadoIdPreradicado
            } = respuesta.data;

            this.setState({
              numeroGuia,
              operadorLogistico,
              PreradicadoIdPreradicado
            });
          }
        });
    } catch (e) {
      console.log(e.message);
    }
  }

  state = {
    numeroGuia: "",
    operadorLogistico: "",
    PreradicadoIdPreradicado: "",
    btnBuscar: true,
    preLoad: false
  };

  preload = () => {
    if (!this.state.preLoad) return null;
    return (
      <Row>
        <Col offset="s5" s={2}>
          <Preloader size="big" />
        </Col>
      </Row>
    );
  };

  validarForm = () => {
    const { numeroGuia, operadorLogistico } = this.state;

    if (numeroGuia === "") {
      Toast.fire({
        type: "error",
        title: `debe agregar numero de guia`
      });
      return false;
    }

    if (operadorLogistico === "") {
      Toast.fire({
        type: "error",
        title: `debe agregar operador logistico`
      });
      return false;
    }

    return true;
  };

  numeroGuia = e => {
    this.setState({
      numeroGuia: e.target.value
    });
  };

  operadorLogistico = e => {
    this.setState({
      operadorLogistico: e.target.value
    });
  };

  actualizar = e => {
    e.preventDefault();
    if (!this.validarForm()) return null;
    const {
      numeroGuia,
      operadorLogistico,
      PreradicadoIdPreradicado
    } = this.state;

    const guia = {
      idGuia: this.props.match.params.id,
      numeroGuia,
      operadorLogistico,
      idPreradicado: PreradicadoIdPreradicado,
      path: "Path",
      accion: "crear"
    };

    const { accessToken } = this.context;

    try {
      axios
        .put(
          `${constServicios.url}${constServicios.urlGuias}${guia.idGuia}`,
          guia,
          {
            headers: {
              Authorization: accessToken
            }
          }
        )
        .then(respuesta => {
          if (respuesta.status === 200) {
            MySwal.fire(
              "La guia fue editada correctamente.",
              "",
              "success"
            );
          }
        });
    } catch (e) {
      console.log(e.message);
    }
    // this.props.editarGuia(this.file, guia);

    this.props.history.push("/preradicado");
  };

  render() {
    return (
      <Fragment>
        <div className="">
          <Tabs className="tab-demo z-depth-1">
            <Tab title={`Actualizar Guia a ${this.state.PreradicadoIdPreradicado}`}>
              <div className="card-panel">
                <div className="row">
                  <form className="col s12" onSubmit={this.actualizar}>
                    <div className="row">
                      <div className="input-field col s12">
                        <TextInput
                          s={12}
                          m={12}
                          l={12}
                          xl={12}
                          label="Numero de guia"
                          value={this.state.numeroGuia}
                          onChange={this.numeroGuia}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s12">
                        <TextInput
                          s={12}
                          m={12}
                          l={12}
                          xl={12}
                          label="Operador logistico"
                          value={this.state.operadorLogistico}
                          onChange={this.operadorLogistico}
                        />
                      </div>
                    </div>
                    <div className="row">
                    <div className="input-field col s6">
												<Link className="btn left" to={"/preradicado"}>Salir</Link>
											</div>
                      <div className="input-field col s6">
                        <button
                          className="btn waves-effect waves-light green right"
                          type="submit"
                          name="action"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

EditarGuias.contextType = AuthConsumer;

export default EditarGuias;
