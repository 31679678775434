import React, { Component,Fragment } from "react";
import { Select, DatePicker, Switch, Textarea } from "react-materialize";

//Redux
import { connect } from "react-redux";
import {
  mostrarLineamiento,
  editarLineamiento
} from "../../actions/lineamientosActions";

//constantes
import { constLineamientos } from "./constLineamientos";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

//formato de fechas
const dateFormat = require("dateformat");

class EditarLineamiento extends Component {
  state = {
    tipo: "-1",
    fechaaplicacion: "",
    estado: false,
    descripcion: ""
  };

  componentDidMount() {
    this.props.mostrarLineamiento(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps, nextState) {
    const {
      TiposLineamientoIdTiposLineamientos,
      fechaAplicacion,
      EstadoIdEstado,
      descripcion
    } = nextProps.lineamiento;

    this.setState({
      tipo: TiposLineamientoIdTiposLineamientos,
      fechaaplicacion: fechaAplicacion,
      estado: EstadoIdEstado === 1 ? true : false,
      descripcion: descripcion
    });
  }

  validarForm = () => {
    const { tipo, fechaaplicacion, descripcion } = this.state;

    if (tipo === "-1") {
      Toast.fire({
        type: "error",
        title: `${constLineamientos.mensajeErrorTipo}`
      });
      return false;
    }

    if (fechaaplicacion === "") {
      Toast.fire({
        type: "error",
        title: `${constLineamientos.mensajeErrorFecha}`
      });
      return false;
    }

    if (descripcion === "") {
      Toast.fire({
        type: "error",
        title: `${constLineamientos.mensajeErrorDescripcion}`
      });
      return false;
    }

    return true;
  };

  actualizar = e => {
    e.preventDefault();
    if (!this.validarForm()) return null;
    const { tipo, fechaaplicacion, descripcion, estado } = this.state;

    const lineamiento = {
      idLineamiento: this.props.match.params.id,
      idTiposLineamientos: tipo,
      fechaAplicacion: fechaaplicacion,
      descripcion: descripcion,
      idEstado: estado ? 1 : 2,
      path: "this.file.name"
    };

    this.props.editarLineamiento(this.file, lineamiento);

    this.props.history.push("/lineamientos");
  };

  fechaaplicacionLineamiento = e => {
    this.setState({
      fechaaplicacion: e
    });
  };

  tipoLineamiento = e => {
    this.setState({
      tipo: e.target.value
    });
  };

  estadoLineamiento = e => {
    this.setState({
      estado: e.target.checked
    });
  };

  descripcionLineamiento = e => {
    this.setState({
      descripcion: e.target.value
    });
  };

  render() {
    const fecha = dateFormat(
      this.state.fechaaplicacion,
      "mmm dd, yyyy"
    );
    return (
      <Fragment>
        <div className="general_components container">
        <div className="card-panel">
            <h4 className="header2 center-align">
            Editar Lineamiento {this.props.match.params.id}
          </h4>
            <div className="container">
            <form className="col s12" onSubmit={this.actualizar}>
              <div className="row">
                <div className="input-field col s12">
                  <Select
                    s={12}
                    m={12}
                    l={12}
                    xl={12}
                    defaultValue={this.state.tipo}
                    label={constLineamientos.lbSelectTipo}
                    className="input-field col s12"
                    validate={true}
                    onChange={this.tipoLineamiento}
                  >
                    <option value="" disabled>
                      {constLineamientos.valueDefaultSelectTipo}
                    </option>
                    <option value="1">Normativo</option>
                    <option value="2">Operativo</option>
                  </Select>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <DatePicker
                    s={12}
                    m={12}
                    l={12}
                    xl={12}
                    value={fecha}
                    onChange={this.fechaaplicacionLineamiento}
                    label={constLineamientos.lbDtFechaaplicacion}
                  />
                </div>
              </div>
              <div className="row">
                <div className="input-field file-field col s12">
                  <div className="btn btn-color-arus">
                    <span>{constLineamientos.btArchivo}</span>
                    <input accept="*" type="file" ref={this.file} />
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <Switch
                    offLabel={constLineamientos.swEstadoOff}
                    onLabel={constLineamientos.swEstadoOn}
                    onChange={this.estadoLineamiento}
                    checked={this.state.estado}
                  />
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <Textarea
                    label={constLineamientos.lbTextAreaDescripcion}
                    s={12}
                    m={12}
                    l={12}
                    xl={12}
                    value={this.state.descripcion}
                    onChange={this.descripcionLineamiento}
                  />
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    <button
                      className="btn waves-effect waves-light green right"
                      type="submit"
                      name="action"
                    >
                      {constLineamientos.btEnviar}
                      <i className="material-icons right">send</i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
        <br></br><br></br><br></br>  
        <br></br><br></br><br></br><br></br>
        <br></br><br></br><br></br><br></br>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  lineamiento: state.lineamientos.lineamiento
});

export default connect(
  mapStateToProps,
  { mostrarLineamiento, editarLineamiento }
)(EditarLineamiento);
