import React, { Component, Fragment } from "react";

import { AuthConsumer } from "../../ContexApi/authContext";
//Redux
import { connect } from "react-redux";
//estilos materialize

import FormularioUsuario from "./FormularioUsuario";
//sweetAlert

class CreacionUsuarios extends Component {
  render() {
    //var handleToUpdate = this.handleToUpdate;
    return (
      <Fragment>
        <div className="general_components">
          <div className="card-panel">
            <FormularioUsuario referencia={false} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  null,
  { CreacionUsuarios }
)(CreacionUsuarios);
CreacionUsuarios.contextType = AuthConsumer;
