import React, { useState, useEffect } from "react";
import {
  Preloader
} from "react-materialize";
import Cookies from "js-cookie";
import axios from "axios";
import { API_AUTH_SURA, URL_DOMAIN_COOKIE } from "constServicios.js";
import "./SuraAuthentication.css";
//SweetAlert 2
import Swal from "sweetalert2";

const cookieName = "sura_integration";

const SuraAuthentication = props => {
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});
  // const { setAuthSura, logout } = props;
  const { setAuthSura } = props;

  async function handleError() {
    await Swal.fire({
      title: message,
      type: "error",
      confirmButtonText: "Aceptar"
    });
    props.history.push("/");
  }

  async function setAuth() {
    await setAuthSura(token, { data: user });
    props.history.push("/inicio");
  }
  useEffect(() => {

    async function authWithBackend(datosDesdePortalSuraJson) {
      try {
        setLoading(true);
        const response = await axios.post(
          API_AUTH_SURA,
          datosDesdePortalSuraJson,
          {
            timeout: 20000
          }
        );
        if (typeof response.data.access_token != "undefined") {
          // Cookies.remove(cookieName);
          document.cookie = `sura_integration=prueba; expires=Fri, 3 Aug 2017 20:47:11 UTC; path=/; domain=${URL_DOMAIN_COOKIE}`;
          setUser(response.data.user);
          setToken(response.data.access_token);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setMessage(error.response.data.message);
          setOpenModal(true);
        } else {
          setMessage("Ocurrio un error al intentear autenticar contra Portal");
          setOpenModal(true);
        }
      }
    }
    const datosDesdePortalSura = Cookies.get(cookieName);
    if (typeof datosDesdePortalSura === "undefined") {
      setMessage("Data de integración no encontrada.");
      setOpenModal(true);
    } else {
      try {
        const datosDesdePortalSuraJson = JSON.parse(datosDesdePortalSura);
        // Cookies.remove(cookieName);
        document.cookie = `sura_integration=prueba; expires=Fri, 3 Aug 2017 20:47:11 UTC; path=/; domain=${URL_DOMAIN_COOKIE}`;
        authWithBackend(datosDesdePortalSuraJson);
      } catch (error) {
        setMessage("Ocurrió un error al parsear datos.");
        setOpenModal(true);
      }
    }
  }, [setOpenModal, setMessage, setUser]);

  if (openModal) {
    handleError();
  }
  if (token && loading) {
    setLoading(false);
    setAuth();
  }
  return (
    <div className="center-align">
      {loading ? <Preloader size="big" /> : null}
    </div>
  );
};
export default React.memo(SuraAuthentication);
