import React, { Component } from "react";
import { Link } from "react-router-dom";

//Estilos materialize
import { Button } from "react-materialize";

//sweetAlert
import { AuthConsumer } from "ContexApi/authContext";
import "../../LineamientosOperativos/css/Lineamientos.css";
import moment from 'moment'
class Lineamiento extends Component {
  static contextType = AuthConsumer;

  componentDidMount() {}

  render() {
    const {
      idLineamientos,
      idEstado,
      TiposLineamiento,
      descripcion,
      fechaAplicacion,
      path,
      updatedAt,
      Usuario
    } = this.props.dataLineamiento;

    return (
      <tr>
        <td>{idLineamientos ? idLineamientos : ""}</td>
        <td>{TiposLineamiento ? TiposLineamiento.nombre : ""}</td>
        <td>{idEstado == 1 ? "Activo" : "Inactivo"}</td>
        <td style={{textAlign:"center"}}>{fechaAplicacion ? moment(fechaAplicacion).format('DD/MM/YYYY') : ""}</td>
        <td style={{textAlign:"center"}}>{descripcion}</td>
        {Usuario ? <td>
          {Usuario.primerNombre} {Usuario.primerApellido}
        </td> : <td></td>}
        <td>
          {path !== null ? (
            <Button
              onClick={() => this.props.descarga(idLineamientos, path)}
              floating
              small
              className="btn-color-arus"
            >
              <i class="material-icons">archive</i>
            </Button>
          ) : null}

          <Link to={`/lineamientos/${idLineamientos}?reporte=1`}>
            <Button
              floating
              small
              className="btn-color-arus"
              waves="light"
            >
              <i class="material-icons">pageview</i>
            </Button>
          </Link>
        </td>
      </tr>
    );
  }
}

Lineamiento.contenxtType = AuthConsumer;

export default Lineamiento;
