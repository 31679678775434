import React, { Component } from "react";
import { constServicios } from "../../constServicios";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../ContexApi/authContext";
import Axios from "axios";

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
class Roles extends Component {
  constructor(props) {
    super();
    this.state = {
      token: "",
      lista: [],
      tipoSeleccionado: props.idRol
    };
  }

  componentDidMount() {
    try {
      //e.preventDefault();

      const { accessToken } = this.context;
      this.setState({ token: accessToken });
      Axios.get(`${constServicios.url}${constServicios.urlRol}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken
        }
      })
        .then(resp => {
          if (resp.status === 200) {
            this.setState({
              lista: resp.data
            });
            //asigno el valor por defecto
            if (!this.props.idRol) {
              this.props.changeTipoDoc(resp.data[0].idRole);
            } else {
              this.props.changeTipoDoc(this.props.idRol);
            }
          } else {
            alert(
              "Estado Inesperado recuperando roles. Favor intente nuevamente"
            );
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch");
    }
  }
  onHandleChange(event) {
    this.setState({
      tipoSeleccionado: event.target.value
    });
    sleep(500).then(() => {
      this.props.changeTipoDoc(this.state.tipoSeleccionado);
    });
  }

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <React.Fragment>
              <div>
                <select
                  id="srol"
                  name="srol"
                  onChange={event => this.onHandleChange(event)}
                  className="browser-default"
                  value={this.state.tipoSeleccionado}
                >
                  {this.state.lista.map(lists => (
                    <option key={lists.idRole} value={lists.idRole}>
                      {lists.nombre}
                    </option>
                  ))}
                </select>
              </div>
            </React.Fragment>
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

Roles.contextType = AuthConsumer;

export default Roles;
