/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useContext } from "react";
import Row from "react-materialize/lib/Row";
import Col from "react-materialize/lib/Col";
import { AuthConsumer } from "../../../ContexApi/authContext";
import Axios from "axios";
import Swal from "sweetalert2";
import { ProgressBar } from "react-materialize";
import TextInput from "react-materialize/lib/TextInput";
import Select from "react-materialize/lib/Select";
import DatePicker, { registerLocale } from "react-datepicker";
import Button from "react-materialize/lib/Button";
import { constServicios } from "../../../constServicios";
import moment from "moment";
import es from "date-fns/locale/es";
import GridLogSoporte from "./grid";
import { Link } from "react-router-dom";

const FormlogCargaSoportes = (user) => {
  const [mostrar, setMostrar] = useState(false);
  const [datosGrid, setDatosGrid] = useState([]);
  const [datosEstadosFacturas] = useState([
    { text: "Recibido", value: "exito" },
    { text: "No recibido", value: "error" },
  ]);
  const [datosEstadosOrigen] = useState([
    { text: " Seleccione origen (Opcional)", value: "" },
    { text: "Físico", value: 1 },
    { text: "Digital", value: 2 },
  ]);
  const [data, setData] = useState({
    prestador: "",
    factura: "",
    origen: "",
    fechaInicio: "",
    fechaFin: "",
    estado: "",
    cliente: user.user.info.Cliente.numeroDocumento
  });
  const [disabled, setDisabled] = useState({
    btn: true,
    fechaInicio: false,
    fechaFin: true,
  });
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);

  const conxtFront = useContext(AuthConsumer);

  const getClients = async () => {
    try {
      setLoading(true);
      const response = await Axios({
        method: "GET",
        url: `${constServicios.url}${constServicios.urlCliente}`,
        data,
        headers: {
          Authorization: conxtFront.accessToken,
        },
      });
      if (response.data && response.data.length !== 0) {
        setLoading(false);
        setClients(response.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getClients();
    registerLocale("es", es);
  }, []);

  const swal = (title, type, html, confirmButtonColor, confirmButtonText) => {
    Swal.fire({
      title: title,
      type: type,
      html: html,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirmButtonColor,
    });
  };

  const chageData = (e) => {
    const { value, name } = e.target;
    let newData = { ...data };
    newData[name] = String(value);
    setData(newData);
  };

  const generateReport = async (role) => {
    const newData = { ...data }
    try {
      setLoading(true);
      setDisabled({ ...disabled, btn: true });
      if (newData.cliente === "") {
        setLoading(false);
        setDisabled({ ...disabled, btn: false });
        return swal(
          "Selecciones un cliente es obligatorio",
          "error",
          "",
          "#002c55",
          "Cerrar"
        );
      }
      if (newData.fechaInicio && newData.fechaFin) {
        const i = moment(newData.fechaInicio);
        const f = moment(newData.fechaFin);
        const diferencia = f.diff(i, "days");
        if (diferencia < 0) {
          setLoading(false);
          setDisabled({ ...disabled, btn: false });
          return Swal.fire(
            "Fecha inicial de recepción no puede ser mayor a la fecha final de recepción"
          );
        }
        if (diferencia + 1 > 31) {
          setLoading(false);
          setDisabled({ ...disabled, btn: false });
          return Swal.fire("No se puede consultar más de 31 días");
        }
        //se valida si es un prestador
        if (role === "prestadoresSura" || role === "prestadoresSanitas") {
          newData.prestador = user.user.info.Prestadore.idPrestador;
        }

        console.log("newData==========================", newData);
        newData.role = role

        const response = await Axios({
          method: "POST",
          url: `${constServicios.url}${constServicios.urlReporteLogCargaSoporte}`,
          data: newData,
          headers: {
            Authorization: conxtFront.accessToken,
          },
        });
        if (response.data && response.data.length !== 0) {
          setDatosGrid(response.data);
          swal(
            "Se cargaron los datos correctamente",
            "success",
            "",
            "#002c55",
            "Cerrar"
          );
          setMostrar(true);
          setLoading(false);
          setDisabled({ ...disabled, btn: false });
        } else {
          setLoading(false);
          setMostrar(false);
          setDisabled({ ...disabled, btn: false });
          swal(
            "No hay resultados",
            "warning",
            "No se encontraron resultados para mostrar",
            "#002c55",
            "Cerrar"
          );
        }
      } else {
        setMostrar(false);
        setLoading(false);
        swal(
          "error",
          "error",
          "Por favor rellene todos los campos del formulario",
          "#002c55",
          "Cerrar"
        );
        setDisabled({ ...disabled, btn: false });
      }
    } catch (error) {
      setLoading(false);
      setDatosGrid([]);
      setDisabled({ ...disabled, btn: false });
      swal(
        "Error",
        "error",
        "Error al intentar buscar resultados",
        "#002c55",
        "Cerrar"
      );
    }
  };
  const clientes = () => {
    if (!user.user.info.Cliente.numeroDocumento) {
      return clients.map((cliente) => {
        if (cliente.numeroDocumento === "") {
          return (
            <option
              key={cliente.numeroDocumento}
              value={cliente.numeroDocumento}
              disabled
            >
              Selecciones Cliente
            </option>
          );
        } else {
          return (
            <option
              key={cliente.numeroDocumento}
              value={cliente.numeroDocumento}
            >
              {cliente.nombreCliente}
            </option>
          );
        }
      });
    } else {
      for (const cliente of clients) {
        if (
          cliente.numeroDocumento === user.user.info.Cliente.numeroDocumento
        ) {
          return (
            <option
              key={cliente.numeroDocumento}
              value={cliente.numeroDocumento}
            >
              {cliente.nombreCliente}
            </option>
          );
        }
      }
    }
  };

  const crearForm = () => {
    const {
      user: { role },
    } = user;

    return (
      <Fragment>
        <Row>
          {
            // role === "clienteSanitas" ||
            // role === "clienteSura" ||
            role === "administrador" ||
              role === "arusOperativos" ||
              role === "arusLider" ? (
                // role === "prestadoresSanitas" ||
                // role === "prestadoresSura"
                <Col m={12}>
                  <Select
                    label={"Selecione un cliente"}
                    onChange={(e) => {
                      chageData(e);
                    }}
                    m={12}
                    value={data.cliente}
                    name={"cliente"}
                  >
                    {clientes()}
                  </Select>
                </Col>
              ) : (
                ""
              )
          }
        </Row>
        <Row>
          {role === "administrador" ||
            role === "arusOperativos" ||
            role === "arusLider" ||
            role === "clienteSanitas" ||
            role === "clienteSura" ? (
              // role === "prestadoresSanitas" ||
              // role === "prestadoresSura"
              <Col m={4}>
                <TextInput
                  onChange={(e) => {
                    chageData(e);
                  }}
                  label={"Nit del prestador(Opcional)"}
                  m={12}
                  value={data.prestador}
                  name={"prestador"}
                />
              </Col>
            ) : null}
          <Col m={4}>
            <TextInput
              onChange={(e) => {
                chageData(e);
              }}
              label={"Número  de factura(Opcional)"}
              m={12}
              value={data.factura}
              name={"factura"}
            />
          </Col>
          <Col m={4}>
            <Select
              label={"Estados de facturas cargadas"}
              onChange={(e) => {
                chageData(e);
              }}
              m={12}
              value={data.estado}
              name={"estado"}
            >
              <option>Selecciones un estado</option>
              {datosEstadosFacturas.map((factura) => {
                if (factura)
                  return (
                    <option key={factura.value} value={factura.value}>
                      {factura.text}
                    </option>
                  );
              })}
            </Select>
          </Col>
        </Row>
        <Row>
          {/* <Col m={4}>
            <Select
              label={"Origen del soporte(Opcional)"}
              onChange={(e) => {
                chageData(e);
              }}
              m={12}
              value={data.origen}
              name={"origen"}
            >
              {datosEstadosOrigen.map((origen) => {
                if (origen)
                  return (
                    <option key={origen.value} value={origen.value}>
                      {origen.text}
                    </option>
                  );
              })}
            </Select>
          </Col> */}
          <Col m={4}>
            <label>Fecha inicio (Obligatorio)</label>
            <div>
              <DatePicker
                disabled={disabled.fechaInicio}
                selected={data.fechaInicio}
                onChange={(date) => {
                  setData({ ...data, fechaInicio: date });
                  setDisabled({ ...disabled, fechaFin: false });
                }}
                dateFormat="dd-MM-yyyy"
                // onKeyDown={(e) => e.preventDefault()}
                locale="es"
                placeholderText="Seleccione una fecha"
                minDate={treeMonths()}
              />
            </div>
          </Col>
          <Col m={4}>
            <label>Fecha fin (Obligatorio)</label>
            <div>
              <DatePicker
                disabled={disabled.fechaFin}
                selected={data.fechaFin}
                onChange={(date) => {
                  setData({ ...data, fechaFin: date });
                  setDisabled({ ...disabled, btn: false });
                }}
                dateFormat="dd-MM-yyyy"
                // onKeyDown={(e) => e.preventDefault()}
                locale="es"
                placeholderText="Seleccione una fecha"
                minDate={treeMonths()}
              />
            </div>
          </Col>
        </Row>
        <div className="center" style={{ marginLeft: "1vw" }}>
          <Button
            disabled={disabled.btn}
            onClick={() => {
              generateReport(role);
            }}
          >
            Consultar
          </Button>

          <Button
            onClick={() => {
              setData({
                prestador: "",
                factura: "",
                origen: "",
                fechaInicio: "",
                fechaFin: "",
                estado: "",
                cliente: user.user.info.Cliente.numeroDocumento
              })
              setMostrar(false)
            }}
            style={{ marginLeft: "1vw" }}
          >
            Limpiar
          </Button>
          <Link to={"/reportes"}>
            <Button style={{ marginLeft: "1vw" }}>Salir</Button>
          </Link>

          {/* <Button style={{ marginLeft: "1vw" }} onClick={() => {
            setData({...data, prestador: '', factura: '', fechaInicio: '', fechaFin: '' });
            setDisabled({...disabled, fechaInicio: true, fechaFin: true, btn: true })
            setMostrar(false)
          }}>
            Limpiar
          </Button> */}
        </div>
        {loading && <ProgressBar />}
        {mostrarGrid()}
      </Fragment>
    );
  };
  const treeMonths = () => {
    var d = new Date();
    d.setMonth(d.getMonth() - 3);
    return d;
  };
  const mostrarGrid = () => {
    if (mostrar) {
      return (
        <div>
          <GridLogSoporte datosGrid={datosGrid} />
        </div>
      );
    }
  };
  return crearForm();
};
export default FormlogCargaSoportes;
