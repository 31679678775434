import React, { Component, Fragment } from "react";

//campos db
import { Transportadoras } from "../../Variables/CargaCampos";

//estilos materialize
import {
  Select,
  TextInput,
  Row,
  Col,
  ProgressBar,
  Tab,
  Tabs,
} from "react-materialize";

import axios from "axios";
import { constServicios } from "../../constServicios";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthConsumer } from "ContexApi/authContext";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
});

class AsociarGuias extends Component {
  constructor() {
    super();
    this.file = React.createRef();
  }

  componentDidMount() {
    this.setState({
      PreradicadoIdPreradicado: this.props.match.params.id,
      transportadoras: Transportadoras(),
    });
  }

  state = {
    numeroGuia: "",
    operadorLogistico: "",
    PreradicadoIdPreradicado: "",
    btnBuscar: true,
    preLoad: false,
    transportadoras: [],
  };

  preload = () => {
    if (!this.state.preLoad) return null;
    return (
      <Row>
        <Col offset="s3" s={6}>
          <ProgressBar />
        </Col>
      </Row>
    );
  };

  validarForm = () => {
    const { numeroGuia, operadorLogistico } = this.state;

    if (numeroGuia === "") {
      Toast.fire({
        type: "error",
        title: `debe agregar numero de guia`,
      });
      return false;
    }

    if (operadorLogistico === "") {
      Toast.fire({
        type: "error",
        title: `debe agregar operador logistico`,
      });
      return false;
    }

    return true;
  };

  numeroGuia = (e) => {
    this.setState({
      numeroGuia: e.target.value,
    });
  };

  operadorLogistico = (e) => {
    this.setState({
      operadorLogistico: e.target.value,
    });
  };

  crear = (e) => {
    e.preventDefault();
    if (!this.validarForm()) return null;
    const {
      numeroGuia,
      operadorLogistico,
      PreradicadoIdPreradicado,
    } = this.state;

    const guia = {
      numeroGuia,
      operadorLogistico,
      idPreradicado: PreradicadoIdPreradicado,
      path: "Path",
      accion: "crear",
    };
    const { accessToken } = this.context;
    try {
      axios
        .post(`${constServicios.url}${constServicios.urlGuias}`, guia, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((respuesta) => {
          if (respuesta.status === 200)
            MySwal.fire({
              type: "success",
              html: "La guia fue asociada correctamente.",
              confirmButtonText: "Cerrar",
              confirmButtonColor: "#002c55",
            });
          else if (respuesta.status === 400)
            MySwal.fire({
              title: "Error",
              type: "error",
              html: respuesta.err,
              confirmButtonText: "Cerrar",
              confirmButtonColor: "#002c55",
            });
          else
            MySwal.fire({
              title: "Error",
              type: "error",
              html: "La guia no pudo ser creada.",
              confirmButtonText: "Cerrar",
              confirmButtonColor: "#002c55",
            });
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 400 && error.response.data)
			MySwal.fire({
				title: "Error",
				type: "error",
				html: error.response.data.err,
				confirmButtonText: "Cerrar",
				confirmButtonColor: "#002c55",
			  });
		  else 
		  MySwal.fire({
			title: "Error",
			type: "error",
			html: "La guia no pudo ser creada.",
			confirmButtonText: "Cerrar",
			confirmButtonColor: "#002c55",
		  });
        });
    } catch (e) {
      console.log(e.message);
    }
    // this.props.agregarGuia(this.file, guia);

    this.props.history.push("/preradicado");
  };

  render() {
    return (
      <Fragment>
        <div className="general_components">
          <Tabs className="tab-demo z-depth-1">
            <Tab
              title={`Asociar Guia a ${this.state.PreradicadoIdPreradicado}`}
            >
              <div className="card-panel">
                <div className="row">
                  <form className="col s12" onSubmit={this.crear}>
                    <div className="row">
                      <div className="input-field col s12">
                        <TextInput
                          m={12}
                          label="Numero de guia"
                          onChange={this.numeroGuia}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s12">
                        <TextInput
                          m={12}
                          label="Operador logistico"
                          onChange={this.operadorLogistico}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="input-field col s6">
                        <Link className="btn left" to={"/preradicado"}>
                          Salir
                        </Link>
                      </div>
                      <div className="input-field col s6">
                        <button
                          className="btn waves-effect waves-light green right"
                          type="submit"
                          name="action"
                        >
                          Asociar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

AsociarGuias.contextType = AuthConsumer;

export default AsociarGuias;
