import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { constServicios } from "../../../constServicios";
import { Divider, Dropdown } from "react-materialize";
import { AuthConsumer } from "../../../ContexApi/authContext";

class OpcSidebar extends Component {
  state = { menus: [], pintarMenu: true };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { user, accessToken } = this.context;
    if (user) {
      this.consultarMenu(user.role, accessToken);
    }
  }
  consultarMenu = async (role, token) => {
    try {
      if(role !== 'visitor'){
        const respuesta = await axios.get(
          `${constServicios.url}${constServicios.urlMenu}${role}`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        if (respuesta.status === 200) {
          this.setState({
            menus: respuesta.data
          });
          if (this.state.pintarMenu) {
            const menu = document.getElementById("i_menu");
            const botonMenu = document.getElementById("idMenu");
            if (botonMenu) {
              botonMenu.style.opacity = "0";
            }
            if (menu) {
              menu.click();
              botonMenu.style.opacity = "1";
              this.setState({ pintarMenu: false })
            }
          }
        }
      }
    } catch (e) {
      // console.log(e.message);
    }
  };

  render() {
    let { menus } = this.state;

    menus = menus.sort((a, b) => {
      if (a.prioridad > b.prioridad) return 1;
      if (a.prioridad < b.prioridad) return -1;
      return 0;
    });

    if (!this.context.validarPrestador || this.context.user) {
      return menus.map((menu, key) => {
        if (menu.SubModulos.length > 0) {
          return (
            <li key={key} className="sidenav-close">
              <Dropdown
                trigger={
                  <Link to={`#`} className="othera">
                    <img
                      src={`/images/menu/${menu.icon}`}
                      alt={`${menu.nombre}`}
                      height="70%"
                      width="auto"
                    />
                    {"  "}
                    {menu.nombre}
                  </Link>
                }
              >
                {menu.SubModulos.map((submenu, keysub) => {
                  return (
                    <Fragment key={`submenu${keysub}`}>
                      <Link to={`${submenu.href}`}>
                        {/* <i className="material-icons">{submenu.icon}</i> */}
                        {submenu.nombre}
                      </Link>
                      <Divider />
                    </Fragment>
                  );
                })}
              </Dropdown>
            </li>
          );
        }
        return (
          <li key={key} className="sidenav-close">
            <Link to={`${menu.href}`}>
              <img
                src={`/images/menu/${menu.icon}`}
                alt={`${menu.nombre}`}
                height="70%"
                width="auto"
              />
              {"  "}
              {menu.nombre}
            </Link>
          </li>
        );
      });
    } else {
      return ''
    }
  }
}

OpcSidebar.contextType = AuthConsumer;
export default OpcSidebar;
