import React, { Component, Fragment } from "react";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

//Constantes Lineamientos
import { constLineamientos } from "../../LineamientosOperativos/constLineamientos";
import { constTablaLineamientos } from "../../../Variables/constTabla";
import Lineamiento from "./Lineamiento";
import exportarCSV_XLSX from "../reportePertinencia/exportar";
//Estilos Materialize
import {
  Table,
  Row,
  Col,
  ProgressBar,
  Tabs,
  Tab,
  Select
} from "react-materialize";
import "../../LineamientosOperativos/css/Lineamientos.css";

//DataTable.net
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";

//Auth
import { AuthConsumer } from "../../../ContexApi/authContext";
import { Redirect } from "react-router-dom";
import Can from "../../../ContexApi/Can";

import axios from "axios";
import { constServicios } from "../../../constServicios";

import XLSX from "xlsx";
import { saveAs } from "file-saver";

import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
registerLocale("es", es);

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

const day = Date.parse(
  moment(new Date())
    .subtract(3, "months")
    .format()
);

class MostrarLineamientos extends Component {
  state = {
    lineamientos: [],
    cargando: false,
    activarTab: 1,

    tipo: 0,
    fechaAplicacion: "",
    tiposLineamientos: [],
    fechaInicio: "",
    fechaFin: ""
  };

  componentDidMount() {
    const { accessToken, user } = this.context;
    if (
      this.context.authenticated &&
      this.context.user.permisos.static.includes(
        "reporteLineamientosOperativos:ver"
      )
    ) {
      this.cargarTiposLineamientos();
    } else {
      this.props.history.push("/");
    }
  }

  cargarTiposLineamientos = async () => {
    const { accessToken } = this.context;

    try {
      const respuesta = await axios.get(
        `${constServicios.url}${constServicios.urlTiposLineamientos}`,
        {
          headers: {
            Authorization: accessToken
          }
        }
      );
      if (respuesta.status === 200) {
        this.setState({
          tiposLineamientos: respuesta.data
        });
      } else {
        MySwal.fire({
          title: "Error al consultar los tipos de lineamiento, por favor recargue la página.",
          type: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: '#002c55',
        });
      }
    } catch (error) {
      this.props.history.push("/");
    }
  };

  descargaArchivoLineamiento = async (idLineamiento, archivo) => {
    const { accessToken, user } = this.context;
    archivo = archivo.replace(
      `${constServicios.UPLOAD_FILE_PATH_OUT_LINEAMIENTOS}`,
      ""
    );

    axios
      .get(
        `${constServicios.url}${constServicios.urlLineamientosDescargarArchivo}${idLineamiento}/${user.info.Cliente.idCliente}`,
        {
          headers: { Authorization: accessToken },
          responseType: "blob" //IMPORTANTE
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", archivo);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        Toast.fire({
          type: "error",
          title: `${constLineamientos.mensajeErrorImagen}`
        });
      });
  };

  fechaAplicacionLineamiento = e => {
    this.setState({
      fechaAplicacion: e
    });
  };

  fechaInicio = e => {
    this.setState({
      fechaInicio: e,
      fechaFin: ""
    });
  };

  fechaFin = e => {
    this.setState({
      fechaFin: e
    });
  };

  tipoLineamiento = e => {
    this.setState({
      tipo: e.target.value
    });
  };

  isWeekday = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  buscar = async () => {
    this.setState({
      cargando: true,
      lineamientos: []
    });

    const { accessToken, user } = this.context;

    try {
      const respuesta = await axios.post(
        `${constServicios.url}${constServicios.urlReporteLineamientosOperativos}`,
        {
          idTiposLineamiento: this.state.tipo,
          fechaAplicacion: this.state.fechaAplicacion,
          fechaInicio: this.state.fechaInicio,
          fechaFin: this.state.fechaFin,
          idCliente: user.info.Cliente.idCliente,
          idRole: user.info.Role.idRole
        },
        {
          headers: {
            Authorization: accessToken
          }
        }
      );

      if (respuesta.status === 200) {
        if (respuesta.data.length > 0) {
          this.setState({
            lineamientos: respuesta.data,
            cargando: false
          });
        } else {
          MySwal.fire({
            title: "Alerta",
            type: "info",
            html: "No se encontró información con los datos de búsqueda.",
            confirmButtonText: "Cerrar",
            confirmButtonColor: '#002c55',
          });
        }

        $("#datatable").DataTable(constTablaLineamientos);
        $("#datatable_length select").val("10");
        $("#datatable_length select").addClass("browser-default");
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({
      cargando: false
    });
  };

  limpiar = () => {
    document.getElementById("form").reset();
    this.setState({
      tipo: 0,
      fechaAplicacion: "",
      fechaInicio: "",
      fechaFin: "",
      lineamientos: []
    });
  };

  exportarLinemientos = () => {
    const arrToExcel = this.state.lineamientos.map(i => {
      return {
        idLineamiento: i.idLineamientos,
        Descripcion: i.descripcion,
        "Tipo de lineamiento": i.TiposLineamiento.nombre,
        Estado: i.Estado.nombre,
        "Nombre del usuario": i.Usuario.primerNombre,
        "Apellido del usuario": i.Usuario.primerApellido,
        Usuario: i.Usuario.username,
        "Fecha aplicacion": moment(i.fechaAplicacion).format('DD/MM/YYYY'),
        "Fecha de creacion": moment(i.createdAt).format('DD/MM/YYYY')
      };
    });
    exportarCSV_XLSX(arrToExcel, "reporteLineamientos");
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Can
              rules={user.permisos}
              perform="reporteLineamientosOperativos:ver"
              yes={() => {
                let param = this.context.params.filter(i => i.key === "creacionLineamientos")
                if (Number(this.context.user.info.Cliente.idCliente) === Number(param[0].value)) {
                  Swal.fire({
                    title: "Usted no tiene ningún cliente asignado",
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar'
                  }).then((result) => {
                    this.props.history.push('/inicio')
                  })
                }
                return !this.state.cargando ? (
                  <Tabs className="tab-demo z-depth-1">
                    <Tab
                      title="Listado de Lineamientos"
                      active={this.state.activarTab == 1 ? true : false}
                    >
                      <Fragment>
                        <div className="row general_components card-panel">
                          <form
                            id="form"
                            className="col s12"
                            onSubmit={this.buscar}
                          >
                            <div className="row">
                              <div className="input-field col s6 m4">
                                <Select
                                  // s={12}
                                  defaultValue={this.state.tipo}
                                  label={constLineamientos.lbSelectTipo}
                                  // className="input-field col s12"
                                  validate={true}
                                  onChange={this.tipoLineamiento}
                                >
                                  <option value="0" disabled>
                                    {constLineamientos.valueDefaultSelectTipo}
                                  </option>
                                  {this.state.tiposLineamientos.map(tipo => {
                                    return (
                                      <option
                                        key={tipo.idTiposLineamiento}
                                        value={`${tipo.idTiposLineamiento}`}
                                      >
                                        {tipo.nombre}
                                      </option>
                                    );
                                  })}
                                </Select>
                              </div>

                              <div className="input-field col s6 m4">
                                <span className="col s12 spanDate">
                                  {constLineamientos.lbDtFechaaplicacion}
                                </span>
                                <DatePicker
                                  minDate={new Date(day)}
                                  selected={this.state.fechaAplicacion}
                                  name="fechaAplicacion"
                                  onKeyDown={e => e.preventDefault()}
                                  id="fechaAplicacion"
                                  locale="es"
                                  filterDate={this.isWeekday}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={e =>
                                    this.fechaAplicacionLineamiento(e)
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="input-field col s6 m4 center">
                                <button
                                  className="btn btn-color-arus"
                                  type="button"
                                  disabled={this.state.cargando ? true : false}
                                  onClick={this.limpiar}
                                >
                                  {constLineamientos.limpiar}
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s6 m4">
                                <span className="col s12 spanDate">
                                  {constLineamientos.FechaInicio}
                                </span>
                                <DatePicker
                                  selected={this.state.fechaInicio}
                                  onChange={e => this.fechaInicio(e)}
                                  selectsStart
                                  startDate={this.state.fechaInicio}
                                  endDate={this.state.fechaFin}
                                  minDate={new Date(day)}
                                  placeholderText="Fecha Inicio"
                                  locale="es"
                                  autoComplete="off"
                                />
                              </div>

                              <div className="input-field col s6 m4">
                                <span className="col s12 spanDate">
                                  {constLineamientos.FechaFin}
                                </span>
                                <DatePicker
                                  selected={this.state.fechaFin}
                                  onChange={e => this.fechaFin(e)}
                                  selectsEnd
                                  startDate={this.state.fechaInicio}
                                  endDate={this.state.fechaFin}
                                  minDate={this.state.fechaInicio}
                                  placeholderText="Fecha Fin"
                                  locale="es"
                                  autoComplete="off"
                                />
                              </div>

                              <div className="input-field col s6 m4 center">
                                <button
                                  className="btn btn-color-arus"
                                  type="submit"
                                  name="action"
                                  disabled={this.state.cargando ? true : false}
                                >
                                  {constLineamientos.consultar}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>

                        {this.state.lineamientos.length > 0 ? (
                          <div className="row general_components card-panel">
                            <div className="tableOverflow">
                              <div className="input-field col s12 m12 center">
                                <button
                                  className="btn btn-color-arus"
                                  type="button"
                                  name="action"
                                  disabled={this.state.cargando ? true : false}
                                  onClick={this.exportarLinemientos}
                                >
                                  {constLineamientos.exportar}
                                </button>
                              </div>
                              <Table
                                id="datatable"
                                className="striped"
                                hoverable
                              >
                                <thead>
                                  <tr>
                                    <th data-field="id">
                                      {constLineamientos.columId}
                                    </th>
                                    <th data-field="tipo">
                                      {constLineamientos.columTipoLineamiento}
                                    </th>
                                    <th data-field="estado">
                                      {constLineamientos.columEstado}
                                    </th>
                                    <th data-field="fechaaplicacion">
                                      {constLineamientos.columFechaAplica}
                                    </th>
                                    <th data-field="fechacreacion">
                                      {constLineamientos.columDescripcion}
                                    </th>
                                    <th data-field="fechaactualizacion">
                                      {constLineamientos.nombreUsuario}
                                    </th>
                                    <th data-field="acciones">
                                      {constLineamientos.columAcciones}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.lineamientos.map(lineamiento => (
                                    <Lineamiento
                                      key={lineamiento.idLineamientos}
                                      dataLineamiento={lineamiento}
                                      actualizarLineamiento={
                                        this.actualizarLineamiento
                                      }
                                      descarga={this.descargaArchivoLineamiento}
                                    />
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        ) : null}
                      </Fragment>
                    </Tab>
                  </Tabs>
                ) : (
                    <Row>
                      <Col s={12}>
                        <ProgressBar
                          className=""
                          color="blue"
                          active={this.state.cargando}
                        />
                      </Col>
                    </Row>
                  )
              }
              }
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
              <Redirect to="/inicio" />
            )
        }
      </AuthConsumer>
    );
  }
}

MostrarLineamientos.contextType = AuthConsumer;

export default MostrarLineamientos;
