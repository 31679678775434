import React, {  } from 'react';
import './css/index.css';
import ApiVisores from './apiVisores';
const panzoom = require('panzoom');

const CANVAS_ID = 'canvas_visor';

const Visor = (props) => {
  return (
    <div className="center-align">
      <div 
        id={props.container} 
        className={ props.className || 'container_canvas'}
      ></div>
      <div id={`${props.container}_btns`}></div>
    </div>
  );
}

const Position = ({ x, y, z }) => {
  const element = document.getElementById(CANVAS_ID);
  const options = { maxZoom: 10, minZoom: 1, zoomDoubleClickSpeed: 3, pinchSpeed: 2, smoothScroll: false }
  panzoom(element, options).zoomAbs(x, y, z);
}

export { Visor, ApiVisores, Position, CANVAS_ID };


