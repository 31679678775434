import axios from "axios";
import { constServicios } from '../../../constServicios';

export const filtersDB = async (usuario,params) => {
  const filters = `idUsuario=${usuario}
                  &nitCliente=${params.nitCliente}
                  &nitPrestador=${params.nitPrestador}
                  &numFactura=${params.numFactura}
                  &alpha=${params.alpha}
                  &idPreradicado=${params.idPreradicado}
                  &fechaInicio=${params.fechaInicio !== 0 ? new Date(params.fechaInicio) : 0}
                  &fechaFin=${params.fechaInicio !== 0 ? new Date(params.fechaFin) : 0 }`;
  try {
    const respuesta = await axios.get(`${constServicios.url}${constServicios.urlReportesFacturasPreradicados}${filters}`);
    if(respuesta.status === 200){
      return respuesta.data; 
    }else{
      return [`error de peticion status: ${respuesta.status}`]
    }
  }catch(e){ console.log(e.message) }
}

export const filtersPaginationDB = async (usuario,params) => {
  const filters = `idUsuario=${usuario}
                  &nitCliente=${params.nitCliente}
                  &nitPrestador=${params.nitPrestador}
                  &numFactura=${params.numFactura}
                  &alpha=${params.alpha}
                  &idPreradicado=${params.idPreradicado}
                  &fechaInicio=${params.fechaInicio !== 0 ? new Date(params.fechaInicio) : 0}
                  &fechaFin=${params.fechaInicio !== 0 ? new Date(params.fechaFin) : 0 }
                  &limit=${params.limit}
                  &offset=${params.offset}`;
  try {
    const respuesta = await axios.get(`${constServicios.url}${constServicios.urlReportesFacturasPreradicadosPagination}${filters}`);
    if(respuesta.status === 200){
      return respuesta.data; 
    }else{
      return [`error de peticion status: ${respuesta.status}`]
    }
  }catch(e){ console.log(e.message) }
}