import React, { Component } from "react";
import "./Dropzone.css";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      array: [],
      hightlight: false
    };
    // this.fileInputRef = React.createRef();
    this.dzref = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    // this.fileInputRef.current.click();
    this.refs.dzref.click()
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.refs.dzref.value = '';
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    return (
      <div className="row">
        <div className="col l6 m6 s12 offset-l3 offset-m3 grey-text">
          <div
            id="divDropzone"
            className={`Dropzone ${this.state.hightlight ? "Highlight" : ""} `}
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
            onClick={this.openFileDialog}
            style={{ cursor: this.props.disabled ? "default" : "pointer" }}
          >
            <input
              id="us"
              // ref={this.fileInputRef}
              // ref={this.dzref}
              ref="dzref"
              accept=".zip"
              className="FileInput"
              type="file"
              multiple
              onChange={e => this.onFilesAdded(e)}
            />
            <i className="material-icons large">cloud_upload</i>
            <h5 className="center">Arrastra o selecciona los archivos</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Dropzone;
