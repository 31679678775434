import axios from "axios";
import { constServicios } from "../../../constServicios";

export const filtersDB = async (token, usuario, params) => {
  const filters = `cliente=${params.cliente}
                  &prestador=${params.prestador}
                  &factura=${params.factura}
                  &remision=${params.remision}
                  &fechaInicio=${
                    params.fechaInicio !== 0 ? new Date(params.fechaInicio) : 0
                  }
                  &fechaFin=${
                    params.fechaInicio !== 0 ? new Date(params.fechaFin) : 0
                  }
                  &limit=X
                  &offset=X`;
  try {
    console.log("Excel: ", filters);
    const respuesta = await axios.get(
      `${constServicios.url}${constServicios.urlReportesCargaRipsPagination}${filters}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (respuesta.status === 200) {
      return respuesta.data;
    } else {
      return [`error de peticion status: ${respuesta.status}`];
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const filtersPaginationDB = async (token, usuario, params) => {
  const filters = `cliente=${params.cliente}
                  &prestador=${params.prestador}
                  &factura=${params.factura}
                  &remision=${params.remision}
                  &fechaInicio=${
                    params.fechaInicio !== 0 ? new Date(params.fechaInicio) : 0
                  }
                  &fechaFin=${
                    params.fechaInicio !== 0 ? new Date(params.fechaFin) : 0
                  }
                  &limit=X
                  &offset=X`;
  // &limit=${params.limit}
  // &offset=${params.offset}`;
  try {
    console.log("Paginacion: ", filters);
    const respuesta = await axios.get(
      `${constServicios.url}${constServicios.urlReportesCargaRipsPagination}${filters}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (respuesta.status === 200) {
      return respuesta.data;
    } else {
      return [`error de peticion status: ${respuesta.status}`];
    }
  } catch (e) {
    console.log(e.message);
  }
};
