import React, { Component, Fragment } from "react";
import { constServicios } from "../../constServicios";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../ContexApi/authContext";
import { Select } from "react-materialize";
import Axios from "axios";
import "../AdminUsuarios/css/AdminUsuarios.css";
import Swal from "sweetalert2";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PermisosModulos from "../AdminUsuarios/PermisosModulos";
import "../AdminUsuarios/css/AdminUsuarios.css";

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
const ModuloOtros = "12";
class PermisosMod extends Component {
  constructor(props) {
    super();
    this.state = {
      token: "",
      lista: [],
      modulos: [],
      permisos: [],
      modulosNew: [],
      tipoSeleccionado: [], //props.idClienteAsignado,
      nombreRol: props.rolname,
      showNewRole: false,
      opened: 0,
      rolId: props.rol
    };
  }
  async seleccionModulo(event, idMod) {
    event.preventDefault();

    let checked = event.target.checked;

    var bnd = false;
    await Swal.fire({
      title:
        checked === true
          ? "Desea Activar el módulo?"
          : "Desea Eliminar el módulo?",
      text: "Se modificara el permiso del Rol " + this.props.rolname,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "teal",
      cancelButtonColor: "gray",
      confirmButtonText: "Si"
    }).then(result => {
      if (result.value) {
        var resultado =
          checked === true ? this.crearModuloRol(event, idMod) : null;

        bnd = true;
      } else {
        event.preventDefault();
        return;
      }
    });

    if (bnd === true) {
      var arrData;
      arrData = this.state.modulosNew.map(d => {
        return {
          idModulo: d.idModulo,
          nombre: d.nombre,
          seleccion: idMod === d.idModulo ? checked : d.seleccion,
          showPermisos: idMod === d.idModulo ? true : d.showPermisos,
          agregar: idMod === d.idModulo ? checked : d.agregar
        };
      });

      this.setState({ modulosNew: arrData });
    }
  }
  newListaPer(arrOld) {
    var arrOriginal = [];
    var modulosAsignados = [];
    var selecion = false;

    arrOld.map(data => {
      modulosAsignados.push(data.Permiso.ModuloIdModulo); //cambiar por idModulo
    });

    arrOriginal = this.state.lista.map(d => {
      //probablemente por el index of!
      const found = modulosAsignados.find(element => element === d.idModulo);

      if (found !== undefined) {
        selecion = true;
      } else {
        selecion = false;
      }
      return {
        idModulo: d.idModulo,
        nombre: d.nombre,
        seleccion: selecion,
        showPermisos: false,
        showRole: false,
        agregar: false
      };
    });

    return arrOriginal;
  }

  handleChangeView(item) {
    var selecion;
    var arrOriginal;
    var found;

    arrOriginal = this.state.modulosNew.map(d => {
      if (d.idModulo === item) {
        selecion = !d.showPermisos;
      } else {
        selecion = false;
      }

      return {
        idModulo: d.idModulo,
        nombre: d.nombre,
        seleccion: d.seleccion,
        showPermisos: selecion,
        showRole: d.showRole,
        agregar: d.agregar
      };
    });

    this.setState({
      modulosNew: arrOriginal
    });
  }

  handleChange(event) {
    var options = event.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
        this.setState({ tipoSeleccionado: value });
      }
    }
  }
  onHandleChange(event) {
    this.setState({
      tipoSeleccionado: event.target.value
    });
  }
  consultaModulos() {
    this.setState({
      showNewRole: false
    });

    const { accessToken } = this.context;

    this.setState({ token: accessToken });
    try {
      //e.preventDefault();

      Axios.get(`${constServicios.url}${constServicios.urlModulos}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken
        }
      })
        .then(resp => {
          if (resp.status === 200) {
            //filtro respuesta sin modulo OTros no debe mostrarse
            resp.data = resp.data.filter(
              data => String(data.idModulo) !== ModuloOtros
            );

            this.setState({
              lista: resp.data,
              showNewRole: true
            });
          } else {
            this.setState({
              showNewRole: false
            });
            alert(
              "Ocurrio un error recuperando los Modulos, Por favor intente de nuevo."
            );
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch" + e);
      this.setState({
        showNewRole: false
      });
    }
    //ahora traemos los modulos desde permisos Roles

    try {
      //e.preventDefault();

      Axios.get(
        `${constServicios.url}${constServicios.urlPermisosRol}${this.props.rol}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: accessToken
          }
        }
      )
        .then(resp => {
          if (resp.status === 200) {
            sleep(500).then(() => {
              this.setState({
                modulos: resp.data,
                modulosNew: this.newListaPer(resp.data)
              });
            });
            //asigno el valor por defecto
          } else {
            alert(
              "Ocurrio un error recuperando los Permisos por Modulo, Por favor intente de nuevo."
            );
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch" + e);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rol !== this.props.rol) {
      this.consultaModulos();
    }
  }
  componentDidMount() {
    this.consultaModulos();
  }
  onChange(ide, e) {
    this.setState({
      showNewRole: true
    });
  }
  modulosOn(e) {
    this.setState({
      showRole: true
    });
  }
  crearModuloRol = async (e, idMod) => {
    var newMod;
    await Axios.post(
      `${constServicios.url}${constServicios.urlModulosRol}`,
      {
        idModulo: idMod,
        idRol: this.state.rolId,
        idEstado: "1" //activo
      },
      {
        headers: {
          Authorization: this.state.token
        }
      }
    )
      .then(res => {
        if (res.data) {
          if (JSON.stringify(res.data).includes("error")) {
            Swal.fire({
              title: res.data
            });
          } else {
            //recuperar id ROl y mapearle el periso en permisoRol
            newMod = res.data.idModulosRole;
            Swal.fire({
              title: "Modulo agregado Exitosamente: !" + newMod,
              type: "success"
            });
          }
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.status + " " + err.response.data);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Errorx", err.message);
        }
      });
    return "1";
  };

  render() {
    return (
      <div className=" card  lighten-5 col s12 m12">
        <div>
          <Table className="bordered striped">
            <TableHead>
              <TableRow>
                <TableCell className="tablaCell" colSpan={2}>
                  <div>
                    <h5>Módulos Asignados/{this.props.rolname} </h5>
                  </div>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            {this.state.showNewRole === true ? (
              <TableBody>
                {this.state.modulosNew.map((data, key) => (
                  <Fragment key={`f${data.idModulo}`}>
                    <TableRow key={data.idModulo}>
                      <TableCell key={`tc${data.idModulo}`}>
                        <a
                          data-tooltip="Ocultar"
                          id="hideRole"
                          name="hideRole"
                          value={!this.state.showRole}
                          className="btn-floating btn-small waves-light tooltipped"
                        >
                          <i
                            className="large material-icons"
                            onClick={event =>
                              this.handleChangeView(data.idModulo)
                            }
                          >
                            {data.showPermisos ? "remove" : "search"}
                          </i>
                        </a>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <h6 className={data.seleccion ? "thick" : "norm"}>
                          {data.nombre}
                        </h6>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {data.showPermisos ? (
                      <PermisosModulos
                        idModulo={data.idModulo}
                        token={this.state.token}
                        rolId={this.props.rol}
                        moduloChecked={data.seleccion}
                      />
                    ) : null}
                  </Fragment>
                ))}
              </TableBody>
            ) : null}
          </Table>
          {1 === 2 ? (
            <Select
              multiple
              name="sRoles"
              l={10}
              onChange={event => this.handleChange(event)}
              value={this.state.tipoSeleccionado}
            >
              <option value="">Seleccionar</option>
              {this.state.modulosNew.map((data, key) => {
                return (
                  <option
                    value={data.idRole}
                    key={key}
                    selected={
                      this.state.seleccionados &&
                      JSON.stringify(this.props.idRolesAsignados).indexOf(
                        data.idRole
                      ) > 0
                        ? true
                        : false
                    }
                  >
                    {data.nombre}
                  </option>
                );
              })}
            </Select>
          ) : null}
        </div>
      </div>
    );
  }
}

PermisosMod.contextType = AuthConsumer;

export default PermisosMod;
