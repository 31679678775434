import React, { useContext } from "react";
import TablasMaestras from "./TablasMaestras";
import { Redirect } from "react-router-dom";
import Can from "ContexApi/Can";
import authContext from "ContexApi/authContext";

const Index = () => {
  const { authenticated, user } = useContext(authContext);

  return authenticated ? (
    <Can
      rules={user.permisos}
      perform="maestras:ver"
      yes={() => <TablasMaestras />}
      no={() => <Redirect to="/inicio" />}
    />
  ) : (
    <Redirect to="/inicio" />
  );
};
export default Index;
