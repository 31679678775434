export const MOSTRAR_MENU = 'MOSTRAR_MENU';

//Lineamientos
export const MOSTRAR_LINEAMIENTOS = 'MOSTRAR_LINEAMIENTOS'; 
export const MOSTRAR_LINEAMIENTO = 'MOSTRAR_LINEAMIENTO'; 
export const ELIMINAR_LINEAMIENTO = 'ELIMINAR_LINEAMIENTO'; 
export const AGREGAR_LINEAMIENTO = 'AGREGAR_LINEAMIENTO'; 
export const EDITAR_LINEAMIENTO = 'EDITAR_LINEAMIENTO'; 

//Guias
export const MOSTRAR_GUIAS = 'MOSTRAR_GUIAS'; 
export const MOSTRAR_GUIA = 'MOSTRAR_GUIA'; 
export const ELIMINAR_GUIA = 'ELIMINAR_GUIA'; 
export const AGREGAR_GUIA = 'AGREGAR_GUIA'; 
export const EDITAR_GUIA = 'EDITAR_GUIA'; 