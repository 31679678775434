import React, { Component } from "react";
import { constServicios } from "../../constServicios";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../ContexApi/authContext";
import { Select } from "react-materialize";
import Axios from "axios";
import "../AdminUsuarios/css/AdminUsuarios.css";

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

class ClientesAsignados extends Component {
  constructor(props) {
    super();
    this.state = {
      token: "",
      lista: [],
      tipoSeleccionado: props.idClienteAsignado,
      seleccionados: props.clientesSeleccionados
    };
  }

  handleChange(event) {
    var options = event.target.options;

    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].value && options[i].selected) {
        value.push(options[i].value);
        this.setState({ seleccionados: value });
      }
    }
    sleep(500).then(() => {
      this.props.changeTipoDoc(this.state.seleccionados);
    });

    // this.state.tipoSeleccionado(value);
  }
  onHandleChange(event) {
    this.setState({
      tipoSeleccionado: event.target.value
    });

    sleep(500).then(() => {
      this.props.changeTipoDoc(this.state.tipoSeleccionado);
    });
  }

  componentDidMount() {
    try {
      //e.preventDefault();

      const { accessToken } = this.context;

      this.setState({ token: accessToken });
      //urlClientesAsignados
      Axios.get(`${constServicios.url}${constServicios.urlCliente}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken
        }
      })
        .then(resp => {
          if (resp.status === 200) {
            this.setState({
              lista: resp.data
            });
            //asigno el valor por defecto

            if (!this.props.clientesSeleccionados) {
              this.props.changeTipoDoc(resp.data[0].idCliente);
            } else {
              this.props.changeTipoDoc(
                resp.data[0].this.props.clientesSeleccionados
              );
            }
          } else {
            alert(
              "Ocurrio un error recuerando los Clientes, Por favor intente de nuevo."
            );
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch" + e);
    }
  }

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <React.Fragment>
              <div className="divHight">
                <Select
                  multiple
                  name="sclientes[clientes][]"
                  label="Cliente Asignado"
                  l={12}
                  onChange={event => this.handleChange(event)}
                  value={this.state.tipoSeleccionado}
                >
                  <option value="">Seleccionar</option>
                  {this.state.lista.map((data, key) => {
                    return (
                      <option
                        value={data.idCliente}
                        key={key}
                        selected={
                          this.state.seleccionados &&
                          JSON.stringify(this.state.seleccionados).indexOf(
                            data.idCliente
                          ) > 0
                            ? true
                            : false
                        }
                      >
                        {data.nombreCliente}
                      </option>
                    );
                  })}
                </Select>
              </div>
            </React.Fragment>
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

ClientesAsignados.contextType = AuthConsumer;

export default ClientesAsignados;
