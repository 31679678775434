import React, { useContext } from "react";
import axios from "axios";
import authContext from "ContexApi/authContext";

export default function useAxiosInterceptor() {
  const { accessToken } = useContext(authContext);

  axios.interceptors.request.use(
    function(config) {
      if (accessToken && !config.headers.Authorization) config.headers.Authorization = accessToken;
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
  return; 
}
