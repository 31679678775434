import React, { Component } from "react";
import { Link } from "react-router-dom";

//Estilos materialize
import { Button } from "react-materialize";

import axios from "axios";
import { constServicios } from "constServicios";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthConsumer } from "ContexApi/authContext";
const MySwal = withReactContent(Swal);

class Guia extends Component {
  eliminarGuia = () => {
    const { idGuia } = this.props.info;

    MySwal.fire({
      title: "¿ Estás seguro ?",
      text: "el cambio no podrá revertirse",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#002c55",
      cancelButtonColor: "#FF5252",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        try {
          const { accessToken } = this.context;
          const resp = await axios.delete(
            `${constServicios.url}${constServicios.urlGuias}${idGuia}`,
            {
              headers: {
                Authorization: accessToken
              }
            }
          );

          if (resp.status === 200) {
            MySwal.fire(
              "La guia fue eliminada correctamente.",
              "",
              "success"
            );
            this.props.borrarGuiaTabla(idGuia);
          }
        } catch (e) {
          console.log(e.message);
        }
      }
    });
  };

  render() {
    //TODO Terminar el mostrar guias
    const {
      idGuia,
      numeroGuia,
      operadorLogistico
      // PreradicadoIdPreradicado,
      // path
    } = this.props.info;
    return (
      <tr>
        <td>{numeroGuia}</td>
        <td>{operadorLogistico}</td>

        <td>
          <Link to={`/editarGuia/${idGuia}`}>
            <Button floating small className="btn-color-arus" waves="light">
              <i class="material-icons">edit</i>
            </Button>
          </Link>{" "}
          <Button
            floating
            small
            className="red accent-2"
            waves="light"
            onClick={this.eliminarGuia}
          >
            <i class="material-icons">clear</i>
          </Button>
        </td>
      </tr>
    );
  }
}

Guia.contextType = AuthConsumer;
export default Guia;
