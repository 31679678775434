import React, { Component } from 'react';
import { Dropdown, Divider, Row, Col } from "react-materialize";
import { Link } from "react-router-dom";
import axios from 'axios';
import { constServicios } from "../../constServicios";
import { AuthConsumer } from "../../ContexApi/authContext";

class Notificaciones extends Component {

    // state = {
    //     cantNotificaciones: 0,
    //     notificaciones: []
    // }

    // componentWillMount = () => {
    //     if (this.context.socketIoClient) {
    //         this.context.socketIoClient.on('notificaciones', (data) => {
    //             // console.log(data);
    //             this.setState({
    //                 cantNotificaciones: data.cantidad,
    //                 notificaciones: data.notificaciones
    //             })
    //         })
    //     }
    // }

    renderBadgeNotificaciones = () => {
        if (this.context.noti.cantidad && this.context.noti.cantidad > 0)
            return <span className="new badge red right" data-badge-caption="" id="notifi">{this.context.noti.cantidad}</span>;
        else
            return null;
    }

    renderNotificaciones = () => {
        return this.context.noti.notificaciones.map((notificacion) => {
            return <React.Fragment key={notificacion.idNotificacion}>
                <Link
                    key={notificacion.idNotificacion}
                    to={notificacion.link}
                    onClick={() => this.marcarNotificacion(notificacion.idNotificacion)}
                >
                    <Row>
                        <Col s={2}>
                            <img
                                src={`/images/menu/${notificacion.Modulo.icon}`}
                                alt={`${notificacion.Modulo.nombre}`}
                                height="auto"
                                width="100%"
                            />
                        </Col>
                        <Col s={10}>
                            <b>{notificacion.titulo}</b><br />{notificacion.descripcion}
                        </Col>
                    </Row>
                </Link>
                <Divider />
            </React.Fragment>;
        })
    }

    marcarNotificacion = (idNotificacion) => {
        try {
            const { accessToken } = this.props;

            const data = {
                idNotificacion
            };

            axios.post(`${constServicios.url}${constServicios.urlMarcarNotificaciones}`, data, {
                headers: {
                    Authorization: accessToken
                }
            });
        }
        catch (err) {

        }
    }


    render() {

        return (
            <Dropdown
                trigger={
                    <Link to={'#'} className="othera" id={""}>
                        {this.renderBadgeNotificaciones()}
                        <i className="material-icons">notifications</i>
                    </Link>
                }
            >
                {this.renderNotificaciones()}
            </Dropdown>
        );
    }

}

Notificaciones.contextType = AuthConsumer;

export default Notificaciones;