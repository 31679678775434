import {
  MOSTRAR_LINEAMIENTO,
  ELIMINAR_LINEAMIENTO,
  AGREGAR_LINEAMIENTO,
  MOSTRAR_LINEAMIENTOS,
  EDITAR_LINEAMIENTO
} from "./types";
import { constServicios } from "../constServicios";

import axios from "axios";

//sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const mostrarLineamientos = () => async dispatch => {
  try {
    const respuesta = await axios.get(
      `${constServicios.url}${constServicios.urlLineamientos}`
    );
    if (respuesta.status === 200) {
      dispatch({
        type: MOSTRAR_LINEAMIENTOS,
        payload: respuesta.data
      });
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const mostrarLineamiento = id => async dispatch => {
  try {
    const respuesta = await axios.get(
      `${constServicios.url}${constServicios.urlLineamientos}${id}`
    );
    if (respuesta.status === 200) {
      dispatch({
        type: MOSTRAR_LINEAMIENTO,
        payload: respuesta.data
      });
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const borrarLineamiento = id => async dispatch => {
  try {
    const respuesta = await axios.delete(
      `${constServicios.url}${constServicios.urlLineamientos}${id}`
    );

    if (respuesta.status === 200) {
      dispatch({
        type: ELIMINAR_LINEAMIENTO,
        payload: id
      });
      MySwal.fire("", "El lineamiento fue eliminado correctamente.", "success");
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const agregarLineamiento = (file, lineamiento) => async dispatch => {
  //TODO enviar archivo por servicio
  console.log("llegue");
  
  try {
    const respuesta = await axios.post(
      `${constServicios.url}${constServicios.urlLineamientos}`,
      lineamiento
    );
    if (respuesta.status === 200) {
      dispatch({
        type: AGREGAR_LINEAMIENTO,
        payload: respuesta.data
      });
      
      MySwal.fire("", "El lineamiento fue agregado correctamente.", "success");
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const editarLineamiento = (file, lineamiento) => async dispatch => {
  try {
    const respuesta = await axios.put(
      `${constServicios.url}${constServicios.urlLineamientos}${
        lineamiento.idLineamiento
      }`,
      lineamiento
    );
    if (respuesta.status === 200) {
      dispatch({
        type: EDITAR_LINEAMIENTO,
        payload: respuesta.data
      });
      MySwal.fire("", "El lineamiento fue actualizado correctamente.", "success");
    }
  } catch (e) {
    console.log(e.message);
  }
};
