import React, { Component } from 'react';
import moment from 'moment';


class TableRIPS extends Component {

    render() {
      console.log(this.props.dataRIPS);
      if(this.props.dataRIPS.length > 0 ){
        return (
            <table  className="responsive-table striped">
              <thead>
                <tr>
                    <th>ID prestador</th>
                    <th>Nombre prestador</th>
                    <th>Fecha de cargue Exitoso de los RIPS</th>
                    <th>Numero Remision</th>
                    <th>Numero de factura</th>
                </tr>
              </thead>
      
              <tbody>
                {this.props.dataRIPS.map((data,key) => {
                  return(
                    <tr key={key}>
                      <td>{data.NMCODIGO_PRESTADOR}</td>
                      <td>{this.props.user.info.Prestadore.nombrePrestador}</td>
                      <td>{moment(data.FECHAREGISTRO ).format('LLL')}</td>
                      <td>{data.DSNUMERO_REMISION}</td>
                      <td>{data.DSNUMERO_FACTURA}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
        );
      }else{
        return '';
      }

    };
};
export default TableRIPS;