import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "./fileUpload.styles.css";

export default function FileUpload(props) {
  const { disabled, file, setFile, accept, isLoading } = props;
  const fileInput = React.createRef();
  const handleImageChange = e => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    fileInput.current.value = null;
    document.getElementById("form").reset();
    // this.refs.fileText.value = '';
  };

  useEffect(() => {
    if (file === null) fileInput.current.value = null;
  }, [file]);

  return (
    <div className="fileinput text-center">
      <div className="file-field input-field">
        {file === null || typeof file === undefined ? (
          <button
            onClick={() => handleClick()}
            className="btn btn-color-arus"
            disabled={disabled || isLoading}
          >
            Examinar
          </button>
        ) : (
          <button
            onClick={() => handleRemove()}
            className="btn btn-color-arus"
            style={{ marginLeft: 15 }}
            id="limpiar"
          >
            Limpiar
          </button>
        )}
        <input
          type="file"
          onChange={handleImageChange}
          ref={fileInput}
          accept={accept}
        />
        <div className="file-path-wrapper">
          <form id="form">
            <input
              className="file-path validate"
              type="text"
              placeholder="Seleccione un archivo"
            />
          </form>
        </div>
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  file: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  accept: PropTypes.string
};
