export const constPRERADICADO = {
  mensajeInfoFisico:
    "Al seleccionar esta opción, recuerda que debes entregar de manera física, los soportes y/o facturas en las sedes que te indique la EPS.",
  mensajeInfoDigital:
    "Al seleccionar esta opción, recuerda que debes cargar los soportes y/o facturas por el portal web, en el módulo carga de soportes",
  extencionesPermitidas: [".xls", ".xlsx", ".xlsb"],
  tituloInfo: "Aviso",
  tituloError: "",
  nombreFisico: "físico",
  nombreDigital: "digital",
  mensajeErrorExtenciones: "Tipo de archivo no permitido solo se permiten: ",
  mensajeErrorArchivo: "Debes selectionar un archivo",
  mensajeErrorTipo: "Debes selectionar un tipo",
  btnArchivoPreradicado: "Examinar",
  tituloTipo: "Tipo",
  labelTipoFisico: "Físico",
  labelTipoDigital: "Digital",
  btnCarga: "PreRadicar",
  tagArchivo: "file",
  tagTipo: "data",
  rutaPlantilla: "",
  btnDecargarArchivo: "Descargar Archivo",
  msjPreRadicadoFisico:
    "Te informamos que el pre-radicado generado debe ser impreso para ser presentado físicamente con las facturas, en las oficinas que la EPS te indique previamente. Sin esta impresión, no podrá confirmarse la recepción del mismo.",
  msjPreRadicadoDigital: ""
};
