import React, { Component } from "react";
import { constServicios } from "../../constServicios";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../ContexApi/authContext";
import Axios from "axios";

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

class Clientes extends Component {
  constructor(props) {
    super();
    this.state = {
      token: "",
      lista: [],
      tipoSeleccionado: props.idCliente
    };
  }
  onHandleChange(event) {
    this.setState({
      tipoSeleccionado: event.target.value
    });
    sleep(500).then(() => {
      this.props.changeTipoDoc(this.state.tipoSeleccionado);
    });
  }

  componentDidMount() {
    try {
      //e.preventDefault();

      const { accessToken } = this.context;

      this.setState({ token: accessToken });

      Axios.get(`${constServicios.url}${constServicios.urlCliente}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken
        }
      })
        .then(resp => {
          if (resp.status === 200) {
            this.setState({
              lista: resp.data
            });
            //asigno el valor por defecto

            if (!this.props.idCliente) {
              this.props.changeTipoDoc(resp.data[0].idCliente);
            } else {
              this.props.changeTipoDoc(resp.data[0].this.props.idCliente);
            }
          } else {
            alert(
              "Ocurrio un error recuerando los Clientes, Por favor intente de nuevo."
            );
          }
        })
        .catch(err => {});
    } catch (e) {
      console.log("catch");
    }
  }

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <React.Fragment>
              <div>
                <select
                  id="sclientes"
                  className="browser-default"
                  onChange={event => this.onHandleChange(event)}
                  value={this.state.tipoSeleccionado}
                >
                  {this.state.lista.map(lists => (
                    <option key={lists.idCliente} value={lists.idCliente}>
                      {lists.nombreCliente}
                    </option>
                  ))}
                </select>
              </div>
            </React.Fragment>
          ) : (
            <Redirect to="/inicio" />
          )
        }
      </AuthConsumer>
    );
  }
}

Clientes.contextType = AuthConsumer;

export default Clientes;
