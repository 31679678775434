import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

//Constantes Lineamientos
import { constLineamientos } from "./constLineamientos";
import { constTablaLineamientos } from "../../Variables/constTabla";

//redux
import Lineamiento from "./Lineamiento";

//Estilos Materialize
import { Table, Row, Col, ProgressBar, Tabs, Tab } from "react-materialize";
import "./css/Lineamientos.css";

//DataTable.net
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";

//Auth
import { AuthConsumer } from "../../ContexApi/authContext";
import { Redirect } from "react-router-dom";
import Can from "../../ContexApi/Can";
import { async } from "q";

import axios from "axios";
import { constServicios } from "../../constServicios";
// import Row from "react-materialize/lib/Row";

import CrearLineamiento from "./CrearLineamiento";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000
});

class MostrarLineamientos extends Component {
  state = {
    lineamientos: [],
    cargando: true,
    activo: true,
    estados: [],
    activarTab: 1,
    cargandoActualizacion: false
  };

  componentDidMount() {
    if (
      this.context.authenticated &&
      this.context.user.permisos.static.includes("lineamientos:ver")
    ) {
      // this.getEstados();
      this.getLineamientos();

    } else {
      this.props.history.push("/");
    }
  }

  getLineamientos = async () => {
    const { accessToken, user } = this.context;

    try {
      const respuesta = await axios.get(
        `${constServicios.url}${constServicios.urlLineamientos}?idCliente=${user.info.Cliente.idCliente}`,
        {
          headers: {
            Authorization: accessToken
          }
        }
      );

      if (respuesta.status === 200) {
        this.setState({
          lineamientos: respuesta.data,
          cargando: false
        });

        $("#datatable").DataTable(constTablaLineamientos);
        $("#datatable_length select").val("10");
        $("#datatable_length select").addClass("browser-default");
      }
    } catch (error) {
      console.log(error);
    }
  };

  getEstados = async () => {
    const { accessToken } = this.context;

    try {
      const respuesta = await axios.get(
        `${constServicios.url}${constServicios.urlEstados}`,
        {
          headers: {
            Authorization: accessToken
          }
        }
      );

      if (respuesta.status === 200) {
        this.setState({
          estados: respuesta.data
        });
      }
    } catch (error) {
      this.props.history.push("/");
    }
  };

  actualizarLineamiento = (idLineamiento, idEstado) => {
    let lineamientos = [...this.state.lineamientos];

    lineamientos = lineamientos.map(lineamiento =>
      lineamiento.idLineamientos === idLineamiento
        ? { ...lineamiento, idEstado: idEstado }
        : lineamiento
    );

    this.setState({
      lineamientos
    });
  };

  descarga = async (idLineamiento, archivo) => {
    const { accessToken, user } = this.context;

    archivo = archivo.replace(
      `${constServicios.UPLOAD_FILE_PATH_OUT_LINEAMIENTOS}`,
      ""
    );

    axios
      .get(
        `${constServicios.url}${constServicios.urlLineamientosDescargarArchivo}${idLineamiento}/${user.info.Cliente.idCliente}`,
        {
          headers: { Authorization: accessToken },
          responseType: "blob" //IMPORTANTE
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", archivo);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        Toast.fire({
          type: "error",
          title: `${constLineamientos.mensajeErrorImagen}`
        });
      });
  };

  activarTab = numero => {
    this.setState({
      cargando: true
    });
    this.getLineamientos();
  };

  render() {
    return (
      <AuthConsumer>
        {({ authenticated, user }) =>
          authenticated ? (
            <Can
              rules={user.permisos}
              perform="lineamientos:ver"
              yes={() =>
                !this.state.cargando ? (
                  <Tabs className="tab-demo z-depth-1">
                    <Tab
                      title="Lineamientos Operativos"
                      active={this.state.activarTab == 1 ? true : false}
                    >
                      <Can
                        rules={user.permisos}
                        perform="lineamientos:listar"
                        yes={() => {
                          let param = this.context.params.filter(i => i.key === "creacionLineamientos")
                          if (Number(this.context.user.info.Cliente.idCliente) === Number(param[0].value)) {
                            Swal.fire({
                              title: "Usted no tiene ningún cliente asignado",
                              icon: 'error',
                              confirmButtonColor: '#3085d6',
                              confirmButtonText: 'Aceptar'
                            }).then((result) => {
                              this.props.history.push('/inicio')
                            })
                          }
                          return <Fragment>
                            <div className="general_components card-panel">
                              <div className="tableOverflow">
                                <Table
                                  id="datatable"
                                  className="striped"
                                  hoverable
                                >
                                  <thead>
                                    <tr>
                                      <th data-field="id">
                                        {constLineamientos.columId}
                                      </th>
                                      <th data-field="tipo">
                                        {
                                          constLineamientos.columTipoLineamiento
                                        }
                                      </th>
                                      <th data-field="estado">
                                        {constLineamientos.columEstado}
                                      </th>
                                      <th data-field="fechaaplicacion">
                                        {constLineamientos.columFechaAplica}
                                      </th>
                                      <th data-field="descripcion">
                                        {constLineamientos.columDescripcion}
                                      </th>
                                      <th data-field="fechaactualizacion">
                                        {constLineamientos.nombreUsuario}
                                      </th>
                                      <th data-field="acciones">
                                        {constLineamientos.columAcciones}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.lineamientos.map(
                                      lineamiento => (
                                        <Lineamiento
                                          key={lineamiento.idLineamientos}
                                          dataLineamiento={lineamiento}
                                          todosEstados={this.state.estados}
                                          actualizarLineamiento={
                                            this.actualizarLineamiento
                                          }
                                          descarga={this.descarga}
                                        />
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Fragment>

                        }}
                        no={() => {
                          return (
                            <div>
                              Usted no tiene permisos para ver los lineamientos
                            </div>
                          );
                        }}
                      />
                    </Tab>
                    <Tab
                      title="Crear Lineamiento"
                      active={this.state.activarTab == 2 ? true : false}
                    >
                      <Can
                        rules={user.permisos}
                        perform="lineamientos:crear"
                        yes={() => {
                          let param = this.context.params.filter(i => i.key === "creacionLineamientos")
                          if (Number(this.context.user.info.Cliente.idCliente) === Number(param[0].value)) {
                            return <div>
                              Usted no tiene permisos para crear los
                              lineamientos
                          </div>
                          }
                          return (
                            <CrearLineamiento activarTab={this.activarTab} />
                          );
                        }}
                        no={() => {
                          return (
                            <div>
                              Usted no tiene permisos para crear los
                              lineamientos
                            </div>
                          );
                        }}
                      />
                    </Tab>
                  </Tabs>
                ) : (
                    <Row>
                      <Col s={12}>
                        <ProgressBar
                          className=""
                          color="blue"
                          active={this.state.cargando}
                        />
                      </Col>
                    </Row>
                  )
              }
              no={() => <Redirect to="/inicio" />}
            />
          ) : (
              <Redirect to="/inicio" />
            )
        }
      </AuthConsumer>
    );
  }
}

MostrarLineamientos.contextType = AuthConsumer;

export default MostrarLineamientos;
