import XLSX from 'xlsx';
import { saveAs } from "file-saver";

const exportarCSV_XLSX = async (data, nombre ,type='xlsx') => {
  nombre = nombre ? nombre : 'archivoTemporal';
  let wbout = null
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, nombre);
  if(type === 'xlsx'){
    wbout = await XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  }else{
    wbout = await XLSX.write(wb, { FS: ";", type: "binary" });
  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream" }),
    type === 'xlsx' ? `${nombre}.xlsx` :`${nombre}.csv`
  );
}

export default exportarCSV_XLSX;